export const ShiyuDuos = [
  {
    char: 'miyabi',
    round: 80,
    char_1: 'soukaku',
    app_rate_1: 37.23,
    avg_round_1: 84,
    app_flat_1: 0,
    char_2: 'yanagi',
    app_rate_2: 31.5,
    avg_round_2: 78,
    app_flat_2: 0,
    char_3: 'lucy',
    app_rate_3: 25.49,
    avg_round_3: 74,
    app_flat_3: 0,
    char_4: 'lycaon',
    app_rate_4: 20.59,
    avg_round_4: 81,
    app_flat_4: 0,
    char_5: 'burnice',
    app_rate_5: 16.89,
    avg_round_5: 72,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 13.94,
    avg_round_6: 80,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 13.81,
    avg_round_7: 87,
    app_flat_7: 0,
    char_8: 'astra-yao',
    app_rate_8: 9.04,
    avg_round_8: 60,
    app_flat_8: 0,
    char_9: 'lighter',
    app_rate_9: 6.99,
    avg_round_9: 76,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 5.53,
    avg_round_10: 82,
    app_flat_10: 0,
    char_11: 'piper',
    app_rate_11: 3.1,
    avg_round_11: 86,
    app_flat_11: 0,
    char_12: 'harumasa',
    app_rate_12: 2.03,
    avg_round_12: 134,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 2.03,
    avg_round_13: 93,
    app_flat_13: 0,
    char_14: 'ellen',
    app_rate_14: 1.54,
    avg_round_14: 109,
    app_flat_14: 0,
    char_15: 'qingyi',
    app_rate_15: 1.27,
    avg_round_15: 102,
    app_flat_15: 0,
    char_16: 'seth',
    app_rate_16: 1.2,
    avg_round_16: 111,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 0.95,
    avg_round_17: 96,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 0.7,
    avg_round_18: 104,
    app_flat_18: 0,
    char_19: 'koleda',
    app_rate_19: 0.68,
    avg_round_19: 110,
    app_flat_19: 0,
    char_20: 'jane-doe',
    app_rate_20: 0.59,
    avg_round_20: 89,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.06,
    avg_round_21: 103,
    app_flat_21: 0,
    char_22: 'ben',
    app_rate_22: 0.02,
    avg_round_22: 83,
    app_flat_22: 0,
    char_23: 'soldier-11',
    app_rate_23: 0.02,
    avg_round_23: 600,
    app_flat_23: 0,
    char_24: 'corin',
    app_rate_24: 0.02,
    avg_round_24: 600,
    app_flat_24: 0,
    char_25: 'billy-kid',
    app_rate_25: 0.02,
    avg_round_25: 116,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'astra-yao',
    round: 95,
    char_1: 'miyabi',
    app_rate_1: 53.85,
    avg_round_1: 60,
    app_flat_1: 0,
    char_2: 'yanagi',
    app_rate_2: 28.73,
    avg_round_2: 64,
    app_flat_2: 0,
    char_3: 'qingyi',
    app_rate_3: 16.86,
    avg_round_3: 119,
    app_flat_3: 0,
    char_4: 'harumasa',
    app_rate_4: 13.69,
    avg_round_4: 138,
    app_flat_4: 0,
    char_5: 'burnice',
    app_rate_5: 12.67,
    avg_round_5: 73,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 10.86,
    avg_round_6: 113,
    app_flat_6: 0,
    char_7: 'lycaon',
    app_rate_7: 8.94,
    avg_round_7: 101,
    app_flat_7: 0,
    char_8: 'ellen',
    app_rate_8: 8.6,
    avg_round_8: 120,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 8.37,
    avg_round_9: 150,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 7.69,
    avg_round_10: 84,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 5.88,
    avg_round_11: 164,
    app_flat_11: 0,
    char_12: 'soukaku',
    app_rate_12: 4.3,
    avg_round_12: 81,
    app_flat_12: 0,
    char_13: 'seth',
    app_rate_13: 4.07,
    avg_round_13: 147,
    app_flat_13: 0,
    char_14: 'caesar',
    app_rate_14: 3.96,
    avg_round_14: 121,
    app_flat_14: 0,
    char_15: 'rina',
    app_rate_15: 2.38,
    avg_round_15: 108,
    app_flat_15: 0,
    char_16: 'piper',
    app_rate_16: 2.26,
    avg_round_16: 114,
    app_flat_16: 0,
    char_17: 'lighter',
    app_rate_17: 1.92,
    avg_round_17: 101,
    app_flat_17: 0,
    char_18: 'grace-howard',
    app_rate_18: 1.47,
    avg_round_18: 130,
    app_flat_18: 0,
    char_19: 'lucy',
    app_rate_19: 1.13,
    avg_round_19: 78,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 1.02,
    avg_round_20: 139,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.45,
    avg_round_21: 278,
    app_flat_21: 0,
    char_22: 'koleda',
    app_rate_22: 0.34,
    avg_round_22: 270,
    app_flat_22: 0,
    char_23: 'billy-kid',
    app_rate_23: 0.11,
    avg_round_23: 68,
    app_flat_23: 0,
    char_24: 'corin',
    app_rate_24: 0.11,
    avg_round_24: 600,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lucy',
    round: 101,
    char_1: 'miyabi',
    app_rate_1: 68.57,
    avg_round_1: 74,
    app_flat_1: 0,
    char_2: 'burnice',
    app_rate_2: 42.72,
    avg_round_2: 88,
    app_flat_2: 0,
    char_3: 'lighter',
    app_rate_3: 18.14,
    avg_round_3: 81,
    app_flat_3: 0,
    char_4: 'yanagi',
    app_rate_4: 15.94,
    avg_round_4: 99,
    app_flat_4: 0,
    char_5: 'piper',
    app_rate_5: 11.55,
    avg_round_5: 125,
    app_flat_5: 0,
    char_6: 'jane-doe',
    app_rate_6: 11.14,
    avg_round_6: 156,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 7.46,
    avg_round_7: 139,
    app_flat_7: 0,
    char_8: 'seth',
    app_rate_8: 6.18,
    avg_round_8: 165,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 3.01,
    avg_round_9: 108,
    app_flat_9: 0,
    char_10: 'harumasa',
    app_rate_10: 2.45,
    avg_round_10: 182,
    app_flat_10: 0,
    char_11: 'qingyi',
    app_rate_11: 2.04,
    avg_round_11: 167,
    app_flat_11: 0,
    char_12: 'soldier-11',
    app_rate_12: 1.89,
    avg_round_12: 190,
    app_flat_12: 0,
    char_13: 'koleda',
    app_rate_13: 1.43,
    avg_round_13: 164,
    app_flat_13: 0,
    char_14: 'lycaon',
    app_rate_14: 1.23,
    avg_round_14: 86,
    app_flat_14: 0,
    char_15: 'nicole-demara',
    app_rate_15: 1.23,
    avg_round_15: 102,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 0.77,
    avg_round_16: 152,
    app_flat_16: 0,
    char_17: 'rina',
    app_rate_17: 0.77,
    avg_round_17: 116,
    app_flat_17: 0,
    char_18: 'ellen',
    app_rate_18: 0.77,
    avg_round_18: 139,
    app_flat_18: 0,
    char_19: 'ben',
    app_rate_19: 0.61,
    avg_round_19: 175,
    app_flat_19: 0,
    char_20: 'astra-yao',
    app_rate_20: 0.51,
    avg_round_20: 78,
    app_flat_20: 0,
    char_21: 'anby-demara',
    app_rate_21: 0.41,
    avg_round_21: 155,
    app_flat_21: 0,
    char_22: 'corin',
    app_rate_22: 0.31,
    avg_round_22: 166,
    app_flat_22: 0,
    char_23: 'billy-kid',
    app_rate_23: 0.26,
    avg_round_23: 188,
    app_flat_23: 0,
    char_24: 'zhu-yuan',
    app_rate_24: 0.2,
    avg_round_24: 277,
    app_flat_24: 0,
    char_25: 'nekomata',
    app_rate_25: 0.15,
    avg_round_25: 242,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lighter',
    round: 103,
    char_1: 'miyabi',
    app_rate_1: 61.64,
    avg_round_1: 76,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 59.46,
    avg_round_2: 81,
    app_flat_2: 0,
    char_3: 'ellen',
    app_rate_3: 26.13,
    avg_round_3: 141,
    app_flat_3: 0,
    char_4: 'soukaku',
    app_rate_4: 16.75,
    avg_round_4: 137,
    app_flat_4: 0,
    char_5: 'lycaon',
    app_rate_5: 9.88,
    avg_round_5: 140,
    app_flat_5: 0,
    char_6: 'soldier-11',
    app_rate_6: 5.36,
    avg_round_6: 145,
    app_flat_6: 0,
    char_7: 'caesar',
    app_rate_7: 3.85,
    avg_round_7: 168,
    app_flat_7: 0,
    char_8: 'burnice',
    app_rate_8: 3.02,
    avg_round_8: 114,
    app_flat_8: 0,
    char_9: 'astra-yao',
    app_rate_9: 2.85,
    avg_round_9: 101,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 2.35,
    avg_round_10: 115,
    app_flat_10: 0,
    char_11: 'harumasa',
    app_rate_11: 2.35,
    avg_round_11: 178,
    app_flat_11: 0,
    char_12: 'rina',
    app_rate_12: 1.68,
    avg_round_12: 145,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 1.51,
    avg_round_13: 139,
    app_flat_13: 0,
    char_14: 'ben',
    app_rate_14: 1.01,
    avg_round_14: 129,
    app_flat_14: 0,
    char_15: 'yanagi',
    app_rate_15: 0.67,
    avg_round_15: 201,
    app_flat_15: 0,
    char_16: 'piper',
    app_rate_16: 0.67,
    avg_round_16: 156,
    app_flat_16: 0,
    char_17: 'jane-doe',
    app_rate_17: 0.5,
    avg_round_17: 195,
    app_flat_17: 0,
    char_18: 'seth',
    app_rate_18: 0.34,
    avg_round_18: 201,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soukaku',
    round: 104,
    char_1: 'miyabi',
    app_rate_1: 76.03,
    avg_round_1: 84,
    app_flat_1: 0,
    char_2: 'lycaon',
    app_rate_2: 48.33,
    avg_round_2: 103,
    app_flat_2: 0,
    char_3: 'ellen',
    app_rate_3: 24.52,
    avg_round_3: 151,
    app_flat_3: 0,
    char_4: 'yanagi',
    app_rate_4: 10.71,
    avg_round_4: 85,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 8.92,
    avg_round_5: 112,
    app_flat_5: 0,
    char_6: 'burnice',
    app_rate_6: 5.04,
    avg_round_6: 78,
    app_flat_6: 0,
    char_7: 'lighter',
    app_rate_7: 3.88,
    avg_round_7: 137,
    app_flat_7: 0,
    char_8: 'qingyi',
    app_rate_8: 3.37,
    avg_round_8: 155,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.79,
    avg_round_9: 89,
    app_flat_9: 0,
    char_10: 'lucy',
    app_rate_10: 2.29,
    avg_round_10: 108,
    app_flat_10: 0,
    char_11: 'harumasa',
    app_rate_11: 1.86,
    avg_round_11: 161,
    app_flat_11: 0,
    char_12: 'astra-yao',
    app_rate_12: 1.47,
    avg_round_12: 81,
    app_flat_12: 0,
    char_13: 'rina',
    app_rate_13: 1.47,
    avg_round_13: 89,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 1.32,
    avg_round_14: 96,
    app_flat_14: 0,
    char_15: 'anby-demara',
    app_rate_15: 0.93,
    avg_round_15: 158,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 0.89,
    avg_round_16: 129,
    app_flat_16: 0,
    char_17: 'piper',
    app_rate_17: 0.78,
    avg_round_17: 105,
    app_flat_17: 0,
    char_18: 'jane-doe',
    app_rate_18: 0.54,
    avg_round_18: 92,
    app_flat_18: 0,
    char_19: 'seth',
    app_rate_19: 0.5,
    avg_round_19: 112,
    app_flat_19: 0,
    char_20: 'zhu-yuan',
    app_rate_20: 0.47,
    avg_round_20: 135,
    app_flat_20: 0,
    char_21: 'ben',
    app_rate_21: 0.04,
    avg_round_21: 246,
    app_flat_21: 0,
    char_22: 'billy-kid',
    app_rate_22: 0.04,
    avg_round_22: 116,
    app_flat_22: 0,
    char_23: 'corin',
    app_rate_23: 0.04,
    avg_round_23: 27,
    app_flat_23: 0,
    char_24: 'nekomata',
    app_rate_24: 0.04,
    avg_round_24: 600,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanagi',
    round: 105,
    char_1: 'miyabi',
    app_rate_1: 51.57,
    avg_round_1: 78,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 37.17,
    avg_round_2: 101,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 27.71,
    avg_round_3: 110,
    app_flat_3: 0,
    char_4: 'burnice',
    app_rate_4: 15.33,
    avg_round_4: 113,
    app_flat_4: 0,
    char_5: 'seth',
    app_rate_5: 14.09,
    avg_round_5: 137,
    app_flat_5: 0,
    char_6: 'lucy',
    app_rate_6: 9.7,
    avg_round_6: 99,
    app_flat_6: 0,
    char_7: 'soukaku',
    app_rate_7: 8.58,
    avg_round_7: 85,
    app_flat_7: 0,
    char_8: 'astra-yao',
    app_rate_8: 7.9,
    avg_round_8: 64,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 6.03,
    avg_round_9: 145,
    app_flat_9: 0,
    char_10: 'qingyi',
    app_rate_10: 5.91,
    avg_round_10: 153,
    app_flat_10: 0,
    char_11: 'harumasa',
    app_rate_11: 4.45,
    avg_round_11: 163,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 3.17,
    avg_round_12: 105,
    app_flat_12: 0,
    char_13: 'piper',
    app_rate_13: 2.02,
    avg_round_13: 136,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 1.99,
    avg_round_14: 153,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 1.15,
    avg_round_15: 164,
    app_flat_15: 0,
    char_16: 'lycaon',
    app_rate_16: 0.84,
    avg_round_16: 98,
    app_flat_16: 0,
    char_17: 'ellen',
    app_rate_17: 0.16,
    avg_round_17: 254,
    app_flat_17: 0,
    char_18: 'lighter',
    app_rate_18: 0.12,
    avg_round_18: 201,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.12,
    avg_round_19: 144,
    app_flat_19: 0,
    char_20: 'koleda',
    app_rate_20: 0.12,
    avg_round_20: 120,
    app_flat_20: 0,
    char_21: 'nekomata',
    app_rate_21: 0.09,
    avg_round_21: 193,
    app_flat_21: 0,
    char_22: 'ben',
    app_rate_22: 0.06,
    avg_round_22: 142,
    app_flat_22: 0,
    char_23: 'anton',
    app_rate_23: 0.06,
    avg_round_23: 152,
    app_flat_23: 0,
    char_24: 'corin',
    app_rate_24: 0.03,
    avg_round_24: 153,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'burnice',
    round: 106,
    char_1: 'miyabi',
    app_rate_1: 49.94,
    avg_round_1: 72,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 46.97,
    avg_round_2: 88,
    app_flat_2: 0,
    char_3: 'yanagi',
    app_rate_3: 27.7,
    avg_round_3: 113,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 19.44,
    avg_round_4: 144,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 17.98,
    avg_round_5: 166,
    app_flat_5: 0,
    char_6: 'soukaku',
    app_rate_6: 7.3,
    avg_round_6: 78,
    app_flat_6: 0,
    char_7: 'astra-yao',
    app_rate_7: 6.29,
    avg_round_7: 73,
    app_flat_7: 0,
    char_8: 'seth',
    app_rate_8: 6.24,
    avg_round_8: 168,
    app_flat_8: 0,
    char_9: 'rina',
    app_rate_9: 5.39,
    avg_round_9: 108,
    app_flat_9: 0,
    char_10: 'piper',
    app_rate_10: 4.44,
    avg_round_10: 144,
    app_flat_10: 0,
    char_11: 'qingyi',
    app_rate_11: 1.57,
    avg_round_11: 181,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 1.57,
    avg_round_12: 88,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 1.29,
    avg_round_13: 156,
    app_flat_13: 0,
    char_14: 'lighter',
    app_rate_14: 1.01,
    avg_round_14: 114,
    app_flat_14: 0,
    char_15: 'harumasa',
    app_rate_15: 0.9,
    avg_round_15: 172,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 0.51,
    avg_round_16: 179,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.39,
    avg_round_17: 129,
    app_flat_17: 0,
    char_18: 'koleda',
    app_rate_18: 0.39,
    avg_round_18: 148,
    app_flat_18: 0,
    char_19: 'lycaon',
    app_rate_19: 0.34,
    avg_round_19: 131,
    app_flat_19: 0,
    char_20: 'ben',
    app_rate_20: 0.17,
    avg_round_20: 135,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.17,
    avg_round_21: 217,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.06,
    avg_round_22: 215,
    app_flat_22: 0,
    char_23: 'nekomata',
    app_rate_23: 0.06,
    avg_round_23: 1,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lycaon',
    round: 110,
    char_1: 'soukaku',
    app_rate_1: 74.12,
    avg_round_1: 103,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 64.49,
    avg_round_2: 81,
    app_flat_2: 0,
    char_3: 'ellen',
    app_rate_3: 32.36,
    avg_round_3: 155,
    app_flat_3: 0,
    char_4: 'rina',
    app_rate_4: 5.18,
    avg_round_4: 139,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 4.76,
    avg_round_5: 169,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 4.7,
    avg_round_6: 101,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 3.75,
    avg_round_7: 118,
    app_flat_7: 0,
    char_8: 'lighter',
    app_rate_8: 3.51,
    avg_round_8: 140,
    app_flat_8: 0,
    char_9: 'harumasa',
    app_rate_9: 1.9,
    avg_round_9: 187,
    app_flat_9: 0,
    char_10: 'yanagi',
    app_rate_10: 1.61,
    avg_round_10: 98,
    app_flat_10: 0,
    char_11: 'lucy',
    app_rate_11: 1.43,
    avg_round_11: 86,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 1.19,
    avg_round_12: 148,
    app_flat_12: 0,
    char_13: 'burnice',
    app_rate_13: 0.36,
    avg_round_13: 131,
    app_flat_13: 0,
    char_14: 'corin',
    app_rate_14: 0.24,
    avg_round_14: 178,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 0.18,
    avg_round_15: 201,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 0.12,
    avg_round_16: 194,
    app_flat_16: 0,
    char_17: 'qingyi',
    app_rate_17: 0.06,
    avg_round_17: 146,
    app_flat_17: 0,
    char_18: 'anton',
    app_rate_18: 0.06,
    avg_round_18: 207,
    app_flat_18: 0,
    char_19: 'nekomata',
    app_rate_19: 0.06,
    avg_round_19: 164,
    app_flat_19: 0,
    char_20: 'piper',
    app_rate_20: 0.06,
    avg_round_20: 300,
    app_flat_20: 0,
    char_21: 'seth',
    app_rate_21: 0.06,
    avg_round_21: 348,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    round: 124,
    char_1: 'nicole-demara',
    app_rate_1: 91.3,
    avg_round_1: 122,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 72.88,
    avg_round_2: 120,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 10.83,
    avg_round_3: 144,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 7.74,
    avg_round_4: 132,
    app_flat_4: 0,
    char_5: 'astra-yao',
    app_rate_5: 7.07,
    avg_round_5: 113,
    app_flat_5: 0,
    char_6: 'miyabi',
    app_rate_6: 3.68,
    avg_round_6: 96,
    app_flat_6: 0,
    char_7: 'koleda',
    app_rate_7: 1.62,
    avg_round_7: 141,
    app_flat_7: 0,
    char_8: 'lycaon',
    app_rate_8: 1.47,
    avg_round_8: 148,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 0.88,
    avg_round_9: 135,
    app_flat_9: 0,
    char_10: 'lighter',
    app_rate_10: 0.66,
    avg_round_10: 139,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 0.52,
    avg_round_11: 293,
    app_flat_11: 0,
    char_12: 'rina',
    app_rate_12: 0.44,
    avg_round_12: 138,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 0.29,
    avg_round_13: 277,
    app_flat_13: 0,
    char_14: 'yanagi',
    app_rate_14: 0.29,
    avg_round_14: 144,
    app_flat_14: 0,
    char_15: 'ellen',
    app_rate_15: 0.22,
    avg_round_15: 108,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 0.15,
    avg_round_16: 288,
    app_flat_16: 0,
    char_17: 'seth',
    app_rate_17: 0.07,
    avg_round_17: 136,
    app_flat_17: 0,
    char_18: 'burnice',
    app_rate_18: 0.07,
    avg_round_18: 215,
    app_flat_18: 0,
    char_19: 'harumasa',
    app_rate_19: 0.07,
    avg_round_19: 273,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'rina',
    round: 126,
    char_1: 'yanagi',
    app_rate_1: 53.49,
    avg_round_1: 101,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 32.86,
    avg_round_2: 80,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 27.89,
    avg_round_3: 161,
    app_flat_3: 0,
    char_4: 'qingyi',
    app_rate_4: 23.72,
    avg_round_4: 147,
    app_flat_4: 0,
    char_5: 'seth',
    app_rate_5: 13.61,
    avg_round_5: 138,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 8.5,
    avg_round_6: 111,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 8.24,
    avg_round_7: 159,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 6.58,
    avg_round_8: 140,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 6.31,
    avg_round_9: 180,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 4.3,
    avg_round_10: 108,
    app_flat_10: 0,
    char_11: 'lycaon',
    app_rate_11: 3.89,
    avg_round_11: 139,
    app_flat_11: 0,
    char_12: 'ellen',
    app_rate_12: 2.73,
    avg_round_12: 156,
    app_flat_12: 0,
    char_13: 'soukaku',
    app_rate_13: 1.7,
    avg_round_13: 89,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 1.03,
    avg_round_14: 98,
    app_flat_14: 0,
    char_15: 'astra-yao',
    app_rate_15: 0.94,
    avg_round_15: 108,
    app_flat_15: 0,
    char_16: 'anton',
    app_rate_16: 0.81,
    avg_round_16: 168,
    app_flat_16: 0,
    char_17: 'piper',
    app_rate_17: 0.67,
    avg_round_17: 143,
    app_flat_17: 0,
    char_18: 'corin',
    app_rate_18: 0.67,
    avg_round_18: 149,
    app_flat_18: 0,
    char_19: 'lucy',
    app_rate_19: 0.67,
    avg_round_19: 116,
    app_flat_19: 0,
    char_20: 'lighter',
    app_rate_20: 0.45,
    avg_round_20: 145,
    app_flat_20: 0,
    char_21: 'koleda',
    app_rate_21: 0.36,
    avg_round_21: 190,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.27,
    avg_round_22: 138,
    app_flat_22: 0,
    char_23: 'nekomata',
    app_rate_23: 0.09,
    avg_round_23: 228,
    app_flat_23: 0,
    char_24: 'ben',
    app_rate_24: 0.04,
    avg_round_24: 222,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    round: 127,
    char_1: 'zhu-yuan',
    app_rate_1: 59.23,
    avg_round_1: 122,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 57.12,
    avg_round_2: 123,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 18.64,
    avg_round_3: 152,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 15.01,
    avg_round_4: 158,
    app_flat_4: 0,
    char_5: 'miyabi',
    app_rate_5: 13.91,
    avg_round_5: 82,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 6.17,
    avg_round_6: 129,
    app_flat_6: 0,
    char_7: 'yanagi',
    app_rate_7: 4.88,
    avg_round_7: 105,
    app_flat_7: 0,
    char_8: 'soukaku',
    app_rate_8: 3.44,
    avg_round_8: 89,
    app_flat_8: 0,
    char_9: 'astra-yao',
    app_rate_9: 3.25,
    avg_round_9: 84,
    app_flat_9: 0,
    char_10: 'lycaon',
    app_rate_10: 3.01,
    avg_round_10: 118,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 2.15,
    avg_round_11: 169,
    app_flat_11: 0,
    char_12: 'seth',
    app_rate_12: 2.06,
    avg_round_12: 149,
    app_flat_12: 0,
    char_13: 'ellen',
    app_rate_13: 1.53,
    avg_round_13: 137,
    app_flat_13: 0,
    char_14: 'burnice',
    app_rate_14: 1.34,
    avg_round_14: 88,
    app_flat_14: 0,
    char_15: 'nekomata',
    app_rate_15: 1.34,
    avg_round_15: 163,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 1.29,
    avg_round_16: 149,
    app_flat_16: 0,
    char_17: 'lucy',
    app_rate_17: 1.15,
    avg_round_17: 102,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 1.1,
    avg_round_18: 154,
    app_flat_18: 0,
    char_19: 'rina',
    app_rate_19: 1.1,
    avg_round_19: 98,
    app_flat_19: 0,
    char_20: 'lighter',
    app_rate_20: 0.67,
    avg_round_20: 115,
    app_flat_20: 0,
    char_21: 'anton',
    app_rate_21: 0.53,
    avg_round_21: 150,
    app_flat_21: 0,
    char_22: 'piper',
    app_rate_22: 0.48,
    avg_round_22: 191,
    app_flat_22: 0,
    char_23: 'grace-howard',
    app_rate_23: 0.43,
    avg_round_23: 149,
    app_flat_23: 0,
    char_24: 'soldier-11',
    app_rate_24: 0.19,
    avg_round_24: 82,
    app_flat_24: 0,
    char_25: 'corin',
    app_rate_25: 0.19,
    avg_round_25: 164,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'caesar',
    round: 135,
    char_1: 'yanagi',
    app_rate_1: 43.34,
    avg_round_1: 110,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 35.36,
    avg_round_2: 87,
    app_flat_2: 0,
    char_3: 'burnice',
    app_rate_3: 16.83,
    avg_round_3: 144,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 16.25,
    avg_round_4: 180,
    app_flat_4: 0,
    char_5: 'soukaku',
    app_rate_5: 11.19,
    avg_round_5: 112,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 9.24,
    avg_round_6: 111,
    app_flat_6: 0,
    char_7: 'ellen',
    app_rate_7: 8.75,
    avg_round_7: 155,
    app_flat_7: 0,
    char_8: 'seth',
    app_rate_8: 8.56,
    avg_round_8: 180,
    app_flat_8: 0,
    char_9: 'harumasa',
    app_rate_9: 8.22,
    avg_round_9: 174,
    app_flat_9: 0,
    char_10: 'qingyi',
    app_rate_10: 7.68,
    avg_round_10: 165,
    app_flat_10: 0,
    char_11: 'lucy',
    app_rate_11: 7.1,
    avg_round_11: 139,
    app_flat_11: 0,
    char_12: 'nicole-demara',
    app_rate_12: 6.27,
    avg_round_12: 129,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 5.11,
    avg_round_13: 132,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 4.09,
    avg_round_14: 179,
    app_flat_14: 0,
    char_15: 'lycaon',
    app_rate_15: 3.89,
    avg_round_15: 169,
    app_flat_15: 0,
    char_16: 'astra-yao',
    app_rate_16: 1.7,
    avg_round_16: 121,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 1.36,
    avg_round_17: 186,
    app_flat_17: 0,
    char_18: 'lighter',
    app_rate_18: 1.12,
    avg_round_18: 168,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 1.02,
    avg_round_19: 210,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.83,
    avg_round_20: 181,
    app_flat_20: 0,
    char_21: 'corin',
    app_rate_21: 0.63,
    avg_round_21: 203,
    app_flat_21: 0,
    char_22: 'nekomata',
    app_rate_22: 0.63,
    avg_round_22: 204,
    app_flat_22: 0,
    char_23: 'billy-kid',
    app_rate_23: 0.44,
    avg_round_23: 168,
    app_flat_23: 0,
    char_24: 'anton',
    app_rate_24: 0.24,
    avg_round_24: 191,
    app_flat_24: 0,
    char_25: 'koleda',
    app_rate_25: 0.15,
    avg_round_25: 193,
    app_flat_25: 0,
    char_26: 'ben',
    app_rate_26: 0.05,
    avg_round_26: 196,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingyi',
    round: 139,
    char_1: 'nicole-demara',
    app_rate_1: 51.2,
    avg_round_1: 123,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 42.37,
    avg_round_2: 120,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 38.05,
    avg_round_3: 148,
    app_flat_3: 0,
    char_4: 'rina',
    app_rate_4: 22.71,
    avg_round_4: 147,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 8.14,
    avg_round_5: 153,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 6.77,
    avg_round_6: 165,
    app_flat_6: 0,
    char_7: 'astra-yao',
    app_rate_7: 6.38,
    avg_round_7: 119,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 4.88,
    avg_round_8: 192,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 3.73,
    avg_round_9: 155,
    app_flat_9: 0,
    char_10: 'ellen',
    app_rate_10: 3.21,
    avg_round_10: 176,
    app_flat_10: 0,
    char_11: 'seth',
    app_rate_11: 2.91,
    avg_round_11: 207,
    app_flat_11: 0,
    char_12: 'miyabi',
    app_rate_12: 2.87,
    avg_round_12: 102,
    app_flat_12: 0,
    char_13: 'lucy',
    app_rate_13: 1.71,
    avg_round_13: 167,
    app_flat_13: 0,
    char_14: 'burnice',
    app_rate_14: 1.2,
    avg_round_14: 181,
    app_flat_14: 0,
    char_15: 'corin',
    app_rate_15: 0.99,
    avg_round_15: 163,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 0.94,
    avg_round_16: 208,
    app_flat_16: 0,
    char_17: 'anton',
    app_rate_17: 0.77,
    avg_round_17: 154,
    app_flat_17: 0,
    char_18: 'nekomata',
    app_rate_18: 0.56,
    avg_round_18: 145,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.43,
    avg_round_19: 157,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.26,
    avg_round_20: 189,
    app_flat_20: 0,
    char_21: 'piper',
    app_rate_21: 0.21,
    avg_round_21: 209,
    app_flat_21: 0,
    char_22: 'anby-demara',
    app_rate_22: 0.17,
    avg_round_22: 182,
    app_flat_22: 0,
    char_23: 'lycaon',
    app_rate_23: 0.04,
    avg_round_23: 146,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'piper',
    round: 141,
    char_1: 'lucy',
    app_rate_1: 60.43,
    avg_round_1: 125,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 43.58,
    avg_round_2: 86,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 22.46,
    avg_round_3: 179,
    app_flat_3: 0,
    char_4: 'burnice',
    app_rate_4: 21.12,
    avg_round_4: 144,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 17.38,
    avg_round_5: 136,
    app_flat_5: 0,
    char_6: 'soukaku',
    app_rate_6: 5.35,
    avg_round_6: 105,
    app_flat_6: 0,
    char_7: 'astra-yao',
    app_rate_7: 5.35,
    avg_round_7: 114,
    app_flat_7: 0,
    char_8: 'rina',
    app_rate_8: 4.01,
    avg_round_8: 143,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 3.74,
    avg_round_9: 276,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 2.67,
    avg_round_10: 195,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 2.67,
    avg_round_11: 191,
    app_flat_11: 0,
    char_12: 'seth',
    app_rate_12: 2.41,
    avg_round_12: 246,
    app_flat_12: 0,
    char_13: 'nekomata',
    app_rate_13: 1.87,
    avg_round_13: 267,
    app_flat_13: 0,
    char_14: 'harumasa',
    app_rate_14: 1.6,
    avg_round_14: 245,
    app_flat_14: 0,
    char_15: 'qingyi',
    app_rate_15: 1.34,
    avg_round_15: 209,
    app_flat_15: 0,
    char_16: 'lighter',
    app_rate_16: 1.07,
    avg_round_16: 156,
    app_flat_16: 0,
    char_17: 'corin',
    app_rate_17: 1.07,
    avg_round_17: 236,
    app_flat_17: 0,
    char_18: 'koleda',
    app_rate_18: 0.8,
    avg_round_18: 261,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.27,
    avg_round_19: 16,
    app_flat_19: 0,
    char_20: 'ellen',
    app_rate_20: 0.27,
    avg_round_20: 300,
    app_flat_20: 0,
    char_21: 'lycaon',
    app_rate_21: 0.27,
    avg_round_21: 300,
    app_flat_21: 0,
    char_22: 'anby-demara',
    app_rate_22: 0.27,
    avg_round_22: 241,
    app_flat_22: 0,
    char_23: 'ben',
    app_rate_23: 0.27,
    avg_round_23: 197,
    app_flat_23: 0,
    char_24: 'soldier-11',
    app_rate_24: 0.27,
    avg_round_24: 236,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'ellen',
    round: 152,
    char_1: 'soukaku',
    app_rate_1: 66.6,
    avg_round_1: 151,
    app_flat_1: 0,
    char_2: 'lycaon',
    app_rate_2: 57.32,
    avg_round_2: 155,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 18.97,
    avg_round_3: 155,
    app_flat_3: 0,
    char_4: 'lighter',
    app_rate_4: 16.44,
    avg_round_4: 141,
    app_flat_4: 0,
    char_5: 'miyabi',
    app_rate_5: 8.54,
    avg_round_5: 109,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 8.01,
    avg_round_6: 120,
    app_flat_6: 0,
    char_7: 'qingyi',
    app_rate_7: 7.9,
    avg_round_7: 176,
    app_flat_7: 0,
    char_8: 'rina',
    app_rate_8: 6.43,
    avg_round_8: 156,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.37,
    avg_round_9: 137,
    app_flat_9: 0,
    char_10: 'lucy',
    app_rate_10: 1.58,
    avg_round_10: 139,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 1.37,
    avg_round_11: 211,
    app_flat_11: 0,
    char_12: 'koleda',
    app_rate_12: 1.37,
    avg_round_12: 157,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.53,
    avg_round_13: 97,
    app_flat_13: 0,
    char_14: 'yanagi',
    app_rate_14: 0.53,
    avg_round_14: 254,
    app_flat_14: 0,
    char_15: 'harumasa',
    app_rate_15: 0.32,
    avg_round_15: 204,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 0.32,
    avg_round_16: 108,
    app_flat_16: 0,
    char_17: 'burnice',
    app_rate_17: 0.32,
    avg_round_17: 217,
    app_flat_17: 0,
    char_18: 'grace-howard',
    app_rate_18: 0.21,
    avg_round_18: 222,
    app_flat_18: 0,
    char_19: 'soldier-11',
    app_rate_19: 0.11,
    avg_round_19: 600,
    app_flat_19: 0,
    char_20: 'piper',
    app_rate_20: 0.11,
    avg_round_20: 300,
    app_flat_20: 0,
    char_21: 'seth',
    app_rate_21: 0.11,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: 'corin',
    app_rate_22: 0.11,
    avg_round_22: 27,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'harumasa',
    round: 160,
    char_1: 'qingyi',
    app_rate_1: 57.93,
    avg_round_1: 148,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 40.64,
    avg_round_2: 161,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 25.44,
    avg_round_3: 152,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 19.57,
    avg_round_4: 181,
    app_flat_4: 0,
    char_5: 'caesar',
    app_rate_5: 11.02,
    avg_round_5: 174,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 9.33,
    avg_round_6: 163,
    app_flat_6: 0,
    char_7: 'astra-yao',
    app_rate_7: 7.89,
    avg_round_7: 138,
    app_flat_7: 0,
    char_8: 'miyabi',
    app_rate_8: 6.98,
    avg_round_8: 134,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 6.46,
    avg_round_9: 190,
    app_flat_9: 0,
    char_10: 'soukaku',
    app_rate_10: 3.13,
    avg_round_10: 161,
    app_flat_10: 0,
    char_11: 'lucy',
    app_rate_11: 3.13,
    avg_round_11: 182,
    app_flat_11: 0,
    char_12: 'lycaon',
    app_rate_12: 2.09,
    avg_round_12: 187,
    app_flat_12: 0,
    char_13: 'seth',
    app_rate_13: 1.89,
    avg_round_13: 229,
    app_flat_13: 0,
    char_14: 'burnice',
    app_rate_14: 1.04,
    avg_round_14: 172,
    app_flat_14: 0,
    char_15: 'lighter',
    app_rate_15: 0.91,
    avg_round_15: 178,
    app_flat_15: 0,
    char_16: 'koleda',
    app_rate_16: 0.85,
    avg_round_16: 197,
    app_flat_16: 0,
    char_17: 'jane-doe',
    app_rate_17: 0.65,
    avg_round_17: 238,
    app_flat_17: 0,
    char_18: 'piper',
    app_rate_18: 0.39,
    avg_round_18: 245,
    app_flat_18: 0,
    char_19: 'ellen',
    app_rate_19: 0.2,
    avg_round_19: 204,
    app_flat_19: 0,
    char_20: 'zhu-yuan',
    app_rate_20: 0.07,
    avg_round_20: 273,
    app_flat_20: 0,
    char_21: 'anton',
    app_rate_21: 0.07,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'koleda',
    round: 161,
    char_1: 'miyabi',
    app_rate_1: 34.95,
    avg_round_1: 110,
    app_flat_1: 0,
    char_2: 'lucy',
    app_rate_2: 27.18,
    avg_round_2: 164,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 26.21,
    avg_round_3: 149,
    app_flat_3: 0,
    char_4: 'soukaku',
    app_rate_4: 22.33,
    avg_round_4: 129,
    app_flat_4: 0,
    char_5: 'zhu-yuan',
    app_rate_5: 21.36,
    avg_round_5: 141,
    app_flat_5: 0,
    char_6: 'harumasa',
    app_rate_6: 12.62,
    avg_round_6: 197,
    app_flat_6: 0,
    char_7: 'ellen',
    app_rate_7: 12.62,
    avg_round_7: 157,
    app_flat_7: 0,
    char_8: 'rina',
    app_rate_8: 7.77,
    avg_round_8: 190,
    app_flat_8: 0,
    char_9: 'soldier-11',
    app_rate_9: 6.8,
    avg_round_9: 226,
    app_flat_9: 0,
    char_10: 'burnice',
    app_rate_10: 6.8,
    avg_round_10: 148,
    app_flat_10: 0,
    char_11: 'yanagi',
    app_rate_11: 3.88,
    avg_round_11: 120,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 3.88,
    avg_round_12: 209,
    app_flat_12: 0,
    char_13: 'piper',
    app_rate_13: 2.91,
    avg_round_13: 261,
    app_flat_13: 0,
    char_14: 'caesar',
    app_rate_14: 2.91,
    avg_round_14: 193,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 2.91,
    avg_round_15: 213,
    app_flat_15: 0,
    char_16: 'astra-yao',
    app_rate_16: 2.91,
    avg_round_16: 270,
    app_flat_16: 0,
    char_17: 'seth',
    app_rate_17: 1.94,
    avg_round_17: 229,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.97,
    avg_round_18: 186,
    app_flat_18: 0,
    char_19: 'nekomata',
    app_rate_19: 0.97,
    avg_round_19: 1,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    round: 164,
    char_1: 'rina',
    app_rate_1: 40.83,
    avg_round_1: 140,
    app_flat_1: 0,
    char_2: 'miyabi',
    app_rate_2: 29.72,
    avg_round_2: 93,
    app_flat_2: 0,
    char_3: 'harumasa',
    app_rate_3: 27.5,
    avg_round_3: 190,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 24.72,
    avg_round_4: 199,
    app_flat_4: 0,
    char_5: 'seth',
    app_rate_5: 17.22,
    avg_round_5: 214,
    app_flat_5: 0,
    char_6: 'yanagi',
    app_rate_6: 10.28,
    avg_round_6: 164,
    app_flat_6: 0,
    char_7: 'soukaku',
    app_rate_7: 9.44,
    avg_round_7: 96,
    app_flat_7: 0,
    char_8: 'caesar',
    app_rate_8: 7.78,
    avg_round_8: 186,
    app_flat_8: 0,
    char_9: 'burnice',
    app_rate_9: 6.39,
    avg_round_9: 156,
    app_flat_9: 0,
    char_10: 'qingyi',
    app_rate_10: 6.11,
    avg_round_10: 208,
    app_flat_10: 0,
    char_11: 'lucy',
    app_rate_11: 4.17,
    avg_round_11: 152,
    app_flat_11: 0,
    char_12: 'astra-yao',
    app_rate_12: 3.61,
    avg_round_12: 130,
    app_flat_12: 0,
    char_13: 'piper',
    app_rate_13: 2.78,
    avg_round_13: 195,
    app_flat_13: 0,
    char_14: 'anby-demara',
    app_rate_14: 2.78,
    avg_round_14: 208,
    app_flat_14: 0,
    char_15: 'nicole-demara',
    app_rate_15: 2.5,
    avg_round_15: 149,
    app_flat_15: 0,
    char_16: 'anton',
    app_rate_16: 1.94,
    avg_round_16: 180,
    app_flat_16: 0,
    char_17: 'koleda',
    app_rate_17: 0.83,
    avg_round_17: 213,
    app_flat_17: 0,
    char_18: 'ellen',
    app_rate_18: 0.56,
    avg_round_18: 222,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.56,
    avg_round_19: 288,
    app_flat_19: 0,
    char_20: 'lycaon',
    app_rate_20: 0.56,
    avg_round_20: 194,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.28,
    avg_round_21: 226,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seth',
    round: 166,
    char_1: 'jane-doe',
    app_rate_1: 57.37,
    avg_round_1: 190,
    app_flat_1: 0,
    char_2: 'yanagi',
    app_rate_2: 42.26,
    avg_round_2: 137,
    app_flat_2: 0,
    char_3: 'rina',
    app_rate_3: 28.36,
    avg_round_3: 138,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 16.42,
    avg_round_4: 180,
    app_flat_4: 0,
    char_5: 'lucy',
    app_rate_5: 11.29,
    avg_round_5: 165,
    app_flat_5: 0,
    char_6: 'burnice',
    app_rate_6: 10.35,
    avg_round_6: 168,
    app_flat_6: 0,
    char_7: 'qingyi',
    app_rate_7: 6.34,
    avg_round_7: 207,
    app_flat_7: 0,
    char_8: 'miyabi',
    app_rate_8: 5.88,
    avg_round_8: 111,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 5.78,
    avg_round_9: 214,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 4.01,
    avg_round_10: 149,
    app_flat_10: 0,
    char_11: 'astra-yao',
    app_rate_11: 3.36,
    avg_round_11: 147,
    app_flat_11: 0,
    char_12: 'harumasa',
    app_rate_12: 2.71,
    avg_round_12: 229,
    app_flat_12: 0,
    char_13: 'anby-demara',
    app_rate_13: 2.33,
    avg_round_13: 230,
    app_flat_13: 0,
    char_14: 'soukaku',
    app_rate_14: 1.21,
    avg_round_14: 112,
    app_flat_14: 0,
    char_15: 'piper',
    app_rate_15: 0.84,
    avg_round_15: 246,
    app_flat_15: 0,
    char_16: 'lighter',
    app_rate_16: 0.19,
    avg_round_16: 201,
    app_flat_16: 0,
    char_17: 'koleda',
    app_rate_17: 0.19,
    avg_round_17: 229,
    app_flat_17: 0,
    char_18: 'anton',
    app_rate_18: 0.19,
    avg_round_18: 160,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.09,
    avg_round_19: 136,
    app_flat_19: 0,
    char_20: 'lycaon',
    app_rate_20: 0.09,
    avg_round_20: 348,
    app_flat_20: 0,
    char_21: 'ellen',
    app_rate_21: 0.09,
    avg_round_21: 600,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anton',
    round: 166,
    char_1: 'qingyi',
    app_rate_1: 48.65,
    avg_round_1: 154,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 48.65,
    avg_round_2: 168,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 29.73,
    avg_round_3: 150,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 18.92,
    avg_round_4: 178,
    app_flat_4: 0,
    char_5: 'grace-howard',
    app_rate_5: 18.92,
    avg_round_5: 180,
    app_flat_5: 0,
    char_6: 'caesar',
    app_rate_6: 13.51,
    avg_round_6: 191,
    app_flat_6: 0,
    char_7: 'seth',
    app_rate_7: 5.41,
    avg_round_7: 160,
    app_flat_7: 0,
    char_8: 'yanagi',
    app_rate_8: 5.41,
    avg_round_8: 152,
    app_flat_8: 0,
    char_9: 'ben',
    app_rate_9: 2.7,
    avg_round_9: 222,
    app_flat_9: 0,
    char_10: 'lycaon',
    app_rate_10: 2.7,
    avg_round_10: 207,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 2.7,
    avg_round_11: 600,
    app_flat_11: 0,
    char_12: 'harumasa',
    app_rate_12: 2.7,
    avg_round_12: 600,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    round: 168,
    char_1: 'nicole-demara',
    app_rate_1: 74.19,
    avg_round_1: 154,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 32.26,
    avg_round_2: 157,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 29.03,
    avg_round_3: 165,
    app_flat_3: 0,
    char_4: 'caesar',
    app_rate_4: 29.03,
    avg_round_4: 168,
    app_flat_4: 0,
    char_5: 'lucy',
    app_rate_5: 16.13,
    avg_round_5: 188,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 3.23,
    avg_round_6: 68,
    app_flat_6: 0,
    char_7: 'ben',
    app_rate_7: 3.23,
    avg_round_7: 380,
    app_flat_7: 0,
    char_8: 'koleda',
    app_rate_8: 3.23,
    avg_round_8: 186,
    app_flat_8: 0,
    char_9: 'piper',
    app_rate_9: 3.23,
    avg_round_9: 16,
    app_flat_9: 0,
    char_10: 'nekomata',
    app_rate_10: 3.23,
    avg_round_10: 220,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 3.23,
    avg_round_11: 226,
    app_flat_11: 0,
    char_12: 'miyabi',
    app_rate_12: 3.23,
    avg_round_12: 116,
    app_flat_12: 0,
    char_13: 'soukaku',
    app_rate_13: 3.23,
    avg_round_13: 116,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    round: 170,
    char_1: 'nicole-demara',
    app_rate_1: 51.9,
    avg_round_1: 158,
    app_flat_1: 0,
    char_2: 'harumasa',
    app_rate_2: 49.59,
    avg_round_2: 181,
    app_flat_2: 0,
    char_3: 'zhu-yuan',
    app_rate_3: 24.3,
    avg_round_3: 144,
    app_flat_3: 0,
    char_4: 'rina',
    app_rate_4: 23.31,
    avg_round_4: 180,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 10.58,
    avg_round_5: 153,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 8.6,
    avg_round_6: 164,
    app_flat_6: 0,
    char_7: 'miyabi',
    app_rate_7: 6.12,
    avg_round_7: 104,
    app_flat_7: 0,
    char_8: 'seth',
    app_rate_8: 4.13,
    avg_round_8: 230,
    app_flat_8: 0,
    char_9: 'soukaku',
    app_rate_9: 3.97,
    avg_round_9: 158,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 3.8,
    avg_round_10: 265,
    app_flat_10: 0,
    char_11: 'caesar',
    app_rate_11: 3.47,
    avg_round_11: 210,
    app_flat_11: 0,
    char_12: 'ellen',
    app_rate_12: 2.15,
    avg_round_12: 211,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 1.65,
    avg_round_13: 208,
    app_flat_13: 0,
    char_14: 'billy-kid',
    app_rate_14: 1.49,
    avg_round_14: 165,
    app_flat_14: 0,
    char_15: 'lucy',
    app_rate_15: 1.32,
    avg_round_15: 155,
    app_flat_15: 0,
    char_16: 'burnice',
    app_rate_16: 1.16,
    avg_round_16: 129,
    app_flat_16: 0,
    char_17: 'anton',
    app_rate_17: 1.16,
    avg_round_17: 178,
    app_flat_17: 0,
    char_18: 'nekomata',
    app_rate_18: 1.16,
    avg_round_18: 258,
    app_flat_18: 0,
    char_19: 'qingyi',
    app_rate_19: 0.66,
    avg_round_19: 182,
    app_flat_19: 0,
    char_20: 'corin',
    app_rate_20: 0.33,
    avg_round_20: 174,
    app_flat_20: 0,
    char_21: 'piper',
    app_rate_21: 0.17,
    avg_round_21: 241,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'corin',
    round: 170,
    char_1: 'qingyi',
    app_rate_1: 58.97,
    avg_round_1: 163,
    app_flat_1: 0,
    char_2: 'rina',
    app_rate_2: 38.46,
    avg_round_2: 149,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 33.33,
    avg_round_3: 203,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 15.38,
    avg_round_4: 166,
    app_flat_4: 0,
    char_5: 'piper',
    app_rate_5: 10.26,
    avg_round_5: 236,
    app_flat_5: 0,
    char_6: 'lycaon',
    app_rate_6: 10.26,
    avg_round_6: 178,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 10.26,
    avg_round_7: 164,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 5.13,
    avg_round_8: 216,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 5.13,
    avg_round_9: 174,
    app_flat_9: 0,
    char_10: 'yanagi',
    app_rate_10: 2.56,
    avg_round_10: 153,
    app_flat_10: 0,
    char_11: 'miyabi',
    app_rate_11: 2.56,
    avg_round_11: 600,
    app_flat_11: 0,
    char_12: 'astra-yao',
    app_rate_12: 2.56,
    avg_round_12: 600,
    app_flat_12: 0,
    char_13: 'ellen',
    app_rate_13: 2.56,
    avg_round_13: 27,
    app_flat_13: 0,
    char_14: 'soukaku',
    app_rate_14: 2.56,
    avg_round_14: 27,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    round: 175,
    char_1: 'lucy',
    app_rate_1: 57.81,
    avg_round_1: 190,
    app_flat_1: 0,
    char_2: 'lighter',
    app_rate_2: 50.0,
    avg_round_2: 145,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 26.56,
    avg_round_3: 181,
    app_flat_3: 0,
    char_4: 'astra-yao',
    app_rate_4: 14.06,
    avg_round_4: 139,
    app_flat_4: 0,
    char_5: 'burnice',
    app_rate_5: 14.06,
    avg_round_5: 179,
    app_flat_5: 0,
    char_6: 'koleda',
    app_rate_6: 10.94,
    avg_round_6: 226,
    app_flat_6: 0,
    char_7: 'qingyi',
    app_rate_7: 9.38,
    avg_round_7: 189,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 6.25,
    avg_round_8: 82,
    app_flat_8: 0,
    char_9: 'ben',
    app_rate_9: 3.12,
    avg_round_9: 166,
    app_flat_9: 0,
    char_10: 'miyabi',
    app_rate_10: 1.56,
    avg_round_10: 600,
    app_flat_10: 0,
    char_11: 'ellen',
    app_rate_11: 1.56,
    avg_round_11: 600,
    app_flat_11: 0,
    char_12: 'piper',
    app_rate_12: 1.56,
    avg_round_12: 236,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    round: 176,
    char_1: 'seth',
    app_rate_1: 53.2,
    avg_round_1: 190,
    app_flat_1: 0,
    char_2: 'caesar',
    app_rate_2: 28.89,
    avg_round_2: 180,
    app_flat_2: 0,
    char_3: 'burnice',
    app_rate_3: 27.68,
    avg_round_3: 166,
    app_flat_3: 0,
    char_4: 'lucy',
    app_rate_4: 18.86,
    avg_round_4: 156,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 16.78,
    avg_round_5: 145,
    app_flat_5: 0,
    char_6: 'rina',
    app_rate_6: 15.92,
    avg_round_6: 159,
    app_flat_6: 0,
    char_7: 'qingyi',
    app_rate_7: 9.86,
    avg_round_7: 192,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 7.7,
    avg_round_8: 199,
    app_flat_8: 0,
    char_9: 'astra-yao',
    app_rate_9: 6.4,
    avg_round_9: 150,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 3.89,
    avg_round_10: 169,
    app_flat_10: 0,
    char_11: 'miyabi',
    app_rate_11: 2.68,
    avg_round_11: 89,
    app_flat_11: 0,
    char_12: 'anby-demara',
    app_rate_12: 1.99,
    avg_round_12: 265,
    app_flat_12: 0,
    char_13: 'soukaku',
    app_rate_13: 1.21,
    avg_round_13: 92,
    app_flat_13: 0,
    char_14: 'piper',
    app_rate_14: 1.21,
    avg_round_14: 276,
    app_flat_14: 0,
    char_15: 'harumasa',
    app_rate_15: 0.87,
    avg_round_15: 238,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 0.61,
    avg_round_16: 293,
    app_flat_16: 0,
    char_17: 'ellen',
    app_rate_17: 0.43,
    avg_round_17: 97,
    app_flat_17: 0,
    char_18: 'koleda',
    app_rate_18: 0.35,
    avg_round_18: 209,
    app_flat_18: 0,
    char_19: 'lycaon',
    app_rate_19: 0.26,
    avg_round_19: 201,
    app_flat_19: 0,
    char_20: 'lighter',
    app_rate_20: 0.26,
    avg_round_20: 195,
    app_flat_20: 0,
    char_21: 'corin',
    app_rate_21: 0.17,
    avg_round_21: 216,
    app_flat_21: 0,
    char_22: 'nekomata',
    app_rate_22: 0.17,
    avg_round_22: 473,
    app_flat_22: 0,
    char_23: 'anton',
    app_rate_23: 0.09,
    avg_round_23: 600,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nekomata',
    round: 209,
    char_1: 'nicole-demara',
    app_rate_1: 65.12,
    avg_round_1: 163,
    app_flat_1: 0,
    char_2: 'qingyi',
    app_rate_2: 30.23,
    avg_round_2: 145,
    app_flat_2: 0,
    char_3: 'caesar',
    app_rate_3: 30.23,
    avg_round_3: 204,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 16.28,
    avg_round_4: 258,
    app_flat_4: 0,
    char_5: 'piper',
    app_rate_5: 16.28,
    avg_round_5: 267,
    app_flat_5: 0,
    char_6: 'astra-yao',
    app_rate_6: 9.3,
    avg_round_6: 278,
    app_flat_6: 0,
    char_7: 'lucy',
    app_rate_7: 6.98,
    avg_round_7: 242,
    app_flat_7: 0,
    char_8: 'miyabi',
    app_rate_8: 6.98,
    avg_round_8: 103,
    app_flat_8: 0,
    char_9: 'yanagi',
    app_rate_9: 6.98,
    avg_round_9: 193,
    app_flat_9: 0,
    char_10: 'rina',
    app_rate_10: 4.65,
    avg_round_10: 228,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 4.65,
    avg_round_11: 473,
    app_flat_11: 0,
    char_12: 'lycaon',
    app_rate_12: 2.33,
    avg_round_12: 164,
    app_flat_12: 0,
    char_13: 'burnice',
    app_rate_13: 2.33,
    avg_round_13: 1,
    app_flat_13: 0,
    char_14: 'koleda',
    app_rate_14: 2.33,
    avg_round_14: 1,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 2.33,
    avg_round_15: 220,
    app_flat_15: 0,
    char_16: 'soukaku',
    app_rate_16: 2.33,
    avg_round_16: 600,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'ben',
    round: 600,
    char_1: 'lucy',
    app_rate_1: 75.0,
    avg_round_1: 175,
    app_flat_1: 0,
    char_2: 'lighter',
    app_rate_2: 37.5,
    avg_round_2: 129,
    app_flat_2: 0,
    char_3: 'burnice',
    app_rate_3: 18.75,
    avg_round_3: 135,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 12.5,
    avg_round_4: 166,
    app_flat_4: 0,
    char_5: 'yanagi',
    app_rate_5: 12.5,
    avg_round_5: 142,
    app_flat_5: 0,
    char_6: 'soukaku',
    app_rate_6: 6.25,
    avg_round_6: 246,
    app_flat_6: 0,
    char_7: 'anton',
    app_rate_7: 6.25,
    avg_round_7: 222,
    app_flat_7: 0,
    char_8: 'rina',
    app_rate_8: 6.25,
    avg_round_8: 222,
    app_flat_8: 0,
    char_9: 'miyabi',
    app_rate_9: 6.25,
    avg_round_9: 83,
    app_flat_9: 0,
    char_10: 'caesar',
    app_rate_10: 6.25,
    avg_round_10: 196,
    app_flat_10: 0,
    char_11: 'billy-kid',
    app_rate_11: 6.25,
    avg_round_11: 380,
    app_flat_11: 0,
    char_12: 'piper',
    app_rate_12: 6.25,
    avg_round_12: 197,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
