/* eslint-disable @typescript-eslint/no-unused-vars */
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Range, getTrackBackground } from 'react-range';

interface IProps {
  name: string;
  character_element: string;
}

export const ZZZCoreProfile: React.FC<IProps> = ({
  name,
  character_element
}) => {
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');

  // useEffect(() => {
  //   if (name === 'evelyn') {
  //     setCurrentLevel([6]);
  //   }
  // }, []);

  useEffect(() => {
    if (character_element === 'Physical') {
      setColor('#f0d12b');
    } else if (character_element === 'Fire') {
      setColor('#ff5521');
    } else if (character_element === 'Ice') {
      setColor('#98eff0');
    } else if (character_element === 'Frost') {
      setColor('#98eff0');
    } else if (character_element === 'Ether') {
      setColor('#fe437e');
    } else if (character_element === 'Electric') {
      setColor('#2eb6ff');
    }
  }, []);

  return (
    <div className="core-profile">
      <div className="skills-v2">
        <div className="tab-inside active">
          <Row xs={1} xxl={2}>
            <Col>
              <div className="box">
                <div className="skill-header">
                  <div className={`skill-icon ${character_element}`}>
                    <StaticImage
                      src="../../../../images/zzz/icons/icon_core.png"
                      alt="Core"
                    />
                  </div>
                  <div className="skill-info">
                    <p className="skill-name">
                      Core Passive {name === 'nekomata' && 'Stealthy Paws'}
                      {name === 'soldier-11' && 'Heatwave'}
                      {name === 'koleda' && 'Demolition Hammer'}
                      {name === 'lycaon' && 'Metallic Paws'}
                      {name === 'grace-howard' && 'Pre-Driven Needle'}
                      {name === 'ellen' && 'Sharp-Toothed'}
                      {name === 'rina' && 'Mini Destruction Partner'}
                      {name === 'zhu-yuan' && 'Special Ops Ammunition'}
                      {name === 'anby-demara' && 'Fluctuating Voltage'}
                      {name === 'nicole-demara' && 'Mechanical Case'}
                      {name === 'soukaku' && 'Blade Flurry'}
                      {name === 'corin' && 'Hyperfocus'}
                      {name === 'billy-kid' && 'Steady Shot'}
                      {name === 'anton' && 'Brothers in Arms!'}
                      {name === 'ben' && 'Guardian'}
                      {name === 'lucy' && 'Crimson Mane Guard'}
                      {name === 'piper' && 'Power Charge'}
                      {name === 'qingyi' && 'Eternal Seasons'}
                      {name === 'jane-doe' && 'Insight'}
                      {name === 'seth' && 'Protector'}
                      {name === 'caesar' && 'Barrier of Resilience'}
                      {name === 'burnice' && 'Nitro-Fuel Cocktail'}
                      {name === 'yanagi' && 'Lunar Eclipse'}
                      {name === 'lighter' && 'Accelerant'}
                      {name === 'miyabi' && 'Searing Cold'}
                      {name === 'harumasa' && 'Day Break'}
                      {name === 'astra-yao' && 'Graceful Andante'}
                      {name === 'evelyn' && 'Entangle'}
                    </p>
                  </div>
                </div>
                <div className="level-changer">
                  <span className="current-level">
                    Lv.{' '}
                    <span className="level" style={{ color: color }}>
                      {currentLevel}
                    </span>
                  </span>
                  <div
                    className={`level-slider ${name === 'test' && 'disabled'}`}
                  >
                    <Range
                      disabled={name === 'test' && true}
                      step={1}
                      min={1}
                      max={7}
                      values={currentLevel}
                      onChange={(values) => setCurrentLevel(values)}
                      renderTrack={({ props, children }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '100%'
                          }}
                        >
                          <div
                            ref={props.ref}
                            style={{
                              height: '5px',
                              width: '100%',
                              borderRadius: '0px',
                              background: getTrackBackground({
                                values: currentLevel,
                                colors: [color, '#484950'],
                                min: 1,
                                max: 7
                              }),
                              alignSelf: 'center'
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '20px',
                            width: '20px',
                            borderRadius: '0px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            style={{
                              height: '14px',
                              width: '5px',
                              backgroundColor: isDragged ? color : '#484950'
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={`skill-description core`}>
                  {name === 'nekomata' && (
                    <p>
                      When Nekomata's Dodge Counter or Quick Assist hits an
                      enemy, her DMG increases by{' '}
                      {Number(currentLevel) === 1 && <b>30%</b>}
                      {Number(currentLevel) === 2 && <b>35%</b>}
                      {Number(currentLevel) === 3 && <b>40%</b>}
                      {Number(currentLevel) === 4 && <b>45%</b>}
                      {Number(currentLevel) === 5 && <b>50%</b>}
                      {Number(currentLevel) === 6 && <b>55%</b>}
                      {Number(currentLevel) === 7 && <b>60%</b>} for <b>6s</b>.
                    </p>
                  )}
                  {name === 'soldier-11' && (
                    <p>
                      When triggering Fire Suppression during a Basic Attack or
                      Dash Attack, increase that attack's DMG by
                      {Number(currentLevel) === 1 && <b>35%</b>}
                      {Number(currentLevel) === 2 && <b>40.8%</b>}
                      {Number(currentLevel) === 3 && <b>46.6%</b>}
                      {Number(currentLevel) === 4 && <b>52.5%</b>}
                      {Number(currentLevel) === 5 && <b>58.3%</b>}
                      {Number(currentLevel) === 6 && <b>64.1%</b>}
                      {Number(currentLevel) === 7 && <b>70%</b>}.
                    </p>
                  )}
                  {name === 'koleda' && (
                    <p>
                      When Koleda uses her EX Special Attack or consumes Furnace
                      Fire to use an enhanced Basic Attack, the Daze she deals
                      increases by {Number(currentLevel) === 1 && <b>30%</b>}
                      {Number(currentLevel) === 2 && <b>35%</b>}
                      {Number(currentLevel) === 3 && <b>40%</b>}
                      {Number(currentLevel) === 4 && <b>45%</b>}
                      {Number(currentLevel) === 5 && <b>50%</b>}
                      {Number(currentLevel) === 6 && <b>55%</b>}
                      {Number(currentLevel) === 7 && <b>60%</b>}.
                    </p>
                  )}
                  {name === 'lycaon' && (
                    <p>
                      When Lycaon charges his Basic Attack to completion, the
                      attack deals {Number(currentLevel) === 1 && <b>40%</b>}
                      {Number(currentLevel) === 2 && <b>46.6%</b>}
                      {Number(currentLevel) === 3 && <b>53.3%</b>}
                      {Number(currentLevel) === 4 && <b>60%</b>}
                      {Number(currentLevel) === 5 && <b>66.6%</b>}
                      {Number(currentLevel) === 6 && <b>73.3%</b>}
                      {Number(currentLevel) === 7 && <b>80%</b>} increased Daze.
                      When EX Special Attack or Assist Follow-Up hits an enemy,
                      the target's <u className="Ice">Ice DMG RES</u> decreases
                      by <b>25%</b> for <b>30s</b>.
                    </p>
                  )}
                  {name === 'grace-howard' && (
                    <p>
                      When Grace's Basic Attack or Dash Attack hits an enemy and
                      deals <u className="Physical">Physical DMG</u>, she gains{' '}
                      <b>1</b> stack of Zap (up to <b>8</b> stacks). Upon
                      reaching maximum stacks, using a Special Attack or EX
                      Special Attack will consume all stacks of Zap, increasing
                      the accumulated{' '}
                      <u className="Electric">Electric Anomaly Buildup</u> by{' '}
                      {Number(currentLevel) === 1 && <b>65%</b>}
                      {Number(currentLevel) === 2 && <b>75.8%</b>}
                      {Number(currentLevel) === 3 && <b>86.6%</b>}
                      {Number(currentLevel) === 4 && <b>97.5%</b>}
                      {Number(currentLevel) === 5 && <b>108.3%</b>}
                      {Number(currentLevel) === 6 && <b>119.1%</b>}
                      {Number(currentLevel) === 7 && <b>130%</b>}.
                    </p>
                  )}
                  {name === 'ellen' && (
                    <p>
                      When Ellen launches a charged scissor strike during Dash
                      Attack: Arctic Ambush, or consumes a Flash Freeze Charge
                      to activate Basic Attack: Flash Freeze Trimming, the
                      skill's CRIT DMG is increased by{' '}
                      {Number(currentLevel) === 1 && <b>50%</b>}
                      {Number(currentLevel) === 2 && <b>58.3%</b>}
                      {Number(currentLevel) === 3 && <b>66.6%</b>}
                      {Number(currentLevel) === 4 && <b>75%</b>}
                      {Number(currentLevel) === 5 && <b>83.3%</b>}
                      {Number(currentLevel) === 6 && <b>91.6%</b>}
                      {Number(currentLevel) === 7 && <b>100%</b>}.
                    </p>
                  )}
                  {name === 'rina' && (
                    <p>
                      When ordering Drusilla or Anastella to attack, other squad
                      members' PEN Ratio increases together with Rina's, by{' '}
                      <b>25%</b> of Rina's PEN Ratio +{' '}
                      {Number(currentLevel) === 1 && <b>6%</b>}
                      {Number(currentLevel) === 2 && <b>7.5%</b>}
                      {Number(currentLevel) === 3 && <b>9%</b>}
                      {Number(currentLevel) === 4 && <b>10.2%</b>}
                      {Number(currentLevel) === 5 && <b>10.8%</b>}
                      {Number(currentLevel) === 6 && <b>11.4%</b>}
                      {Number(currentLevel) === 7 && <b>12%</b>}, up to{' '}
                      <b>30%</b>. The effect ends when both Drusilla and
                      Anastella return to Rina.
                    </p>
                  )}
                  {name === 'zhu-yuan' && (
                    <p>
                      When Zhu Yuan is under Suppressive Mode, using Enhanced
                      Shotshells increases her DMG by{' '}
                      {Number(currentLevel) === 1 && <b>20%</b>}
                      {Number(currentLevel) === 2 && <b>23.3%</b>}
                      {Number(currentLevel) === 3 && <b>26.6%</b>}
                      {Number(currentLevel) === 4 && <b>30%</b>}
                      {Number(currentLevel) === 5 && <b>33.3%</b>}
                      {Number(currentLevel) === 6 && <b>36.6%</b>}
                      {Number(currentLevel) === 7 && <b>40%</b>}. When attacking
                      Stunned enemies, this DMG buff is increased by an
                      additional {Number(currentLevel) === 1 && <b>20%</b>}
                      {Number(currentLevel) === 2 && <b>23.3%</b>}
                      {Number(currentLevel) === 3 && <b>26.6%</b>}
                      {Number(currentLevel) === 4 && <b>30%</b>}
                      {Number(currentLevel) === 5 && <b>33.3%</b>}
                      {Number(currentLevel) === 6 && <b>36.6%</b>}
                      {Number(currentLevel) === 7 && <b>40%</b>}. When Zhu Yuan
                      enters the battlefield, she immediately gains <b>6</b>{' '}
                      Enhanced Shotshells.
                    </p>
                  )}
                  {name === 'anby-demara' && (
                    <p>
                      Launch a Basic Attack: Thunderbolt, Special Attack, or EX
                      Special Attack after the 3rd-hit of Anby's Basic Attack
                      for an extra {Number(currentLevel) === 1 && <b>32%</b>}
                      {Number(currentLevel) === 2 && <b>37.3%</b>}
                      {Number(currentLevel) === 3 && <b>42.6%</b>}
                      {Number(currentLevel) === 4 && <b>48%</b>}
                      {Number(currentLevel) === 5 && <b>53.3%</b>}
                      {Number(currentLevel) === 6 && <b>58.6%</b>}
                      {Number(currentLevel) === 7 && <b>64%</b>} Daze.
                    </p>
                  )}
                  {name === 'nicole-demara' && (
                    <p>
                      After hitting an enemy with a Special Attack, EX Special
                      Attack, Chain Attack, or Ultimate, Nicole can reload,
                      enhancing her Basic Attack and Dash Attack. When her
                      enhanced bullets or Energy Field hits an enemy, the
                      target's DEF is reduced by{' '}
                      {Number(currentLevel) === 1 && <b>20%</b>}
                      {Number(currentLevel) === 2 && <b>25%</b>}
                      {Number(currentLevel) === 3 && <b>30%</b>}
                      {Number(currentLevel) === 4 && <b>34%</b>}
                      {Number(currentLevel) === 5 && <b>36%</b>}
                      {Number(currentLevel) === 6 && <b>38%</b>}
                      {Number(currentLevel) === 7 && <b>40%</b>} for 3.5s.
                    </p>
                  )}
                  {name === 'soukaku' && (
                    <p>
                      When Soukaku launches Fly the Flag, her ATK is increased
                      by {Number(currentLevel) === 1 && <b>10%</b>}
                      {Number(currentLevel) === 2 && <b>12.5%</b>}
                      {Number(currentLevel) === 3 && <b>15%</b>}
                      {Number(currentLevel) === 4 && <b>17%</b>}
                      {Number(currentLevel) === 5 && <b>18%</b>}
                      {Number(currentLevel) === 6 && <b>19%</b>}
                      {Number(currentLevel) === 7 && <b>20%</b>} up to 500, for{' '}
                      <b>22s</b>. When consuming Vortex during Fly the Flag, the
                      ATK increase is doubled, up to a max 1000. This buff given
                      can be passed along to characters switching in via Quick
                      Assist or Chain Attacks triggered by Fly the Flag, and it
                      refreshes the duration of the buff.
                    </p>
                  )}
                  {name === 'corin' && (
                    <p>
                      After holding or tapping repeatedly, Corin unleashes a
                      continuous extended slash with her Chainsaw, the skill's
                      DMG increases by{' '}
                      {Number(currentLevel) === 1 && <b>18.7%</b>}
                      {Number(currentLevel) === 2 && <b>21.8%</b>}
                      {Number(currentLevel) === 3 && <b>25%</b>}
                      {Number(currentLevel) === 4 && <b>28.1%</b>}
                      {Number(currentLevel) === 5 && <b>31.2%</b>}
                      {Number(currentLevel) === 6 && <b>34.3%</b>}
                      {Number(currentLevel) === 7 && <b>37.5%</b>}.
                    </p>
                  )}
                  {name === 'billy-kid' && (
                    <p>
                      Billy's DMG increases by{' '}
                      {Number(currentLevel) === 1 && <b>25%</b>}
                      {Number(currentLevel) === 2 && <b>29.1%</b>}
                      {Number(currentLevel) === 3 && <b>33.3%</b>}
                      {Number(currentLevel) === 4 && <b>37.5%</b>}
                      {Number(currentLevel) === 5 && <b>41.6%</b>}
                      {Number(currentLevel) === 6 && <b>45.8%</b>}
                      {Number(currentLevel) === 7 && <b>50%</b>} when he enters
                      Crouching Shot during his Basic Attack. The effect ends if
                      he moves, returns to standby, or is knocked back or
                      launched by an attack.
                    </p>
                  )}
                  {name === 'anton' && (
                    <p>
                      When Anton uses his Pile Driver Attack, the skill's DMG is
                      increased by {Number(currentLevel) === 1 && <b>12%</b>}
                      {Number(currentLevel) === 2 && <b>14%</b>}
                      {Number(currentLevel) === 3 && <b>16%</b>}
                      {Number(currentLevel) === 4 && <b>18%</b>}
                      {Number(currentLevel) === 5 && <b>20%</b>}
                      {Number(currentLevel) === 6 && <b>22%</b>}
                      {Number(currentLevel) === 7 && <b>24%</b>}. When he uses
                      his Drill Attack, the skill's DMG is increased by{' '}
                      {Number(currentLevel) === 1 && <b>20%</b>}
                      {Number(currentLevel) === 2 && <b>23.3%</b>}
                      {Number(currentLevel) === 3 && <b>26.6%</b>}
                      {Number(currentLevel) === 4 && <b>30%</b>}
                      {Number(currentLevel) === 5 && <b>33.3%</b>}
                      {Number(currentLevel) === 6 && <b>36.6%</b>}
                      {Number(currentLevel) === 7 && <b>40%</b>}.
                    </p>
                  )}
                  {name === 'ben' && (
                    <p>
                      Ben's ATK increases with his DEF. He gains{' '}
                      {Number(currentLevel) === 1 && <b>40%</b>}
                      {Number(currentLevel) === 2 && <b>46%</b>}
                      {Number(currentLevel) === 3 && <b>52%</b>}
                      {Number(currentLevel) === 4 && <b>60%</b>}
                      {Number(currentLevel) === 5 && <b>66%</b>}
                      {Number(currentLevel) === 6 && <b>72%</b>}
                      {Number(currentLevel) === 7 && <b>80%</b>} of his DEF as
                      ATK. When Ben launches a follow-up attack during his EX
                      Special Attack, all squad members gain a Shield of{' '}
                      {Number(currentLevel) === 1 && <b>15%</b>}
                      {Number(currentLevel) === 2 && <b>17.5%</b>}
                      {Number(currentLevel) === 3 && <b>20%</b>}
                      {Number(currentLevel) === 4 && <b>22.5%</b>}
                      {Number(currentLevel) === 5 && <b>25%</b>}
                      {Number(currentLevel) === 6 && <b>27.5%</b>}
                      {Number(currentLevel) === 7 && <b>30%</b>} of his DEF +
                      {Number(currentLevel) === 1 && <b>100</b>}
                      {Number(currentLevel) === 2 && <b>220</b>}
                      {Number(currentLevel) === 3 && <b>330</b>}
                      {Number(currentLevel) === 4 && <b>460</b>}
                      {Number(currentLevel) === 5 && <b>500</b>}
                      {Number(currentLevel) === 6 && <b>525</b>}
                      {Number(currentLevel) === 7 && <b>550</b>}, lasting for
                      30s.
                    </p>
                  )}
                  {name === 'lucy' && (
                    <p>
                      When Lucy uses her Special Attack or EX Special Attack, a
                      guard boar will join the battle and call in two other
                      guard boars. The guard boars inherit Lucy's ATK and
                      Impact. The Cheer On! buff for the guard boars is
                      increased to {Number(currentLevel) === 1 && <b>140%</b>}
                      {Number(currentLevel) === 2 && <b>150%</b>}
                      {Number(currentLevel) === 3 && <b>160%</b>}
                      {Number(currentLevel) === 4 && <b>170%</b>}
                      {Number(currentLevel) === 5 && <b>180%</b>}
                      {Number(currentLevel) === 6 && <b>190%</b>}
                      {Number(currentLevel) === 7 && <b>200%</b>} of the
                      original effect, and their attack frequency is increased.
                    </p>
                  )}
                  {name === 'piper' && (
                    <p>
                      When Piper's EX Special Attack: Engine Spin or Ultimate
                      hits an enemy, she gains 1 stack of Power, up to maximum
                      of 20 stacks, lasting for 12s. Repeated triggers reset the
                      duration. For every stack of Power, Piper's{' '}
                      <strong className="Physical">
                        Physical Anomaly Buildup Rate
                      </strong>{' '}
                      increases by {Number(currentLevel) === 1 && <b>2%</b>}
                      {Number(currentLevel) === 2 && <b>2.3%</b>}
                      {Number(currentLevel) === 3 && <b>2.6%</b>}
                      {Number(currentLevel) === 4 && <b>3%</b>}
                      {Number(currentLevel) === 5 && <b>3.3%</b>}
                      {Number(currentLevel) === 6 && <b>3.6%</b>}
                      {Number(currentLevel) === 7 && <b>4%</b>}.
                    </p>
                  )}
                  {name === 'qingyi' && (
                    <>
                      <p>
                        When Qingyi lands a Finishing Strike on an enemy with
                        her Basic Attack: Enchanted Moonlit Blossoms, it applies{' '}
                        <b>5</b> stacks of Subjugation to the enemy, stacking up
                        to <b>20</b> times. Each stack of Subjugation increases
                        the target's Stun DMG Multiplier by{' '}
                        {Number(currentLevel) === 1 && <b>2%</b>}
                        {Number(currentLevel) === 2 && <b>2.4%</b>}
                        {Number(currentLevel) === 3 && <b>2.7%</b>}
                        {Number(currentLevel) === 4 && <b>3%</b>}
                        {Number(currentLevel) === 5 && <b>3.4%</b>}
                        {Number(currentLevel) === 6 && <b>3.7%</b>}
                        {Number(currentLevel) === 7 && <b>4%</b>}. For each rush
                        attack during Basic Attack: Enchanted Moonlit Blossoms,
                        the finishing strike will apply an extra stack of
                        Subjugation.
                      </p>
                      <p>
                        If Perfect Dodge is triggered when using finishing
                        strike, it directly applies <b>10</b> stacks of
                        Subjugation. When applying Subjugation to normal or
                        elite enemies, the applied stacks are doubled. When the
                        target recovers from being Stunned, the corresponding
                        stacks of Subjugation are reset.
                      </p>
                    </>
                  )}
                  {name === 'jane-doe' && (
                    <>
                      <p>
                        When Jane's attacks hit an enemy, it causes them to
                        enter the Gnawed state, lasting for 10s. If any squad
                        member triggers Assault on an enemy in the Gnawed state
                        and causes the Flinch effect, the Flinch effect is
                        increased by 5s, and Assault DMG has a chance to trigger
                        a CRIT, with a base CRIT Rate of{' '}
                        {Number(currentLevel) === 1 && <b>20%</b>}
                        {Number(currentLevel) === 2 && <b>21%</b>}
                        {Number(currentLevel) === 3 && <b>22%</b>}
                        {Number(currentLevel) === 4 && <b>23%</b>}
                        {Number(currentLevel) === 5 && <b>24%</b>}
                        {Number(currentLevel) === 6 && <b>25%</b>}
                        {Number(currentLevel) === 7 && <b>40%</b>} and CRIT DMG
                        of 50%. Each point of Jane's Anomaly Proficiency further
                        increases this CRIT Rate by{' '}
                        {Number(currentLevel) === 1 && <b>0.1%</b>}
                        {Number(currentLevel) === 2 && <b>0.11%</b>}
                        {Number(currentLevel) === 3 && <b>0.12%</b>}
                        {Number(currentLevel) === 4 && <b>0.13%</b>}
                        {Number(currentLevel) === 5 && <b>0.14%</b>}
                        {Number(currentLevel) === 6 && <b>0.15%</b>}
                        {Number(currentLevel) === 7 && <b>0.16%</b>}
                      </p>
                    </>
                  )}
                  {name === 'seth' && (
                    <>
                      <p>
                        When Seth uses EX Special Attack: Thunder Shield Rush -
                        High Voltage, he gains a Shield of Firm Resolve equal to{' '}
                        {Number(currentLevel) === 1 && <b>40%</b>}
                        {Number(currentLevel) === 2 && <b>50%</b>}
                        {Number(currentLevel) === 3 && <b>60%</b>}
                        {Number(currentLevel) === 4 && <b>68%</b>}
                        {Number(currentLevel) === 5 && <b>72%</b>}
                        {Number(currentLevel) === 6 && <b>76%</b>}
                        {Number(currentLevel) === 7 && <b>80%</b>} of his
                        initial ATK, up to a max of 3,000 points, and lasting
                        25s. This can be triggered once every 10s. When Basic
                        Attack: Lightning Strike - Electrified or Assist
                        Follow-Up: Public Security Ruling triggers a Quick
                        Assist or Chain Attack, causing another squad member to
                        enter the field, that squad member gains a Shield of
                        Firm Resolve equal to{' '}
                        {Number(currentLevel) === 1 && <b>40%</b>}
                        {Number(currentLevel) === 2 && <b>50%</b>}
                        {Number(currentLevel) === 3 && <b>60%</b>}
                        {Number(currentLevel) === 4 && <b>68%</b>}
                        {Number(currentLevel) === 5 && <b>72%</b>}
                        {Number(currentLevel) === 6 && <b>76%</b>}
                        {Number(currentLevel) === 7 && <b>80%</b>} of Seth's
                        initial ATK, up to a max of 3,000 points, and lasting
                        25s. This can be triggered once every 10s. A Shield of
                        Firm Resolve grants a{' '}
                        {Number(currentLevel) === 1 && <b>50</b>}
                        {Number(currentLevel) === 2 && <b>62</b>}
                        {Number(currentLevel) === 3 && <b>75</b>}
                        {Number(currentLevel) === 4 && <b>85</b>}
                        {Number(currentLevel) === 5 && <b>90</b>}
                        {Number(currentLevel) === 6 && <b>95</b>}
                        {Number(currentLevel) === 7 && <b>100</b>} point
                        increase to Anomaly Proficiency.
                      </p>
                    </>
                  )}
                  {name === 'caesar' && (
                    <>
                      <p>
                        When Caesar activates EX Special Attack: Parry Counter,
                        Chain Attack, Ultimate, or triggers EX Special Attack:
                        Overpowered Shield Bash through Retaliation, she buffs
                        the entire team with a Radiant Aegis shield equal to{' '}
                        {Number(currentLevel) === 1 && <b>300%</b>}
                        {Number(currentLevel) === 2 && <b>500%</b>}
                        {Number(currentLevel) === 3 && <b>700%</b>}
                        {Number(currentLevel) === 4 && <b>900%</b>}
                        {Number(currentLevel) === 5 && <b>1100%</b>}
                        {Number(currentLevel) === 6 && <b>1300%</b>}
                        {Number(currentLevel) === 7 && <b>1400%</b>} of her base
                        Impact +{Number(currentLevel) === 1 && <b>300</b>}
                        {Number(currentLevel) === 2 && <b>500</b>}
                        {Number(currentLevel) === 3 && <b>700</b>}
                        {Number(currentLevel) === 4 && <b>900</b>}
                        {Number(currentLevel) === 5 && <b>1100</b>}
                        {Number(currentLevel) === 6 && <b>1300</b>}
                        {Number(currentLevel) === 7 && <b>1400</b>} The shield
                        lasts for 90s and is equipped by the on-field character,
                        with its value shared among the entire team. Any single
                        instance of DMG will not exceed the Shield value.
                      </p>
                      <p>
                        While Radiant Aegis is active, the shield bearer's
                        Anti-Interrupt level is increased, and their ATK is
                        increased by {Number(currentLevel) === 1 && <b>40</b>}
                        {Number(currentLevel) === 2 && <b>135</b>}
                        {Number(currentLevel) === 3 && <b>240</b>}
                        {Number(currentLevel) === 4 && <b>400</b>}
                        {Number(currentLevel) === 5 && <b>650</b>}
                        {Number(currentLevel) === 6 && <b>900</b>}
                        {Number(currentLevel) === 7 && <b>1000</b>}. After
                        Radiant Aegis expires, the buff remains for an
                        additional 5s.{' '}
                      </p>
                    </>
                  )}
                  {name === 'burnice' && (
                    <>
                      <p>
                        When Burnice expends Energy, she accumulates Heat, up to
                        a max of 100. She gains <b>1.4</b> Heat for each 1 point
                        of Energy consumed. At <b>50</b> Heat, she enters the
                        Nitro-Fuel Cocktail state until all Heat is expended.
                        Upon entering the battlefield, Burnice instantly
                        accumulates <b>100</b> Heat.
                      </p>
                      <p>
                        While in the Nitro-Fuel Cocktail state, when Burnice
                        hits an enemy with a Basic Attack: Mixed Flame Blend, EX
                        Special Attack, Chain Attack, or Ultimate, she applies
                        the Scorched effect to the target until she exits the
                        Nitro-Fuel Cocktail state. When other squad members hit
                        enemies under the Scorched status, it triggers the
                        Afterburn effect, expending <b>8</b> Heat to deal Fire
                        DMG equal to {Number(currentLevel) === 1 && <b>175</b>}
                        {Number(currentLevel) === 2 && <b>205</b>}
                        {Number(currentLevel) === 3 && <b>235</b>}
                        {Number(currentLevel) === 4 && <b>262</b>}
                        {Number(currentLevel) === 5 && <b>290</b>}
                        {Number(currentLevel) === 6 && <b>320</b>}
                        {Number(currentLevel) === 7 && <b>350</b>} of Burnice's
                        ATK and accumulate Fire Anomaly Buildup. This effect can
                        trigger once every <b>1.5s</b>. For every <b>10</b>{' '}
                        Anomaly Proficiency, Afterburn DMG increases by{' '}
                        <b>1%</b>, up to a max of <b>30%</b>. DMG from the
                        Afterburn effect is considered Assist Attack DMG.
                      </p>
                    </>
                  )}
                  {name === 'yanagi' && (
                    <>
                      <p>
                        After Yanagi activates her EX Special Attack, the DMG
                        multiplier of Disorder is increased by{' '}
                        {Number(currentLevel) === 1 && <b>125</b>}
                        {Number(currentLevel) === 2 && <b>145</b>}
                        {Number(currentLevel) === 3 && <b>166</b>}
                        {Number(currentLevel) === 4 && <b>188</b>}
                        {Number(currentLevel) === 5 && <b>208</b>}
                        {Number(currentLevel) === 6 && <b>230</b>}
                        {Number(currentLevel) === 7 && <b>250</b>} when any
                        squad member applies the Disorder effect to an enemy,
                        lasting <b>15s</b>.
                      </p>
                      <p>
                        When her EX Special Attack hits an enemy, Yanagi's
                        Electric DMG against the target is increased by{' '}
                        {Number(currentLevel) === 1 && <b>10</b>}
                        {Number(currentLevel) === 2 && <b>11.6</b>}
                        {Number(currentLevel) === 3 && <b>13.3</b>}
                        {Number(currentLevel) === 4 && <b>15</b>}
                        {Number(currentLevel) === 5 && <b>16.6</b>}
                        {Number(currentLevel) === 6 && <b>18.3</b>}
                        {Number(currentLevel) === 7 && <b>20</b>} for <b>15s</b>
                        .
                      </p>
                    </>
                  )}
                  {name === 'lighter' && (
                    <>
                      <p>
                        Lighter automatically gains Morale over time, at a rate
                        of <b>2.9</b> points per second. For each point of
                        Energy consumed by any squad member, Lighter recovers an
                        additional <b>0.26</b> Morale, up to a maximum of{' '}
                        <b>100</b>. When Morale reaches <b>80</b>, the next time
                        Lighter switches into battle he immediately launches a
                        Quick Assist. Once Morale is <b>80</b> or higher,
                        executing the 5th hit of his Basic Attack puts Lighter
                        in the Morale Burst state, enhancing the power of the
                        startup light punch, jab combo, and the Finishing Move
                        of the 5th hit of his Basic Attack. When a skill hits an
                        enemy with its power increased, it will continuously
                        consume Morale. With every <b>10</b> points of Morale
                        consumed, Lighter's Impact increases by{' '}
                        {Number(currentLevel) === 1 && <b>1%</b>}
                        {Number(currentLevel) === 2 && <b>1.17%</b>}
                        {Number(currentLevel) === 3 && <b>1.33%</b>}
                        {Number(currentLevel) === 4 && <b>1.5%</b>}
                        {Number(currentLevel) === 5 && <b>1.67%</b>}
                        {Number(currentLevel) === 6 && <b>1.83%</b>}
                        {Number(currentLevel) === 7 && <b>2%</b>}, up to a
                        maximum increase of{' '}
                        {Number(currentLevel) === 1 && <b>10%</b>}
                        {Number(currentLevel) === 2 && <b>11.7%</b>}
                        {Number(currentLevel) === 3 && <b>13.3%</b>}
                        {Number(currentLevel) === 4 && <b>15%</b>}
                        {Number(currentLevel) === 5 && <b>16.7%</b>}
                        {Number(currentLevel) === 6 && <b>18.3%</b>}
                        {Number(currentLevel) === 7 && <b>20%</b>}, and lasting{' '}
                        <b>6s</b>.
                      </p>
                      <p>
                        In the Morale Burst state, Lighter will no longer
                        automatically gain Morale over time. If his Morale is
                        exhausted during a startup light punch or jab combo, he
                        will automatically follow up with a more powerful
                        Finishing Move.
                      </p>
                      <p>
                        In the Morale Burst state, when startup light punches or
                        jab combos hit enemies, the target's Ice RES and Fire
                        RES are reduced by 15%, lasting <b>30</b>s.
                      </p>
                      <p>
                        In the Morale Burst state, when the Finishing Move hits
                        an enemy, it applies the Collapse effect on the target,
                        extending its Stunned duration by <b>3s</b>. The
                        Collapse effect can trigger only once on the same target
                        before they recover from Stun.
                      </p>
                      <p>
                        When the Finishing Move of the 5th hit of his Basic
                        Attack hits an enemy, when he switches off field, or
                        when his Morale runs out, Lighter will leave the Morale
                        Burst state, keeping any unspent Morale.{' '}
                      </p>
                    </>
                  )}
                  {name === 'miyabi' && (
                    <>
                      <p>
                        When Hoshimi Miyabi deals Frost DMG, she applies Icefire
                        to the target, lasting <b>30s</b>. When accumulating
                        Frost Anomaly Buildup against targets with Icefire,
                        increases Frost Anomaly Buildup Rate by <b>100%</b> of
                        Hoshimi Miyabi's CRIT Rate, up to a maximum increase of{' '}
                        <b>80%</b>. As Hoshimi Miyabi accumulates Frost Anomaly
                        Buildup, it has its own independent Attribute Anomaly
                        Buildup gauge and triggers Freeze, Shatter, and
                        Frostbite that can react with other Attribute Anomalies,
                        including Ice Attribute Anomaly, to trigger Disorder.
                      </p>
                      <p>
                        When Hoshimi Miyabi applies Frostbite to enemies with
                        Icefire, it will consume the Icefire to trigger
                        Frostburn - Break, dealing Frost DMG equal to{' '}
                        {Number(currentLevel) === 1 && <b>750%</b>}
                        {Number(currentLevel) === 2 && <b>875%</b>}
                        {Number(currentLevel) === 3 && <b>1000%</b>}
                        {Number(currentLevel) === 4 && <b>1125%</b>}
                        {Number(currentLevel) === 5 && <b>1250%</b>}
                        {Number(currentLevel) === 6 && <b>1375%</b>}
                        {Number(currentLevel) === 7 && <b>1500%</b>} of Hoshimi
                        Miyabi's ATK and causing the target to enter the
                        Frostburn state. All squad members accumulate{' '}
                        {Number(currentLevel) === 1 && <b>14%</b>}
                        {Number(currentLevel) === 2 && <b>15%</b>}
                        {Number(currentLevel) === 3 && <b>16%</b>}
                        {Number(currentLevel) === 4 && <b>17%</b>}
                        {Number(currentLevel) === 5 && <b>18%</b>}
                        {Number(currentLevel) === 6 && <b>19%</b>}
                        {Number(currentLevel) === 7 && <b>20%</b>} more Anomaly
                        Buildup against enemies under the Frostburn state, but
                        Hoshimi Miyabi cannot reapply Icefire to these enemies.
                        The Frostburn state ends when Frostbite expires. While
                        this state remains active, triggering another Attribute
                        Anomaly will overwrite Frostbite and remove Frostburn.
                      </p>
                      <p>
                        Upon triggering Frostburn - Break, Hoshimi Miyabi
                        obtains <b>1</b> point of Fallen Frost. This effect can
                        trigger once every <b>10s</b>.
                      </p>
                    </>
                  )}
                  {name === 'harumasa' && (
                    <>
                      <p>
                        The CRIT Rate of Dash Attack: Hiten no Tsuru - Slash
                        increases by{' '}
                        {Number(currentLevel) === 1 && <b>10.6%</b>}
                        {Number(currentLevel) === 2 && <b>13%</b>}
                        {Number(currentLevel) === 3 && <b>15.4%</b>}
                        {Number(currentLevel) === 4 && <b>17.8%</b>}
                        {Number(currentLevel) === 5 && <b>20.2%</b>}
                        {Number(currentLevel) === 6 && <b>22.6%</b>}
                        {Number(currentLevel) === 7 && <b>25%</b>}, and when
                        Harumasa's Dash Attack: Hiten no Tsuru - Slash triggers
                        a critical hit against an enemy, he gains <b>1</b> stack
                        of Gleaming Edge, for up to a maximum of <b>3</b> stacks
                        per use of the skill and stacking up to <b>6</b> times,
                        lasting <b>5s</b>, repeated triggers reset the duration.
                        Each stack of Gleaming Edge increases the CRIT DMG of
                        Dash Attack: Hiten no Tsuru - Slash by{' '}
                        {Number(currentLevel) === 1 && <b>6%</b>}
                        {Number(currentLevel) === 2 && <b>7%</b>}
                        {Number(currentLevel) === 3 && <b>8%</b>}
                        {Number(currentLevel) === 4 && <b>9%</b>}
                        {Number(currentLevel) === 5 && <b>10%</b>}
                        {Number(currentLevel) === 6 && <b>11%</b>}
                        {Number(currentLevel) === 7 && <b>12%</b>}.
                      </p>
                    </>
                  )}
                  {name === 'astra-yao' && (
                    <>
                      <p>
                        During the Idyllic Cadenza state, when other characters
                        switch in through a Quick Assist, Chain Attack,
                        Defensive Assist, or Evasive Assist, and when Astra Yao
                        expends Energy to release Tremolo to attack, both Astra
                        Yao and the incoming character's ATK are increased. The
                        increase is equal to{' '}
                        {Number(currentLevel) === 1 && <b>22%</b>}
                        {Number(currentLevel) === 2 && <b>24%</b>}
                        {Number(currentLevel) === 3 && <b>26%</b>}
                        {Number(currentLevel) === 4 && <b>28%</b>}
                        {Number(currentLevel) === 5 && <b>30%</b>}
                        {Number(currentLevel) === 6 && <b>32%</b>}
                        {Number(currentLevel) === 7 && <b>35%</b>} of Astra
                        Yao's initial ATK, up to a maximum of <b>1,200</b>. The
                        effect lasts for <b>20s</b> and can be extended up to{' '}
                        <b>30s</b> with repeated triggers.
                      </p>
                    </>
                  )}
                  {name === 'evelyn' && (
                    <>
                      <p>
                        Upon entering Binding Seal, Evelyn's CRIT Rate increases
                        by {Number(currentLevel) === 1 && <b>12.5%</b>}
                        {Number(currentLevel) === 2 && <b>14.6%</b>}
                        {Number(currentLevel) === 3 && <b>16.7%</b>}
                        {Number(currentLevel) === 4 && <b>18.8%</b>}
                        {Number(currentLevel) === 5 && <b>20.8%</b>}
                        {Number(currentLevel) === 6 && <b>23%</b>}
                        {Number(currentLevel) === 7 && <b>25%</b>}.
                      </p>
                      <p>
                        After leaving Binding Seal, she retains the buff for{' '}
                        <b>10s</b>. If Evelyn switches to another character
                        while in Binding Seal, she will automatically follow up
                        with Special Attack: Binding Sunder - First Form.
                      </p>
                    </>
                  )}
                </div>
                <div className="core-stats">
                  {(name === 'soldier-11' ||
                    name === 'nekomata' ||
                    name === 'billy-kid' ||
                    name === 'anton' ||
                    name === 'evelyn' ||
                    name === 'harumasa' ||
                    name === 'ellen') && (
                    <p>
                      CRIT Rate: {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+4.8%</b>}
                      {Number(currentLevel) === 3 && <b>+4.8%</b>}
                      {Number(currentLevel) === 4 && <b>+9.6%</b>}
                      {Number(currentLevel) === 5 && <b>+9.6%</b>}
                      {Number(currentLevel) === 6 && <b>+14.4%</b>}
                      {Number(currentLevel) === 7 && <b>+14.4%</b>}
                    </p>
                  )}
                  {(name === 'koleda' ||
                    name === 'lycaon' ||
                    name === 'caesar' ||
                    name === 'lighter' ||
                    name === 'qingyi' ||
                    name === 'anby-demara') && (
                    <p>
                      Impact {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+6</b>}
                      {Number(currentLevel) === 3 && <b>+6</b>}
                      {Number(currentLevel) === 4 && <b>+12</b>}
                      {Number(currentLevel) === 5 && <b>+12</b>}
                      {Number(currentLevel) === 6 && <b>+18</b>}
                      {Number(currentLevel) === 7 && <b>+18</b>}
                    </p>
                  )}
                  {(name === 'grace-howard' ||
                    name === 'jane-doe' ||
                    name === 'yanagi') && (
                    <p>
                      Anomaly Mastery: {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+12</b>}
                      {Number(currentLevel) === 3 && <b>+12</b>}
                      {Number(currentLevel) === 4 && <b>+24</b>}
                      {Number(currentLevel) === 5 && <b>+24</b>}
                      {Number(currentLevel) === 6 && <b>+36</b>}
                      {Number(currentLevel) === 7 && <b>+36</b>}
                    </p>
                  )}
                  {name === 'miyabi' && (
                    <p>
                      Anomaly Proficiency:{' '}
                      {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+30</b>}
                      {Number(currentLevel) === 3 && <b>+30</b>}
                      {Number(currentLevel) === 4 && <b>+60</b>}
                      {Number(currentLevel) === 5 && <b>+60</b>}
                      {Number(currentLevel) === 6 && <b>+90</b>}
                      {Number(currentLevel) === 7 && <b>+90</b>}
                    </p>
                  )}
                  {name === 'rina' && (
                    <p>
                      PEN Ratio {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+4.8%</b>}
                      {Number(currentLevel) === 3 && <b>+4.8%</b>}
                      {Number(currentLevel) === 4 && <b>+9.6%</b>}
                      {Number(currentLevel) === 5 && <b>+9.6%</b>}
                      {Number(currentLevel) === 6 && <b>+14.4%</b>}
                      {Number(currentLevel) === 7 && <b>+14.4%</b>}
                    </p>
                  )}
                  {(name === 'corin' || name === 'zhu-yuan') && (
                    <p>
                      CRIT DMG {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+9.6%</b>}
                      {Number(currentLevel) === 3 && <b>+9.6%</b>}
                      {Number(currentLevel) === 4 && <b>+19.2%</b>}
                      {Number(currentLevel) === 5 && <b>+19.2%</b>}
                      {Number(currentLevel) === 6 && <b>+28.8%</b>}
                      {Number(currentLevel) === 7 && <b>+28.8%</b>}
                    </p>
                  )}
                  {(name === 'nicole-demara' ||
                    name === 'soukaku' ||
                    name === 'piper' ||
                    name === 'seth' ||
                    name === 'burnice' ||
                    name === 'lucy' ||
                    name === 'ben') && (
                    <p>
                      Base Energy Regen {Number(currentLevel) === 1 && <b>0</b>}
                      {Number(currentLevel) === 2 && <b>+0.12/s</b>}
                      {Number(currentLevel) === 3 && <b>+0.12/s</b>}
                      {Number(currentLevel) === 4 && <b>+0.24/s</b>}
                      {Number(currentLevel) === 5 && <b>+0.24/s</b>}
                      {Number(currentLevel) === 6 && <b>+0.36/s</b>}
                      {Number(currentLevel) === 7 && <b>+0.36/s</b>}
                    </p>
                  )}
                  <p>
                    BASE ATK: {Number(currentLevel) === 1 && <b>0</b>}
                    {Number(currentLevel) === 2 && <b>0</b>}
                    {Number(currentLevel) === 3 && <b>+25</b>}
                    {Number(currentLevel) === 4 && <b>+25</b>}
                    {Number(currentLevel) === 5 && <b>+50</b>}
                    {Number(currentLevel) === 6 && <b>+50</b>}
                    {Number(currentLevel) === 7 && <b>+75</b>}
                  </p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="skill-header">
                  <div className={`skill-icon ${character_element}`}>
                    <StaticImage
                      src="../../../../images/zzz/icons/icon_core.png"
                      alt="Core"
                    />
                  </div>
                  <div className="skill-info">
                    <p className="skill-name">
                      Additional Ability {name === 'nekomata' && 'Catwalk'}
                      {name === 'soldier-11' && 'Fields of Fire'}
                      {name === 'koleda' && 'Belobog Management'}
                      {name === 'lycaon' && 'Elegant Predator'}
                      {name === 'grace-howard' && 'Tech Support Dept.'}
                      {name === 'ellen' && 'Rising Storm'}
                      {name === 'rina' && 'Banquet of Perfection'}
                      {name === 'zhu-yuan' && 'Tactical Coordination'}
                      {name === 'anby-demara' && 'Parallel Connection'}
                      {name === 'nicole-demara' && 'Hare of Many Burrows'}
                      {name === 'soukaku' && 'Group Set Meal'}
                      {name === 'corin' && 'Cleanup Assistance'}
                      {name === 'billy-kid' && 'Team Starlight'}
                      {name === 'anton' && 'Teamwork'}
                      {name === 'ben' && 'Contractual Agreement'}
                      {name === 'lucy' && 'Batter Up!'}
                      {name === 'piper' && 'Synced Acceleration'}
                      {name === 'qingyi' &&
                        'Three Verses of Farewell at Yang Pass'}
                      {name === 'jane-doe' && 'Sore Spot'}
                      {name === 'seth' && 'High Spirit'}
                      {name === 'caesar' && 'Battle Spirit'}
                      {name === 'burnice' && 'Fire Kindling'}
                      {name === 'yanagi' && 'Gessou'}
                      {name === 'lighter' && 'Fighting Spirit'}
                      {name === 'miyabi' && 'Bask in Frost'}
                      {name === 'harumasa' && 'Overclock'}
                      {name === 'astra-yao' && 'Moonlit Frenzy'}
                      {name === 'evelyn' && 'Ambush Point'}
                    </p>
                  </div>
                </div>
                <div className={`skill-description core`}>
                  {/* Conditionals */}
                  {name === 'zhu-yuan' ||
                    (name === 'miyabi' && (
                      <p>
                        When another character in your squad is a Support
                        character or shares the same faction:
                      </p>
                    ))}
                  {name === 'jane-doe' && (
                    <p>
                      When there are other Anomaly Agents or Agents of the same
                      faction in the squad:
                    </p>
                  )}
                  {name === 'qingyi' && (
                    <p>
                      Activated when there is at least 1 Attack-type character
                      or a character from the same faction in your squad:
                    </p>
                  )}
                  {name === 'caesar' && (
                    <p>
                      When another character in your squad can activate
                      Defensive Assist or shares the same Faction:
                    </p>
                  )}
                  {name === 'burnice' && (
                    <p>
                      When another character in your squad is an Anomaly
                      character or shares the same Faction:
                    </p>
                  )}
                  {name === 'yanagi' && (
                    <p>
                      When another character in your squad is an Anomaly
                      character or shares the same attribute:
                    </p>
                  )}
                  {name === 'lighter' && (
                    <p>
                      When another character in your squad is an Attack
                      character or shares the same Faction:
                    </p>
                  )}
                  {name === 'harumasa' && (
                    <p>
                      When another character in your squad is a Stun or Anomaly
                      character:
                    </p>
                  )}
                  {name === 'astra-yao' && (
                    <p>
                      When another character in your squad is an Attack or
                      Anomaly character:
                    </p>
                  )}
                  {name === 'evelyn' && (
                    <p>
                      When another character in your squad is a Stun or Support
                      character:
                    </p>
                  )}
                  {name != 'qingyi' &&
                    name != 'caesar' &&
                    name != 'yanagi' &&
                    name != 'lighter' &&
                    name != 'burnice' &&
                    name != 'astra-yao' &&
                    name != 'zhu-yuan' &&
                    name != 'jane-doe' &&
                    name != 'miyabi' &&
                    name != 'astra-yao' &&
                    name != 'evelyn' &&
                    name != 'harumasa' && (
                      <p>
                        When another character in your squad shares the same
                        Attribute or Faction:
                      </p>
                    )}
                  {/* Core skill */}
                  {name === 'piper' && (
                    <p>
                      When Piper has <b>20</b> or more stacks of Power, all
                      squad members' DMG is increased by 18%.
                    </p>
                  )}
                  {name === 'lucy' && (
                    <p>
                      The guard boars inherit Lucy's CRIT Rate and CRIT DMG.
                    </p>
                  )}
                  {name === 'ben' && (
                    <p>
                      While the Shield from Core Passive: Guardian is in effect,
                      the character's CRIT Rate is increased by <b>16%</b>.
                    </p>
                  )}
                  {name === 'anton' && (
                    <p>
                      While Anton is in Burst Mode, for every 4 critical hits,
                      the next attack hitting{' '}
                      <u className="Electric">Shocked</u> enemies triggers an
                      additional bout of <u className="Electric">Shock DMG</u>{' '}
                      at 45% of the original DMG. This effect can be triggered
                      once every <b>0.5s</b>.
                    </p>
                  )}
                  {name === 'billy-kid' && (
                    <p>
                      After Billy uses a Chain Attack, the DMG of his next
                      Ultimate is increased by <b>50%</b>, stacking up to{' '}
                      <b>2</b> times. This effect resets whenever an Ultimate is
                      activated.
                    </p>
                  )}
                  {name === 'nekomata' && (
                    <p>
                      After any character inflicts{' '}
                      <u className="Physical">Assault</u>, increases the DMG of
                      Nekomata's next EX Special Attack by <b>35%</b>, stacking
                      up to <b>2</b> times.
                    </p>
                  )}
                  {name === 'soldier-11' && (
                    <p>
                      Soldier 11 deals <b>10%</b> more{' '}
                      <u className="Fire">Fire DMG</u>. When attacking Stunned
                      enemies, this effect is further enhanced by an additional{' '}
                      <b>22.5%</b>.
                    </p>
                  )}
                  {name === 'koleda' && (
                    <p>
                      When the explosion from Koleda's EX Special Attack hits an
                      enemy, it applies a debuff to the target. While the target
                      is Stunned, all squad members' Chain Attacks deal{' '}
                      <b>35%</b> more DMG to the target. This effect can stack
                      up to <b>2</b> times and resets when the Stunned effect
                      ends.
                    </p>
                  )}
                  {name === 'lycaon' && (
                    <p>
                      When Lycaon hits a Stunned enemy, the target's Stun DMG
                      Multiplier increases by <b>35%</b>.
                    </p>
                  )}
                  {name === 'grace-howard' && (
                    <p>
                      Upon hitting an enemy with an EX Special Attack, the next{' '}
                      <u className="Electric">Shock</u> effect inflicted on the
                      target increases <u className="Electric">Shock</u> DMG by{' '}
                      <b>18%</b>. This effect can stack up to <b>2</b> times,
                      and each enemy can trigger it once per skill use. Resets
                      when the <u className="Electric">Shock</u> effect ends.
                    </p>
                  )}
                  {name === 'ellen' && (
                    <p>
                      When Ellen deals <u className="Ice">Ice DMG</u>,
                      subsequent <u className="Ice">Ice DMG</u> increases by{' '}
                      <b>3%</b> for <b>10s</b>. This effect can stack up to{' '}
                      <b>10</b> times, and repeated triggers reset the duration.
                    </p>
                  )}
                  {name === 'rina' && (
                    <p>
                      Increases the duration of{' '}
                      <u className="Electric">Shock</u> inflicted on enemies by
                      any character by <b>3s</b>. When{' '}
                      <u className="Electric">Shocked</u> enemies are on the
                      field, all squad members'{' '}
                      <u className="Electric">Electric DMG</u> increases by{' '}
                      <b>10%</b>.
                    </p>
                  )}
                  {name === 'zhu-yuan' && (
                    <p>
                      Zhu Yuan's CRIT Rate is increased by <b>30%</b> for{' '}
                      <b>10s</b> after using an EX Special Attack, Chain Attack,
                      or Ultimate.
                    </p>
                  )}
                  {name === 'anby-demara' && (
                    <p>
                      When Anby's Dodge Counter hits an enemy, she gains an
                      extra <b>7.2</b> Energy. This effect can trigger once
                      every <b>5s</b>.
                    </p>
                  )}
                  {name === 'nicole-demara' && (
                    <p>
                      When Nicole's Core Passive: Mechanical Case applies a
                      debuff to an enemy, all squad members deal <b>25%</b> more{' '}
                      <u className="Ether">Ether DMG</u> to the target for{' '}
                      <b>3.5s</b>.
                    </p>
                  )}
                  {name === 'soukaku' && (
                    <p>
                      When Soukaku consumes Vortex to activate Fly the Flag, the{' '}
                      <u className="Ice">Ice DMG</u> of the squad is increased
                      by <b>20%</b> for <b>22s.</b>.
                    </p>
                  )}
                  {name === 'corin' && (
                    <p>
                      Corin deals <b>35%</b> more DMG to Stunned enemies.
                    </p>
                  )}
                  {name === 'qingyi' && (
                    <p>
                      Basic Attacks deal <b>20%</b> increased Daze. If Qingyi's
                      Impact is greater than <b>120</b>, each point over
                      increases her Attack by <b>6</b>, up to a maximum of{' '}
                      <b>600</b>.
                    </p>
                  )}
                  {name === 'jane-doe' && (
                    <p>
                      Jane's <u className="Physical"> Anomaly</u> Buildup Rate
                      is increased by <b>15%</b>. When the enemy is already
                      suffering from an Attribute Anomaly, Jane's{' '}
                      <u className="Physical"> Anomaly</u> Buildup towards the
                      target is increased by an additional <b>15%</b>.
                    </p>
                  )}
                  {name === 'seth' && (
                    <p>
                      When Seth's Chain Attack: Final Judgement or finishing
                      strike during Basic Attack: Lightning Strike - Electrified
                      hits an enemy, the target's Anomaly Buildup RES to all
                      Attributes is reduced by <b>20%</b>, lasting <b>20s</b>.
                    </p>
                  )}
                  {name === 'caesar' && (
                    <p>
                      When Caesar activates Perfect Block, Retaliation,
                      Defensive Assist, or Basic Attack: Dead End, she applies a
                      Debuff effect to enemies within a <b>7m</b> radius,
                      increasing the damage they take from all teammates by{' '}
                      <b>25%</b> for <b>30s</b>.
                    </p>
                  )}
                  {name === 'burnice' && (
                    <p>
                      When Burnice's Basic Attack: Mixed Flame Blend, EX Special
                      Attack: Intense Heat Stirring Method or EX Special Attack:
                      Intense Heat Stirring Method - Double Shot hits an enemy,
                      or when the Afterburn effect is triggered, the move's
                      accumulated <u className="Fire"> Anomaly</u> Buildup
                      increases by <b>65%</b>. When any squad member applies the
                      Burn effect to an enemy, the duration of that effect is
                      extended by <b>3s</b>.
                    </p>
                  )}
                  {name === 'yanagi' && (
                    <p>
                      After switching stances, when Yanagi hits an enemy with
                      Basic Attack: Tsukuyomi Kagura,{' '}
                      <u className="Electric"> Anomaly</u> Buildup increases by{' '}
                      <b>45%</b> for <b>8s</b>.
                    </p>
                  )}
                  {name === 'lighter' && (
                    <p>
                      While under the Morale Burst state, when the 5th hit of
                      Lighter's Basic Attack hits an enemy, all squad members
                      gain a stack of Elation, stacking up to <b>20</b> times
                      and lasting <b>30s</b>. Repeated triggers reset the
                      duration. For each stack of Elation, an Agent's{' '}
                      <u className="Ice">Ice DMG</u> and{' '}
                      <u className="Fire">Fire DMG</u> increase by <b>1.25%</b>.
                      <br />
                      When obtaining Elation, if Lighter's Impact exceeds{' '}
                      <b>170</b>, every additional <b>10</b> points of Impact
                      will increase the buff effect provided by each stack of
                      Elation by an extra <b>0.25%</b>. When any squad member
                      launches a Chain Attack, it will refresh the duration of
                      all squad members' Elation. Elation can increase the{' '}
                      <u className="Ice">Ice DMG</u> and{' '}
                      <u className="Fire">Fire DMG</u> of Agents by a maximum of{' '}
                      <b>75%</b>.{' '}
                    </p>
                  )}
                  {name === 'miyabi' && (
                    <p>
                      Basic Attack: Shimotsuki DMG increases by <b>60%</b>. When
                      any squad member triggers Disorder, Hoshimi Miyabi obtains{' '}
                      <b>2</b> points of Fallen Frost and Basic Attack:
                      Shimotsuki during the next Shimotsuki Stance will ignore{' '}
                      <b>30%</b> of the target's <u className="Ice"> Ice Res</u>
                      .
                    </p>
                  )}
                  {name === 'harumasa' && (
                    <p>
                      When Harumasa's attacks hit Stunned enemies or enemies
                      under Attribute Anomaly, his DMG increases by <b>40%</b>.
                      When Harumasa's Falling Feather hits Stunned enemies or
                      enemies under Attribute Anomaly, it will apply <b>2</b>{' '}
                      stacks of Electro Prison to the target.
                    </p>
                  )}
                  {name === 'astra-yao' && (
                    <p>
                      When Energy is consumed to trigger a Precise Assist or
                      Basic Attack: Finale, Astra Yao will additionally follow
                      up with <b>1</b> Tremolo and <b>3</b> Tone Clusters.
                    </p>
                  )}
                  {name === 'evelyn' && (
                    <p>
                      Evelyn's Chain Attack and Ultimate DMG increases by{' '}
                      <b>30%</b>. When Evelyn's CRIT Rate is at or over{' '}
                      <b>80%</b>, the DMG multiplier for her Chain Attack and
                      Ultimate increases to <b>125%</b> of the original value.
                    </p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
