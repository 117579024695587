/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

interface IProps {
  name: string;
  char?: string;
}

export const ZZZWeaponNotes: React.FC<IProps> = ({ name, char }) => {
  return (
    <>
      {/* Attack */}
      {name === 'Heartstring Nocturne' && (
        <div className="information with-padding">
          {char === 'evelyn' && (
            <>
              Evelyn's Signature Engine and unsurprisingly her clear best
              choice. Grants a tremendous amount of unconditional CRIT RATE and
              CRIT DMG plus has incredibly consistent combat buff, offering up
              to 25% FIRE RES PEN for Chain Attacks and Ultimates - perfect for
              Evelyn.
            </>
          )}
          {char != 'evelyn' && (
            <>
              A great stat stick for most characters. Grants a tremendous amount
              of unconditional CRIT RATE and CRIT DMG.
            </>
          )}
        </div>
      )}
      {name === 'Zanshin Herb Case' && (
        <div className="information with-padding">
          {char === 'harumasa' && (
            <>
              Offers fantastic CRIT RATE and CRIT DMG as a permanent bonus but
              also an additional 40% ELECTRIC DMG% for Dash Attack and another
              CRIT RATE boost after any squad member applies an Anomaly or
              triggers a Stun - something that'll be happening often enough for
              close to permanent uptime. For Harumasa specifically, the grand
              total of stats is a whopping 20% CRIT RATE, 48% CRIT DMG and 40%
              DMG on his most potent ability making this engine his clear best
              in slot.
            </>
          )}
          {char === 'evelyn' && (
            <>
              A solid all around generic option for Evelyn thanks to its close
              to permanent uptime of 20% CRIT RATE and always active 45% CRIT
              DMG, alongside its high base ATK.
            </>
          )}
          {char != 'evelyn' && char != 'harumasa' && (
            <>
              A solid all around generic option thanks to its close to permanent
              uptime of 20% CRIT RATE and always active 45% CRIT DMG, alongside
              its high base ATK.
            </>
          )}
        </div>
      )}
      {name === 'Gilded Blossom' && (
        <div className="information with-padding">
          A weaker alternative to Starlight Engine that offers ATK% and
          increases the damage of EX Special attacks. Only use if you lack
          better W-Engines.
        </div>
      )}
      {name === 'Marcato Desire' && (
        <div className="information with-padding">
          Reasonable option for CRIT scaling agents playing in teams capable of
          applying Anomaly semi-consistently to trigger and maintain its
          secondary bonus effect a decent amount of the time.
        </div>
      )}
      {name === 'Riot Suppressor Mark VI' && (
        <div className="information with-padding">
          Zhu Yuan's Signature W-Engine is an engine for Ether DPS characters
          with a focus on dealing a large amount of their damage through Basic
          Attack. The bonuses of this Engine are simple but effective and for a
          character that can take advantage of all of them a massive damage
          boost making it likely one of their best options.
        </div>
      )}
      {name === 'Deep Sea Visitor' && (
        <div className="information with-padding">
          Ellen's Signature W-Engine and an excellent choice for any Ice Attack
          Agents. Grants 24% CRIT Rate and 25% Ice DMG unconditionally, 10%
          additional CRIT Rate after hitting an enemy with a Basic Attack for 8
          seconds and a further 10% CRIT Rate after hitting an enemy with an Ice
          DMG Dash Attack.
        </div>
      )}
      {name === 'Drill Rig - Red Axis' && (
        <div className="information with-padding">
          Anton's Signature W-Engine a specialized option design specifically
          with his kit in mind but which can be used to varying degrees of
          success by other Electric Combat characters with a focus on
          specifically Electric Basic burst damage.{' '}
          <strong className="red">
            If you feel you don't need the Energy Regen from this W-engine on
            Anton, its value drops significantly as it cannot compete in raw
            damage output compared to all other Engines, as such you should
            consider it lower on this list.
          </strong>
        </div>
      )}
      {name === 'Starlight Engine Replica' && (
        <div className="information with-padding">
          Billy's Signature W-Engine an excellent option for ranged Physical DMG
          Combat characters. Upon hitting an enemy while at least 6 meters away
          with a Basic or Dash Attack the wearer gains a buff granting 57.5%
          Physical DMG for 8 seconds. The size of this buff and the fact it is
          permanently and easily maintainable makes it easily one of the top
          options for all ranged Physical Combat characters.
        </div>
      )}
      {name === 'The Brimstone' && (
        <div className="information with-padding">
          Soldier 11's Signature W-Engine and a good choice for any character
          with a focus on using a lot of Basic Attacks or even just a few long
          lasting ones. The catch is each buff stack has its own duration and
          only 1 buff can be gained every 0.5 seconds, making maintaining max
          stacks of this buff require constant Basic Attacks.
        </div>
      )}
      {name === 'Starlight Engine' && (
        <div className="information with-padding">
          A solid all-around Free To Play accessible option for all Attack
          Agents. Grants 25% unconditional Standard Attack and a further 19.2%
          (At S5) Combat Attack buff after performing a Dodge Counter or Quick
          Assist lasting 12 seconds. This buff is reasonably easy for all Attack
          Agents to achieve in both their standard and burst rotations, assuming
          a Support character is present on the team.
        </div>
      )}
      {name === 'Steel Cushion' && (
        <div className="information with-padding">
          Nekomata's Signature W-Engine - a strong option for Physical Attack
          Agents and also a usable option for others but with the requirement
          for both being that the player must actively aim to attack from behind
          targets wherever possible in order to get good performance compared to
          alternatives.{' '}
          {char === 'billy-kid' && (
            <span className="red">
              Assumes you're attacking the enemy 100% from behind which is
              nearly impossible to do consistently.
            </span>
          )}
        </div>
      )}
      {name === 'Cannon Rotor' && (
        <div className="information with-padding">
          The battle pass W-engine - an expensive engine to acquire which while
          incredibly consistent is often weaker than other W-Engines. The main
          issue with the on Crit trigger ability is that the damage is Physical
          and cannot CRIT meaning for non-physical characters you'll be losing
          all elemental DMG% boosts on this damage and for all characters not
          CRITing is a big downside making the damage from this unimpactful.
        </div>
      )}
      {name === 'Street Superstar' && (
        <div className="information with-padding">
          A decent but somewhat niche Free To Play accessible option for all
          Attack Agents. Against bosses you can guarantee 3 Chain Attacks every
          time you Stun them, meaning this W-engine's effect essentially grants
          72% Ultimate damage. These bonuses allow it to be a competitive option
          during burst rotations with the ultimate available, but when the
          ultimate isn't available or executing a standard rotation this
          W-engine falls behind.
        </div>
      )}
      {name === 'Housekeeper' && (
        <div className="information with-padding">
          Corin's Signature W-Engine designed almost exclusively for her with
          its primary bonus requiring channelled fast-hitting Physical attacks
          to fully utilize. For Corin this engine is great, specifically the off
          field Energy Regen is incredibly potent, allowing her to much more
          easily execute her fully charged EX Special every time an enemy is
          stunned, the Physical DMG bonus is also easy to stack and a big damage
          gain. For anyone else, there are much better options.
        </div>
      )}
      {/* Stun */}
      {char === 'lighter' ? (
        <>
          {name === 'Blazing Laurel' && (
            <div className="information with-padding">
              Lighter's Signature and naturally his best option. It allows for
              comfortable access to the full power of his Additional Ability
              while also boosting his Impact% and Combat Impact % considerably
              throughout all of his rotation. It further boosts the entire
              team's ICE and FIRE CRIT DMG% by up to 30% - a fantastic
              supportive boon.
            </div>
          )}
          {name === 'Ice-Jade Teapot' && (
            <div className="information with-padding">
              Qingyi's Signature is a surprisingly solid alternative to
              Lighter's Signature if you already have it. Lighter is capable of
              stacking the 30 stack bonus of this W-Engine to gain a large
              enough Combat Impact% buff which when combined with the standard
              Impact% gives him enough to satisfy his Additional Ability. This
              Engine also grants 20 DMG% to the team for a short duration
              offering a nice supportive bonus.
            </div>
          )}
          {name === 'Hellfire Gears' && (
            <div className="information with-padding">
              Reasonable non-limited choice capable of maximizing Lighter's
              Additional Ability bonus but with some inconvenience. The Combat
              Impact% buff of this Engine is reliant on using Lighter's EX 1 and
              EX Follow-Up first meaning you have to execute them ideally before
              your Morale combo thus restricting your options.
            </div>
          )}
          {name === 'The Restrained' && (
            <div className="information with-padding">
              This Engine is not capable of fully activating Lighter's
              Additional Ability resulting in a reduction in his supportive
              capabilities. Still provides enough Impact for a reasonable buff
              though and also grants him solid buffs resulting in reasonable
              Daze output overall.
            </div>
          )}
          {name === 'Steam Oven' && (
            <div className="information with-padding">
              An alternative 4-star option to Precious Fossilized Core with
              higher total Impact potential but less consistency due to its
              conditional effect. If you're adept at managing the uptime of this
              Engine in combat ability it can outperform Fossilized Core when
              playing Lighter with supportive buffs in mind.
            </div>
          )}
          {name === 'Six Shooter' && (
            <div className="information with-padding">-</div>
          )}
          {name === 'Precious Fossilized Core' && (
            <div className="information with-padding">
              Another Engine unable to fully enable Lighter's Additional
              Ability, but which gets him a reasonable amount of the way there
              serving as a reasonable 4-star option for those without stronger
              alternatives.
            </div>
          )}
          {name === '[Vortex] Arrow' && (
            <div className="information with-padding">-</div>
          )}
        </>
      ) : (
        <>
          {name === 'Blazing Laurel' && (
            <div className="information with-padding">-</div>
          )}
          {name === 'Ice-Jade Teapot' && (
            <div className="information with-padding">
              Qingyi's Signature W-Engine specifically designed for her but it
              can be used by other Stun characters with incredibly quick and
              frequent Basic Attack damage. Each stack of Tea-riffic grants 0.7%
              Combat Impact for a whopping total of 21% and as each instance of
              damage in a basic attack grants a stack, characters with Basic
              Attacks that are split up into multiple tiny hits can stack and
              maintain this effect with relative ease (Qingyi herself should
              always have 30 stacks).
            </div>
          )}
          {name === 'Hellfire Gears' && (
            <div className="information with-padding">
              Koleda's Signature W-Engine and an incredible option for all Stun
              characters that use their EX Special Attack at least somewhat
              regularly. Maintaining at least 1 stack of this buff consistently
              is expected for most characters using this W-engine but if you can
              gain 2 stacks even for a moment its value only increases further.
              Energy Recharge, strong base stats and an incredible combat buff
              make this Engine a safe option on most characters.
            </div>
          )}
          {name === 'The Restrained' && (
            <div className="information with-padding">
              Lycaon's Signature W-Engine is an engine with good Stun buffing
              stats but also higher damage output compared to many other generic
              options. A reasonable option for all Stun characters with
              competitive stun buffs, but trades the energy regen of some other
              stun W-engines in exchange for a damage buff.
            </div>
          )}
          {name === 'Steam Oven' && (
            <div className="information with-padding">
              Outstanding F2P accessible A-Rank W-Engine that is among the top
              options for many Stun characters thanks to its incredibly potent
              combat effect. With its bonus partially or better yet fully active
              this will be a very competitive option for most Stun characters
              when compared to similar rarity engines.
            </div>
          )}
          {name === 'Six Shooter' && (
            <div className="information with-padding">
              The Stun battle pass W-engine - an expensive and timely Engine to
              acquire but which has great potential for EX Special Burst Daze
              but still only performs similarly or below other W-Engine's with
              similar investment over the full course of a fight. This W-Engine
              offers large spikes of Daze but requires you to space those EX's
              out to gain maximum benefit. The engine also has no extra benefits
              like Energy Regen or Damage stats making it a situational choice
              for those looking for huge burst daze.
            </div>
          )}
          {name === 'Precious Fossilized Core' && (
            <div className="information with-padding">
              A specialized F2P but strong A-Rank Engine, that trades long term
              benefit for immediate power. This Engine is ideal for those
              looking to burst an enemy into the Stunned state initially with
              little regard for the lower stuns they'll have to deal with later,
              for this reason it does have endgame uses but over long fights can
              fall behind other similar investment options.
            </div>
          )}
          {name === '[Vortex] Arrow' && (
            <div className="information with-padding">
              Incredible F2P Stun W-Engine that offers generic Daze boosts to
              the entire characters kit with no conditionals to play around.
              Grants 12% Impact unconditionally and a 12% Daze increase against
              the wearers primary target. This Engine doesn't have any of the
              additional bonuses such as Energy Regen, Damage increases or burst
              daze potential - higher rarity options have but is able to mostly
              keep up in raw Daze output over longer fights making it a good
              stepping stone option until you can upgrade.
            </div>
          )}
        </>
      )}
      {/* Anomaly */}
      {name === 'Hailstorm Shrine' && (
        <div className="information with-padding">
          Offers tremendous CRIT RATE and CRIT DMG unconditionally with an
          additional bonus of up to 40% additional ICE DMG when squad mates are
          able to consistently apply attribute Anomalies. The clear best in slot
          choice for Miyabi by a long shot as no other Anomaly W-Engines current
          possess the CRIT stats she so effectively scales with.
        </div>
      )}
      {name === 'Timeweaver' && (
        <div className="information with-padding">
          Outstanding W-Engine for Yanagi and the Disorder playstyle. The 25%
          boost from this Engine is a separate multiplier to all other %
          increases making it a true 25% extra Disorder damage for all Disorders
          Yanagi triggers including her Polarity Disorders. On top of that the
          engine has great stats as well making it the top option for Yanagi in
          not only in Disorder teams but also in mono Electric.
        </div>
      )}
      {name === 'Flamemaker Shaker' && (
        <div className="information with-padding">
          Best option for Burnice in all scenarios with the slight downside of
          requiring some ramp-up time at the start of fights. Offers more damage
          when fully ramped than all other options and also offers Burnice
          additional Energy Regen while off-field, assisting her with her Energy
          and Heat management. In incredibly short fights or fights where you're
          unable to ramp-up stacks quickly this W-Engine can perform slightly
          worse than represented (we've calculated this engine using a standard
          length max rating Shiyu run for duration assumptions).
        </div>
      )}
      {name === 'Sharpened Stinger' && (
        <div className="information with-padding">
          Jane's signature W-Engine is designed specifically for Physical
          Anomaly characters who incorporate Dodge Counters and Dash Attacks
          into their standard rotation. To maximize its potential, it's
          important to maintain 3 stacks always, especially just before and
          during burst combos. As a result, this engine is one of the best
          choices for Physical Anomaly characters.
        </div>
      )}
      {name === 'Roaring Ride' && (
        <div className="information with-padding">
          Piper's Signature W-Engine - a specialized option for Anomaly
          characters with an incredibly spammable or channelable EX Special in
          their kit. The buffs grant 12.8% Combat Attack, 64% Anomaly
          Proficiency and 40% Anomaly Buildup Rate respectively and if you can
          maintain them all consistently, it allows this engine to be a complete
          powerhouse. The catch with this W-engine is that it can be fully used
          only by characters who can spam their EX as you can only gain a buff
          every 0.3 seconds so for most characters you'll gain one buff per EX
          cast.
        </div>
      )}
      {char === 'jane-doe' && (
        <>
          {name === 'Fusion Compiler' && (
            <div className="information with-padding">
              Grace's Signature W-Engine, while typically a strong choice, is
              just moderately effective for Jane. The reason this engine this
              engine is not suitable for Jane is due to its lower consistency in
              providing Anomaly Proficienc - which can cause her to fall short
              of maxing her Passion State Flat Attack bonus without adding any
              external support or posessing high end gear. But if you can
              mitigate this shortfall with additional gear or team support, this
              engine's competitiveness will improve significantly.
            </div>
          )}
          {name === 'Weeping Gemini' && (
            <div className="information with-padding">
              A solid Free-to-Play accessible for all Anomaly Agents, it grants
              25% Standard ATK% unconditionally and offers flexibility with
              Anomaly Proficiency on semi-permanent basis that stacks when
              Anomalies are inflicted. Its utility is maximized when your team
              is designed to maintain continuous Anomaly Pressure by ensuring
              that the stacks remain active for as long as possible.
            </div>
          )}
          {name === 'Electro-Lip Gloss' && (
            <div className="information with-padding">
              The Anomaly Battle Pass W-Engine (an expensive and timely engine
              to acquire) offers 75 Anomaly Proficiency unconditionally and dual
              combat effects that activate when an enemy is afflicted with an
              Attribute Anomaly. This engine features a major catch and that is,
              damage of Anomalies inflicted is based on damager character deals
              during Anomaly Gauge build up, and as such, unless enemy already
              has Anomaly, the newly applied one lacks any of the conditional
              bonuses of this Engine. So it’s recommended to pair it with a team
              that can apply Anomalies quickly to avoid missing out on its
              benefits. The engine shines when Jane fights enemies with
              long-term Anomaly afflictions, but if not, its performance drops
              significantly.
            </div>
          )}
          {name === 'Rainforest Gourmet' && (
            <div className="information with-padding">
              Another free-to-play option offers 75 Anomaly Proficiency
              unconditionally and a stackable Combat ATK% buff. This engine is a
              good choice for characters that are all about rapidly using their
              energy reserves through EX Specials to apply Anomalies in quick
              and powerful bursts.
            </div>
          )}
        </>
      )}
      {char === 'burnice' && (
        <>
          {name === 'Fusion Compiler' && (
            <div className="information with-padding">
              Decent and reasonably accessible option for Burnice offering
              passable permanent stat boosts and a Combat bonus, which depending
              how hard you're willing to try can range from just ok to pretty
              strong. The stackable Anomaly Proficiency boost from this engine
              triggers every time Burnice starts channelling either of her EXs.
              This means at minimum when using her EX 1 straight into E2 you'll
              generate 2 stacks for very little time or energy commitment. If
              you want to take it further you can start an EX channel and
              immediately dash to cancel it while also still getting the buff as
              well. During standard play you'll have 0-2 stacks of this bonus
              but for the dedicated you can maintain up to 3 a good amount of
              the time (we've calculated this engine using standard gameplay
              without cancels).
            </div>
          )}
          {name === 'Weeping Gemini' && (
            <div className="information with-padding">
              Strong completely F2P option for Burnice especially for Disorder
              heavy teams with an emphasis on triggering as many Anomalies as
              possible, allowing for much higher average stack counts for this
              engine (performance will vary depending on how many enemies are
              present and how frequently you can apply Anomalies per stun
              window).
            </div>
          )}
          {name === 'Electro-Lip Gloss' && (
            <div className="information with-padding">
              Next best W-Engine option for Burnice behind her signature.
              Burnice can fully take advantage of all the stats this W-Engine
              has to offer while also easily mitigating its usually stiff
              downside of always needing an Anomaly active on all enemy targets
              thanks to her absurdly quick and consistent Burn application. This
              W-Engine becomes even more consistent when playing Burnice in
              Disorder teams thanks to even more frequent Anomaly application.
            </div>
          )}
          {name === 'Rainforest Gourmet' && (
            <div className="information with-padding">
              Alternative F2P option to Weeping Gemini with a much larger focus
              on Burst damage due to one of it's biggest bonuses being
              concentrated in a 10 second window during and after Burnice's EX
              Special attack. Strong during this burst window but due to the
              fact a large amount of Burnice damage is spread out throughout the
              course of a fight this Engine can lose efficacy in more drawn out
              encounters unless you're able to trigger some very timely Disorder
              combos.
            </div>
          )}
        </>
      )}
      {char === 'yanagi' && (
        <>
          {name === 'Fusion Compiler' && (
            <div className="information with-padding">
              Another alternative on offer and one that Yanagi can make good use
              of. Getting the most out of this W-Engine requires frequent uses
              of either Special attacks or EX-Special attacks, something she
              does naturally due to her Stance-Swaps. As long as you dedicate a
              reasonable amount of field time to her, she should be able to
              maintain a good amount of Anomaly Proficiency stacks without too
              much trouble.
            </div>
          )}
          {name === 'Weeping Gemini' && (
            <div className="information with-padding">
              The last of Yanagi's decent non-limited Engine options and one
              that has potential to outperform those above under the right
              circumstances. We've calculated this W-engine with an average of
              2.5 stacks overall but due to the frequency in which Yanagi and
              her team can apply Anomaly this engine can perform even better
              especially against enemies that are slow to Stun.
            </div>
          )}
          {name === 'Electro-Lip Gloss' && (
            <div className="information with-padding">
              One of a few decent alternatives to Yanagi's Signature if you
              don't manage to acquire it. Yanagi has excellent Anomaly Buildup
              meaning all of this Engine's bonuses are possible to keep active
              almost all of the time with the exception of the very first
              Anomaly you apply to the enemy.
            </div>
          )}
          {name === 'Rainforest Gourmet' && (
            <div className="information with-padding">
              An all-around underwhelming option that falls behind alternatives
              while also requiring careful use and sequencing of EX
              Specials/Energy to get the most out of.
            </div>
          )}
        </>
      )}
      {char != 'yanagi' && char != 'burnice' && char != 'jane-doe' && (
        <>
          {name === 'Fusion Compiler' && (
            <div className="information with-padding">
              Grace's Signature W-Engine and an outstanding all-around choice
              for most Anomaly characters. The wearer can easily gain a stack
              every time they use a Special Attack or EX Special Attack and
              abilities that activate multiple times or in sequence also grant
              multiple stacks.
            </div>
          )}
          {name === 'Weeping Gemini' && (
            <div className="information with-padding">
              A solid Free-to-Play accessible for all Anomaly Agents, it grants
              25% Standard ATK% unconditionally and offers flexibility with
              Anomaly Proficiency on semi-permanent basis that stacks when
              Anomalies are inflicted. Its utility is maximized when your team
              is designed to maintain continuous Anomaly Pressure by ensuring
              that the stacks remain active for as long as possible.
            </div>
          )}
          {name === 'Electro-Lip Gloss' && (
            <div className="information with-padding">
              The Anomaly battle pass W-engine - an expensive engine to acquire
              and one that can either be exceptionally powerful or fairly weak
              depending on the combat circumstances, team and gameplay utilized
              alongside it. This Engine is only recommended for teams that
              feature a way to apply Anomaly before the wearer of this engine
              does or is able to upkeep anomaly at all times on relevant
              enemies.
            </div>
          )}
          {name === 'Rainforest Gourmet' && (
            <div className="information with-padding">
              Another free-to-play option offers 75 Anomaly Proficiency
              unconditionally and a stackable Combat ATK% buff. This engine is a
              good choice for characters that are all about rapidly using their
              energy reserves through EX Specials to apply Anomalies in quick
              and powerful bursts.
            </div>
          )}
        </>
      )}
      {/* Support */}
      {char === 'seth' && (
        <>
          {name === 'Peacekeeper - Specialized' && (
            <div className="information with-padding">
              Without a doubt the best W-Engine for Seth, it provides him
              unconditional Standard Attack% to improve the size of his Shields,
              a great amount of extra Energy Regen for boosting his main burst
              combo accessibility along with a massive increase to the Anomaly
              build-up of two of his strongest attacks making him more valuable
              in Disorder teams.
            </div>
          )}
          {name === 'Spring Embrace' && (
            <div className="information with-padding">
              An alternative to Peacekeeper, Spring Embrace emphasizes support
              for teammates. This Engine grants unconditional Standard ATK%
              which improves Seth's shields while also providing an energy
              regeneration buff when Seth is hit by enemy attacks. The Energy
              regeneration buff can be transferred to teammate upon character
              Swap, fitting well with Seth's Quick Assist playstyle. This set
              improves the Shield and Anomaly Proficiency buffs Seth already
              provides on character Swap, making it a solid choice for teams
              that are focusing in maximizing support.
            </div>
          )}
          {name === 'Bunny Band' && (
            <div className="information with-padding">
              Bunny Band falls behind Seth's top options, due to its situational
              benefits. It provides 40% Defense, 12.8% Combat HP, and 16% Combat
              Attack while shielded (at max rank). While on paper, the Combat
              ATK looks appealing, it does not improve the size of Seth's
              shields due to the multiplier being limited to "initial ATK,"
              which excludes combat buffs. Its defensive stats also do not scale
              with Seth's kit, making this set less effective compared to others
            </div>
          )}
          {name === 'Original Transmorpher' && (
            <div className="information with-padding">
              This W-engine leans heavily towards defensive stats, which do not
              significantly benefit Seth's kit. However it does provides a buff
              that offers up to 16% Combat Impact for 12 seconds after taking a
              hit. This is not as strong as Seth's top choices but it offers
              some utility thanks to the Combat Impact percentage, particularly
              in a pool of limited defensive options for Seth.
            </div>
          )}
        </>
      )}
      {char === 'caesar' && (
        <>
          {name === 'Tusks of Fury' && (
            <div className="information with-padding">
              Caesar's signature and by far her best option. Offers 18% Impact
              and a 30% increase to all Shields triggered by the wearer
              massively increasing her Aegis size and boosting her Daze output
              nicely. Also possesses a combat buff that affects the entire squad
              - whenever a squad member triggers either an Interrupt or a
              Perfect Dodge all squad members gain 18% increased DMG and 12%
              increased Daze for 20 seconds, further boosting Caesar's
              supportive capabilities and Daze output even more.
            </div>
          )}
          {name === 'Peacekeeper - Specialized' && (
            <div className="information with-padding">
              An anomaly-themed damaging option for Caesar with the niche of
              increasing her Anomaly build-up which can be handy for Anomaly
              specific teams - especially ones aiming to trigger Disorder. These
              bonuses are at the cost of additional Daze and Aegis Potency
              though.
            </div>
          )}
          {name === 'Spring Embrace' && (
            <div className="information with-padding">
              A specialist support W-Engine granting energy generation to an
              ally of your choosing when played around while also offering some
              offensive stats in the form of an ATK% boost. After taking damage
              the wearer gains a 16% Energy Regen boost for 12 seconds - upon
              switching to another squad member this buff is transferred to them
              instead. Given Caesar's Defensive Assist spamming playstyle this
              engine is very easy to trigger and constantly transfer again and
              again to a character of your choice making it a strong option for
              those who desire its supportive effect at the cost of Aegis
              potency and Daze potential.
            </div>
          )}
          {name === 'Original Transmorpher' && (
            <div className="information with-padding">
              Only Daze focused option available within the catalogue of Defense
              W-engine's outside of Caesar's Signature. Grants a massive amount
              of HP% and Combat HP% unconditionally and a temporary Combat
              Impact% boost after Caesar is hit, improving her Daze
              capabilities. Unfortunately Caesar's Aegis scaling is based on
              base Impact only and Combat Impact does not increase the shields
              potency - for this reason this engine loses out to an S or A rank
              Stun Engine unless you require the additional HP boost.
            </div>
          )}
        </>
      )}
      {char === 'astra-yao' && (
        <>
          {name === 'Elegant Vanity' && (
            <div className="information with-padding">
              Astra's Signature and one of multiple of her top choices. Provides
              an excellent amount of ATK% to assist with capping out the buff
              from her Core Passive, 5 extra Energy any time any squadmate
              performs a Quick Assist, Chain Attack, Defensive Assist or Evasive
              Assist (With Astra's kit this is constantly), granting her a tonne
              of extra energy and finally an up to 20% team wide DMG% boost she
              can maintain indefinitely. Astra's Signature is her best option in
              many cases but under ideal gearing scenarios is closer than you
              might expect to alternatives sometimes - even being beaten out by
              them in raw buffing capabilities in some teams.{' '}
              <strong>
                Because of this, Astra's signature is not as integral to her
                gameplay as many other characters making it a luxury and not a
                necessity.
              </strong>
            </div>
          )}
          {name === 'Bashful Demon' && (
            <div className="information with-padding">
              Soukaku's Signature at maximum rank is a very competitive option
              for Astra, due to its excellent team-wide buffs and stats. Bashful
              Demon grants Astra ATK% for her Core Passive and an up to 12.8%
              Combat ATK buff for the whole team. The Combat ATK buff of this
              engine is usually hard to keep active but Astra can build a stack
              of its effect each time she triggers her Tremelo, making it no
              issue with correct Quick Assist management. Lacks the energy
              recovery of some other options, but offers some of the best team
              buffing and consistency of all W-Engine choices.
            </div>
          )}
          {name === 'Kaboom the Cannon' && (
            <div className="information with-padding">
              Lucy's Signature at maximum rank is also a solid option thanks to
              its Energy Regen bonus and team ATK increase. Kaboom Cannon grants
              Astra 50% Energy Regen and an up to 12% Combat ATK buff for the
              whole team but with some restrictions. Kaboom requires each team
              member hit the enemy once every 4 seconds to maintain full effect.
              Astra's Tremelo counts toward this but you'll still need to ensure
              you manage your Quick Assists effectively and continually swap
              between characters to ensure maximum uptime. The W-Engine ss
              missing an ATK% main stat, putting more pressure on other areas of
              Astra's build when hitting her Core Passive cap. Also harder to
              fully maintain its team wide buffing effect.
            </div>
          )}
          {name === 'The Vault' && (
            <div className="information with-padding">
              Nicole's Signature at maximum rank is a usable but not perfect
              Engine for Astra. The Vault grants her 50% Energy Regen and a
              combat trigger that occurs whenever her Chain Attack, Tremelo or
              Ultimate deals damage to an enemy. This trigger will increase her
              Energy Regen by 0.8/s and her teams damage against the struck
              target by 24% both for 2 seconds. This Engine has everything Astra
              wants except for ATK, but requires careful management and team
              play for it to perform well against alternatives given its
              incredibly short 2 second duration.
            </div>
          )}
        </>
      )}
      {char != 'astra-yao' && (
        <>
          {name === 'Bashful Demon' && (
            <div className="information with-padding">
              Provides great synergy to Soukaku's rotations since she needs to
              hit her EX skills to provide her attack buffs.
            </div>
          )}
          {name === 'Kaboom the Cannon' && (
            <div className="information with-padding">
              Best General Support W-Engine outside of Weeping Cradle. It
              provides main stat Energy Regen and constant uptime on the combat
              Attack for the team - easily a great option if you do not have the
              support units exclusive W-Engine.
            </div>
          )}
          {char === 'rina' && (
            <>
              {name === 'Weeping Cradle' && (
                <div className="information with-padding">
                  Rina's Signature W-Engine and one of the best damage
                  increasing weapons for Supports currently. While off-field it
                  generates 0.6 Energy per second to the wearer and also applies
                  a debuff for 3 seconds that increases the damage enemies take
                  by 10%.
                </div>
              )}
            </>
          )}
          {char != 'rina' && (
            <>
              {name === 'Weeping Cradle' && (
                <div className="information with-padding">
                  Rina's Signature W-Engine and one of the best damage
                  increasing weapons for Supports currently. Great quality of
                  life with its off-field Energy Regen. Able to be used on any
                  of our current Supports due to the amp and the ample energy
                  the passive provides - even if Pen Ratio is not the best
                  Support stat.
                </div>
              )}
            </>
          )}
          {name === 'The Vault' && (
            <div className="information with-padding">
              Perfectly synergizes with the normal rotation of Nicole in her
              best teams, making it her Best in Slot option.
            </div>
          )}
          {name === '[Reverb] Mark II' && (
            <div className="information with-padding">
              A solid Free to Play W-Engine that provide Energy Regen.
            </div>
          )}
          {name === 'Slice of Time' && (
            <div className="information with-padding">
              A generic support W-Engine 4 star that may be obtained for free.
              It is recommended to be replaced with Kaboom/WeepingCradle or the
              Sig W-Engine of the support when those options become available,
              but if you lack them it's a decent option.
            </div>
          )}
          {name === 'Unfettered Game Ball' && (
            <div className="information with-padding">
              Provides Crit Rate for the whole team, but it's locked behind the
              Premium Battle Pass and inferior to other options.
            </div>
          )}
        </>
      )}
    </>
  );
};
