export const ZZZBuilds = [
  {
    char: 'corin',
    app_rate_sd: 0.67,
    app_rate_sd_e1: 0.12,
    app_rate_sd_s0: 0.28,
    avg_round_sd: 170,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 39,
    sample_size_players_sd: 0,
    app_rate_da: 0.86,
    app_rate_da_e1: 0.18,
    app_rate_da_s0: 0.49,
    avg_round_da: 12838,
    avg_round_da_e1: 0,
    avg_round_da_s0: 11826,
    app_rate_da_boss_1: 0.21,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.4,
    avg_round_da_boss_2: 15466,
    app_rate_da_boss_3: 0.34,
    avg_round_da_boss_3: 0,
    sample_da: 47,
    sample_size_players_da: 0,
    app_0: 8.51,
    round_0_sd: 600,
    round_0_da: 11887,
    app_1: 1,
    round_1_sd: 600,
    round_1_da: 0,
    app_2: 4.26,
    round_2_sd: 600,
    round_2_da: 9672,
    app_3: 3.52,
    round_3_sd: 600,
    round_3_da: 8477,
    app_4: 4.64,
    round_4_sd: 204,
    round_4_da: 11721,
    app_5: 2.32,
    round_5_sd: 27,
    round_5_da: 9176,
    app_6: 82.35,
    round_6_sd: 173,
    round_6_da: 13527,
    cons_avg: 5.4,
    weapons_1: 'Housekeeper',
    weapons_1_app: 60.58,
    weapons_1_round_sd: 168,
    weapons_1_round_da: 11976,
    weapons_2: 'Steel Cushion',
    weapons_2_app: 24.17,
    weapons_2_round_sd: 169,
    weapons_2_round_da: 17372,
    weapons_3: 'Starlight Engine',
    weapons_3_app: 3.51,
    weapons_3_round_sd: 157,
    weapons_3_round_da: 14095,
    weapons_4: 'Gilded Blossom',
    weapons_4_app: 3.51,
    weapons_4_round_sd: 261,
    weapons_4_round_da: 18783,
    weapons_5: 'The Brimstone',
    weapons_5_app: 2.32,
    weapons_5_round_sd: 281,
    weapons_5_round_da: 11721,
    weapons_6: 'Street Superstar',
    weapons_6_app: 1.2,
    weapons_6_round_sd: 600,
    weapons_6_round_da: 7218,
    weapons_7: '[Lunar] Pleniluna',
    weapons_7_app: 1.2,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 7565,
    weapons_8: '[Lunar] Decrescent',
    weapons_8_app: 1.12,
    weapons_8_round_sd: 27,
    weapons_8_round_da: 0.0,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Fanged Metal, Woodpecker Electro',
    artifacts_1_1: 'Fanged Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 28.95,
    artifacts_1_round_sd: 205,
    artifacts_1_round_da: 11880,
    artifacts_2: 'Woodpecker Electro, Fanged Metal',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Fanged Metal',
    artifacts_2_3: '',
    artifacts_2_app: 10.55,
    artifacts_2_round_sd: 165,
    artifacts_2_round_da: 12090,
    artifacts_3: 'Puffer Electro, Woodpecker Electro',
    artifacts_3_1: 'Puffer Electro',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 7.03,
    artifacts_3_round_sd: 120,
    artifacts_3_round_da: 13381,
    artifacts_4: 'Hormone Punk, Fanged Metal',
    artifacts_4_1: 'Hormone Punk',
    artifacts_4_2: 'Fanged Metal',
    artifacts_4_3: '',
    artifacts_4_app: 7.03,
    artifacts_4_round_sd: 146,
    artifacts_4_round_da: 11338,
    artifacts_5: 'Hormone Punk, Woodpecker Electro',
    artifacts_5_1: 'Hormone Punk',
    artifacts_5_2: 'Woodpecker Electro',
    artifacts_5_3: '',
    artifacts_5_app: 5.76,
    artifacts_5_round_sd: 134,
    artifacts_5_round_da: 21742,
    artifacts_6: 'Woodpecker Electro, Puffer Electro',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Puffer Electro',
    artifacts_6_3: '',
    artifacts_6_app: 3.45,
    artifacts_6_round_sd: 214,
    artifacts_6_round_da: 0,
    artifacts_7: 'Puffer Electro, Branch & Blade Song',
    artifacts_7_1: 'Puffer Electro',
    artifacts_7_2: 'Branch & Blade Song',
    artifacts_7_3: '',
    artifacts_7_app: 2.39,
    artifacts_7_round_sd: 600,
    artifacts_7_round_da: 13578,
    artifacts_8: 'Woodpecker Electro, Flex',
    artifacts_8_1: 'Woodpecker Electro',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 2.39,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 16462,
    artifacts_9: 'Fanged Metal, Flex',
    artifacts_9_1: 'Fanged Metal',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 2.39,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 11512,
    artifacts_10: 'Branch & Blade Song, Fanged Metal, Flex',
    artifacts_10_1: 'Branch & Blade Song',
    artifacts_10_2: 'Fanged Metal',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 2.32,
    artifacts_10_round_sd: 157,
    artifacts_10_round_da: 20245
  },
  {
    char: 'soukaku',
    app_rate_sd: 44.51,
    app_rate_sd_e1: 17.97,
    app_rate_sd_s0: 4.02,
    avg_round_sd: 104,
    avg_round_sd_e1: 78,
    avg_round_sd_s0: 147,
    sample_sd: 2578,
    sample_size_players_sd: 0,
    app_rate_da: 47.59,
    app_rate_da_e1: 18.74,
    app_rate_da_s0: 5.23,
    avg_round_da: 18395,
    avg_round_da_e1: 26191,
    avg_round_da_s0: 13529,
    app_rate_da_boss_1: 39.31,
    avg_round_da_boss_1: 18879,
    app_rate_da_boss_2: 6.48,
    avg_round_da_boss_2: 16855,
    app_rate_da_boss_3: 4.32,
    avg_round_da_boss_3: 16158,
    sample_da: 2611,
    sample_size_players_da: 0,
    app_0: 0.1,
    round_0_sd: 246,
    round_0_da: 11162,
    app_1: 0.06,
    round_1_sd: 68,
    round_1_da: 20524,
    app_2: 0.14,
    round_2_sd: 96,
    round_2_da: 13586,
    app_3: 0.35,
    round_3_sd: 108,
    round_3_da: 14761,
    app_4: 1.01,
    round_4_sd: 109,
    round_4_da: 17002,
    app_5: 1.31,
    round_5_sd: 122,
    round_5_da: 17960,
    app_6: 97.02,
    round_6_sd: 104,
    round_6_da: 18490,
    cons_avg: 5.94,
    weapons_1: 'Bashful Demon',
    weapons_1_app: 86.58,
    weapons_1_round_sd: 105,
    weapons_1_round_da: 18211,
    weapons_2: 'Kaboom the Cannon',
    weapons_2_app: 2.58,
    weapons_2_round_sd: 98,
    weapons_2_round_da: 20849,
    weapons_3: 'Slice of Time',
    weapons_3_app: 1.8,
    weapons_3_round_sd: 108,
    weapons_3_round_da: 17521,
    weapons_4: 'Weeping Cradle',
    weapons_4_app: 0.93,
    weapons_4_round_sd: 116,
    weapons_4_round_da: 16781,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 0.75,
    weapons_5_round_sd: 134,
    weapons_5_round_da: 18362,
    weapons_6: 'Unfettered Game Ball',
    weapons_6_app: 0.7,
    weapons_6_round_sd: 84,
    weapons_6_round_da: 22551,
    weapons_7: 'The Vault',
    weapons_7_app: 0.68,
    weapons_7_round_sd: 96,
    weapons_7_round_da: 14240,
    weapons_8: '[Reverb] Mark III',
    weapons_8_app: 0.18,
    weapons_8_round_sd: 98,
    weapons_8_round_da: 17682,
    weapons_9: '[Reverb] Mark I',
    weapons_9_app: 0.17,
    weapons_9_round_sd: 121,
    weapons_9_round_da: 19380,
    weapons_10: 'Elegant Vanity',
    weapons_10_app: 0.08,
    weapons_10_round_sd: 83,
    weapons_10_round_da: 16178,
    artifacts_1: 'Swing Jazz, Hormone Punk',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Hormone Punk',
    artifacts_1_3: '',
    artifacts_1_app: 40.96,
    artifacts_1_round_sd: 107,
    artifacts_1_round_da: 18503,
    artifacts_2: 'Astral Voice, Swing Jazz',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 7.61,
    artifacts_2_round_sd: 91,
    artifacts_2_round_da: 19685,
    artifacts_3: 'Swing Jazz, Polar Metal',
    artifacts_3_1: 'Swing Jazz',
    artifacts_3_2: 'Polar Metal',
    artifacts_3_3: '',
    artifacts_3_app: 7.38,
    artifacts_3_round_sd: 102,
    artifacts_3_round_da: 18467,
    artifacts_4: 'Freedom Blues, Swing Jazz',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 4.24,
    artifacts_4_round_sd: 96,
    artifacts_4_round_da: 21942,
    artifacts_5: 'ATK +10%, Swing Jazz, Flex',
    artifacts_5_1: 'ATK +10%',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: 'Flex',
    artifacts_5_app: 2.83,
    artifacts_5_round_sd: 105,
    artifacts_5_round_da: 17302,
    artifacts_6: 'Swing Jazz, Woodpecker Electro',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Woodpecker Electro',
    artifacts_6_3: '',
    artifacts_6_app: 2.8,
    artifacts_6_round_sd: 94,
    artifacts_6_round_da: 19108,
    artifacts_7: 'Swing Jazz, Astral Voice',
    artifacts_7_1: 'Swing Jazz',
    artifacts_7_2: 'Astral Voice',
    artifacts_7_3: '',
    artifacts_7_app: 2.54,
    artifacts_7_round_sd: 96,
    artifacts_7_round_da: 17821,
    artifacts_8: 'Hormone Punk, Polar Metal',
    artifacts_8_1: 'Hormone Punk',
    artifacts_8_2: 'Polar Metal',
    artifacts_8_3: '',
    artifacts_8_app: 2.47,
    artifacts_8_round_sd: 111,
    artifacts_8_round_da: 16715,
    artifacts_9: 'Swing Jazz, Flex',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 2.36,
    artifacts_9_round_sd: 119,
    artifacts_9_round_da: 16528,
    artifacts_10: 'Swing Jazz, Freedom Blues',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Freedom Blues',
    artifacts_10_3: '',
    artifacts_10_app: 2.27,
    artifacts_10_round_sd: 111,
    artifacts_10_round_da: 18955
  },
  {
    char: 'billy-kid',
    app_rate_sd: 0.54,
    app_rate_sd_e1: 0.1,
    app_rate_sd_s0: 0.33,
    avg_round_sd: 168,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 31,
    sample_size_players_sd: 0,
    app_rate_da: 0.8,
    app_rate_da_e1: 0.09,
    app_rate_da_s0: 0.53,
    avg_round_da: 14471,
    avg_round_da_e1: 0,
    avg_round_da_s0: 13695,
    app_rate_da_boss_1: 0.24,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.26,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 0.38,
    avg_round_da_boss_3: 0,
    sample_da: 44,
    sample_size_players_da: 0,
    app_0: 4.08,
    round_0_sd: 380,
    round_0_da: 14490,
    app_1: 2.33,
    round_1_sd: 600,
    round_1_da: 8179,
    app_2: 4.08,
    round_2_sd: 141,
    round_2_da: 6220,
    app_3: 1,
    round_3_sd: 600,
    round_3_da: 0,
    app_4: 2.33,
    round_4_sd: 600,
    round_4_da: 7370,
    app_5: 2.69,
    round_5_sd: 144,
    round_5_da: 21042,
    app_6: 86.38,
    round_6_sd: 161,
    round_6_da: 15143,
    cons_avg: 5.47,
    weapons_1: 'Starlight Engine Replica',
    weapons_1_app: 47.36,
    weapons_1_round_sd: 162,
    weapons_1_round_da: 13581,
    weapons_2: 'The Brimstone',
    weapons_2_app: 17.52,
    weapons_2_round_sd: 150,
    weapons_2_round_da: 19058,
    weapons_3: 'Steel Cushion',
    weapons_3_app: 17.52,
    weapons_3_round_sd: 170,
    weapons_3_round_da: 13398,
    weapons_4: 'Zanshin Herb Case',
    weapons_4_app: 4.08,
    weapons_4_round_sd: 600,
    weapons_4_round_da: 12791,
    weapons_5: 'Marcato Desire',
    weapons_5_app: 4.08,
    weapons_5_round_sd: 68,
    weapons_5_round_da: 14392,
    weapons_6: 'Starlight Engine',
    weapons_6_app: 3.99,
    weapons_6_round_sd: 262,
    weapons_6_round_da: 6242,
    weapons_7: 'Street Superstar',
    weapons_7_app: 2.78,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 24200,
    weapons_8: 'Cannon Rotor',
    weapons_8_app: 2.69,
    weapons_8_round_sd: 214,
    weapons_8_round_da: 8179,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Woodpecker Electro, Fanged Metal',
    artifacts_1_1: 'Woodpecker Electro',
    artifacts_1_2: 'Fanged Metal',
    artifacts_1_3: '',
    artifacts_1_app: 29.84,
    artifacts_1_round_sd: 146,
    artifacts_1_round_da: 15715,
    artifacts_2: 'Puffer Electro, Woodpecker Electro',
    artifacts_2_1: 'Puffer Electro',
    artifacts_2_2: 'Woodpecker Electro',
    artifacts_2_3: '',
    artifacts_2_app: 12.23,
    artifacts_2_round_sd: 146,
    artifacts_2_round_da: 14611,
    artifacts_3: 'Woodpecker Electro, Puffer Electro',
    artifacts_3_1: 'Woodpecker Electro',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 10.75,
    artifacts_3_round_sd: 177,
    artifacts_3_round_da: 19686,
    artifacts_4: 'Woodpecker Electro, Hormone Punk',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Hormone Punk',
    artifacts_4_3: '',
    artifacts_4_app: 8.07,
    artifacts_4_round_sd: 159,
    artifacts_4_round_da: 10894,
    artifacts_5: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Branch & Blade Song',
    artifacts_5_3: '',
    artifacts_5_app: 7.98,
    artifacts_5_round_sd: 127,
    artifacts_5_round_da: 21296,
    artifacts_6: 'Fanged Metal, Woodpecker Electro',
    artifacts_6_1: 'Fanged Metal',
    artifacts_6_2: 'Woodpecker Electro',
    artifacts_6_3: '',
    artifacts_6_app: 5.55,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 8713,
    artifacts_7: 'Puffer Electro, Fanged Metal',
    artifacts_7_1: 'Puffer Electro',
    artifacts_7_2: 'Fanged Metal',
    artifacts_7_3: '',
    artifacts_7_app: 5.38,
    artifacts_7_round_sd: 272,
    artifacts_7_round_da: 9546,
    artifacts_8: 'Woodpecker Electro, Astral Voice',
    artifacts_8_1: 'Woodpecker Electro',
    artifacts_8_2: 'Astral Voice',
    artifacts_8_3: '',
    artifacts_8_app: 2.69,
    artifacts_8_round_sd: 85,
    artifacts_8_round_da: 28514,
    artifacts_9: 'Fanged Metal, Puffer Electro, Woodpecker Electro',
    artifacts_9_1: 'Fanged Metal',
    artifacts_9_2: 'Puffer Electro',
    artifacts_9_3: 'Woodpecker Electro',
    artifacts_9_app: 2.69,
    artifacts_9_round_sd: 380,
    artifacts_9_round_da: 6242,
    artifacts_10: 'Fanged Metal, Woodpecker Electro, Flex',
    artifacts_10_1: 'Fanged Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 1.39,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 11903
  },
  {
    char: 'grace-howard',
    app_rate_sd: 6.22,
    app_rate_sd_e1: 1.29,
    app_rate_sd_s0: 2.24,
    avg_round_sd: 164,
    avg_round_sd_e1: 113,
    avg_round_sd_s0: 193,
    sample_sd: 360,
    sample_size_players_sd: 0,
    app_rate_da: 8.04,
    app_rate_da_e1: 1.88,
    app_rate_da_s0: 2.73,
    avg_round_da: 16490,
    avg_round_da_e1: 26405,
    avg_round_da_s0: 13751,
    app_rate_da_boss_1: 3.2,
    avg_round_da_boss_1: 18841,
    app_rate_da_boss_2: 4.45,
    avg_round_da_boss_2: 15625,
    app_rate_da_boss_3: 1.09,
    avg_round_da_boss_3: 13674,
    sample_da: 441,
    sample_size_players_da: 0,
    app_0: 47.17,
    round_0_sd: 165,
    round_0_da: 16423,
    app_1: 31.72,
    round_1_sd: 167,
    round_1_da: 16102,
    app_2: 16.48,
    round_2_sd: 162,
    round_2_da: 17570,
    app_3: 4.0,
    round_3_sd: 118,
    round_3_da: 17207,
    app_4: 1.13,
    round_4_sd: 600,
    round_4_da: 13493,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.79,
    weapons_1: 'Fusion Compiler',
    weapons_1_app: 38.81,
    weapons_1_round_sd: 163,
    weapons_1_round_da: 16838,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 26.19,
    weapons_2_round_sd: 157,
    weapons_2_round_da: 16084,
    weapons_3: 'Rainforest Gourmet',
    weapons_3_app: 16.47,
    weapons_3_round_sd: 182,
    weapons_3_round_da: 15794,
    weapons_4: 'Electro-Lip Gloss',
    weapons_4_app: 6.98,
    weapons_4_round_sd: 151,
    weapons_4_round_da: 18291,
    weapons_5: 'Roaring Ride',
    weapons_5_app: 4.13,
    weapons_5_round_sd: 152,
    weapons_5_round_da: 15785,
    weapons_6: 'Timeweaver',
    weapons_6_app: 1.99,
    weapons_6_round_sd: 173,
    weapons_6_round_da: 18726,
    weapons_7: '[Magnetic Storm] Bravo',
    weapons_7_app: 0.49,
    weapons_7_round_sd: 227,
    weapons_7_round_da: 16859,
    weapons_8: '[Magnetic Storm] Alpha',
    weapons_8_app: 0.49,
    weapons_8_round_sd: 219,
    weapons_8_round_da: 14407,
    weapons_9: 'Sharpened Stinger',
    weapons_9_app: 0.38,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 13633,
    weapons_10: 'Flamemaker Shaker',
    weapons_10_app: 0.25,
    weapons_10_round_sd: 195,
    weapons_10_round_da: 13576,
    artifacts_1: 'Freedom Blues, Thunder Metal',
    artifacts_1_1: 'Freedom Blues',
    artifacts_1_2: 'Thunder Metal',
    artifacts_1_3: '',
    artifacts_1_app: 35.31,
    artifacts_1_round_sd: 163,
    artifacts_1_round_da: 16049,
    artifacts_2: 'Thunder Metal, Freedom Blues',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 18.81,
    artifacts_2_round_sd: 174,
    artifacts_2_round_da: 17174,
    artifacts_3: 'Freedom Blues, Swing Jazz',
    artifacts_3_1: 'Freedom Blues',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 8.36,
    artifacts_3_round_sd: 174,
    artifacts_3_round_da: 16881,
    artifacts_4: 'Chaos Jazz, Freedom Blues',
    artifacts_4_1: 'Chaos Jazz',
    artifacts_4_2: 'Freedom Blues',
    artifacts_4_3: '',
    artifacts_4_app: 6.5,
    artifacts_4_round_sd: 152,
    artifacts_4_round_da: 16907,
    artifacts_5: 'Freedom Blues, Chaos Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Chaos Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 4.38,
    artifacts_5_round_sd: 154,
    artifacts_5_round_da: 15454,
    artifacts_6: 'Chaos Jazz, Thunder Metal',
    artifacts_6_1: 'Chaos Jazz',
    artifacts_6_2: 'Thunder Metal',
    artifacts_6_3: '',
    artifacts_6_app: 3.23,
    artifacts_6_round_sd: 143,
    artifacts_6_round_da: 15950,
    artifacts_7: 'Thunder Metal, Chaos Jazz',
    artifacts_7_1: 'Thunder Metal',
    artifacts_7_2: 'Chaos Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 3.0,
    artifacts_7_round_sd: 174,
    artifacts_7_round_da: 18635,
    artifacts_8: 'Freedom Blues, Flex',
    artifacts_8_1: 'Freedom Blues',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 1.4,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 14390,
    artifacts_9: 'Thunder Metal, Flex',
    artifacts_9_1: 'Thunder Metal',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 1.25,
    artifacts_9_round_sd: 170,
    artifacts_9_round_da: 15531,
    artifacts_10: 'Freedom Blues, Puffer Electro',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.77,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 15526
  },
  {
    char: 'anby-demara',
    app_rate_sd: 10.45,
    app_rate_sd_e1: 1.81,
    app_rate_sd_s0: 5.4,
    avg_round_sd: 170,
    avg_round_sd_e1: 130,
    avg_round_sd_s0: 179,
    sample_sd: 605,
    sample_size_players_sd: 0,
    app_rate_da: 13.47,
    app_rate_da_e1: 2.28,
    app_rate_da_s0: 6.94,
    avg_round_da: 15134,
    avg_round_da_e1: 20711,
    avg_round_da_s0: 14451,
    app_rate_da_boss_1: 1.28,
    avg_round_da_boss_1: 10343,
    app_rate_da_boss_2: 13.24,
    avg_round_da_boss_2: 15692,
    app_rate_da_boss_3: 0.36,
    avg_round_da_boss_3: 0,
    sample_da: 739,
    sample_size_players_da: 0,
    app_0: 0.53,
    round_0_sd: 150,
    round_0_da: 15847,
    app_1: 1.19,
    round_1_sd: 165,
    round_1_da: 15349,
    app_2: 0.89,
    round_2_sd: 183,
    round_2_da: 13313,
    app_3: 2.39,
    round_3_sd: 168,
    round_3_da: 15060,
    app_4: 2.9,
    round_4_sd: 186,
    round_4_da: 13863,
    app_5: 3.5,
    round_5_sd: 173,
    round_5_da: 14163,
    app_6: 88.61,
    round_6_sd: 169,
    round_6_da: 15260,
    cons_avg: 5.71,
    weapons_1: 'Demara Battery Mark II',
    weapons_1_app: 64.57,
    weapons_1_round_sd: 172,
    weapons_1_round_da: 14787,
    weapons_2: 'Steam Oven',
    weapons_2_app: 12.56,
    weapons_2_round_sd: 156,
    weapons_2_round_da: 15962,
    weapons_3: 'Hellfire Gears',
    weapons_3_app: 10.37,
    weapons_3_round_sd: 169,
    weapons_3_round_da: 16521,
    weapons_4: 'The Restrained',
    weapons_4_app: 7.1,
    weapons_4_round_sd: 167,
    weapons_4_round_da: 15920,
    weapons_5: 'Precious Fossilized Core',
    weapons_5_app: 2.98,
    weapons_5_round_sd: 186,
    weapons_5_round_da: 12620,
    weapons_6: '[Vortex] Arrow',
    weapons_6_app: 0.37,
    weapons_6_round_sd: 114,
    weapons_6_round_da: 15668,
    weapons_7: 'Six Shooter',
    weapons_7_app: 0.22,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 14781,
    weapons_8: 'Original Transmorpher',
    weapons_8_app: 0.22,
    weapons_8_round_sd: 185,
    weapons_8_round_da: 13856,
    weapons_9: 'Ice-Jade Teapot',
    weapons_9_app: 0.15,
    weapons_9_round_sd: 93,
    weapons_9_round_da: 17629,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 48.39,
    artifacts_1_round_sd: 162,
    artifacts_1_round_da: 15927,
    artifacts_2: 'Shockstar Disco, Thunder Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Thunder Metal',
    artifacts_2_3: '',
    artifacts_2_app: 28.79,
    artifacts_2_round_sd: 180,
    artifacts_2_round_da: 14824,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 5.29,
    artifacts_3_round_sd: 158,
    artifacts_3_round_da: 15182,
    artifacts_4: 'Shockstar Disco, Flex',
    artifacts_4_1: 'Shockstar Disco',
    artifacts_4_2: 'Flex',
    artifacts_4_3: '',
    artifacts_4_app: 3.81,
    artifacts_4_round_sd: 213,
    artifacts_4_round_da: 13040,
    artifacts_5: 'Shockstar Disco, Hormone Punk',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Hormone Punk',
    artifacts_5_3: '',
    artifacts_5_app: 1.49,
    artifacts_5_round_sd: 192,
    artifacts_5_round_da: 13210,
    artifacts_6: 'Shockstar Disco, Freedom Blues',
    artifacts_6_1: 'Shockstar Disco',
    artifacts_6_2: 'Freedom Blues',
    artifacts_6_3: '',
    artifacts_6_app: 1.11,
    artifacts_6_round_sd: 168,
    artifacts_6_round_da: 14618,
    artifacts_7: 'Shockstar Disco, Thunder Metal, Flex',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Thunder Metal',
    artifacts_7_3: 'Flex',
    artifacts_7_app: 0.97,
    artifacts_7_round_sd: 266,
    artifacts_7_round_da: 9798,
    artifacts_8: 'Shockstar Disco, Swing Jazz, Flex',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Swing Jazz',
    artifacts_8_3: 'Flex',
    artifacts_8_app: 0.89,
    artifacts_8_round_sd: 139,
    artifacts_8_round_da: 12755,
    artifacts_9: 'Shockstar Disco, Puffer Electro',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Puffer Electro',
    artifacts_9_3: '',
    artifacts_9_app: 0.82,
    artifacts_9_round_sd: 167,
    artifacts_9_round_da: 12045,
    artifacts_10: 'Thunder Metal, Flex',
    artifacts_10_1: 'Thunder Metal',
    artifacts_10_2: 'Flex',
    artifacts_10_3: '',
    artifacts_10_app: 0.3,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 14603
  },
  {
    char: 'nicole-demara',
    app_rate_sd: 36.12,
    app_rate_sd_e1: 8.51,
    app_rate_sd_s0: 14.73,
    avg_round_sd: 127,
    avg_round_sd_e1: 97,
    avg_round_sd_s0: 137,
    sample_sd: 2092,
    sample_size_players_sd: 0,
    app_rate_da: 48.46,
    app_rate_da_e1: 11.39,
    app_rate_da_s0: 19.7,
    avg_round_da: 21055,
    avg_round_da_e1: 29008,
    avg_round_da_s0: 19524,
    app_rate_da_boss_1: 11.63,
    avg_round_da_boss_1: 21166,
    app_rate_da_boss_2: 38.02,
    avg_round_da_boss_2: 21418,
    app_rate_da_boss_3: 3.47,
    avg_round_da_boss_3: 16305,
    sample_da: 2659,
    sample_size_players_da: 0,
    app_0: 0.17,
    round_0_sd: 154,
    round_0_da: 17384,
    app_1: 0.23,
    round_1_sd: 114,
    round_1_da: 14736,
    app_2: 0.99,
    round_2_sd: 177,
    round_2_da: 14731,
    app_3: 1.85,
    round_3_sd: 130,
    round_3_da: 16511,
    app_4: 2.33,
    round_4_sd: 144,
    round_4_da: 18258,
    app_5: 4.07,
    round_5_sd: 140,
    round_5_da: 17888,
    app_6: 90.37,
    round_6_sd: 125,
    round_6_da: 21489,
    cons_avg: 5.79,
    weapons_1: 'The Vault',
    weapons_1_app: 87.53,
    weapons_1_round_sd: 126,
    weapons_1_round_da: 21198,
    weapons_2: 'Weeping Cradle',
    weapons_2_app: 4.57,
    weapons_2_round_sd: 125,
    weapons_2_round_da: 20869,
    weapons_3: 'Kaboom the Cannon',
    weapons_3_app: 1.83,
    weapons_3_round_sd: 120,
    weapons_3_round_da: 24498,
    weapons_4: 'Slice of Time',
    weapons_4_app: 1.79,
    weapons_4_round_sd: 150,
    weapons_4_round_da: 17644,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 0.57,
    weapons_5_round_sd: 122,
    weapons_5_round_da: 18718,
    weapons_6: 'Bashful Demon',
    weapons_6_app: 0.44,
    weapons_6_round_sd: 149,
    weapons_6_round_da: 20096,
    weapons_7: 'Unfettered Game Ball',
    weapons_7_app: 0.38,
    weapons_7_round_sd: 118,
    weapons_7_round_da: 22839,
    weapons_8: '[Reverb] Mark III',
    weapons_8_app: 0.23,
    weapons_8_round_sd: 157,
    weapons_8_round_da: 12839,
    weapons_9: 'The Restrained',
    weapons_9_app: 0.04,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0,
    weapons_10: 'Elegant Vanity',
    weapons_10_app: 0.04,
    weapons_10_round_sd: 153,
    weapons_10_round_da: 18294,
    artifacts_1: 'Swing Jazz, Chaotic Metal',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Chaotic Metal',
    artifacts_1_3: '',
    artifacts_1_app: 37.67,
    artifacts_1_round_sd: 129,
    artifacts_1_round_da: 20366,
    artifacts_2: 'Swing Jazz, Freedom Blues',
    artifacts_2_1: 'Swing Jazz',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 21.38,
    artifacts_2_round_sd: 124,
    artifacts_2_round_da: 21661,
    artifacts_3: 'Astral Voice, Swing Jazz',
    artifacts_3_1: 'Astral Voice',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 8.44,
    artifacts_3_round_sd: 127,
    artifacts_3_round_da: 21686,
    artifacts_4: 'Freedom Blues, Swing Jazz',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 4.73,
    artifacts_4_round_sd: 116,
    artifacts_4_round_da: 24707,
    artifacts_5: 'Swing Jazz, Flex',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Flex',
    artifacts_5_3: '',
    artifacts_5_app: 3.19,
    artifacts_5_round_sd: 133,
    artifacts_5_round_da: 20242,
    artifacts_6: 'Chaotic Metal, Swing Jazz',
    artifacts_6_1: 'Chaotic Metal',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: '',
    artifacts_6_app: 2.97,
    artifacts_6_round_sd: 134,
    artifacts_6_round_da: 17843,
    artifacts_7: 'Swing Jazz, Woodpecker Electro',
    artifacts_7_1: 'Swing Jazz',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 2.97,
    artifacts_7_round_sd: 118,
    artifacts_7_round_da: 21450,
    artifacts_8: 'Swing Jazz, Chaos Jazz',
    artifacts_8_1: 'Swing Jazz',
    artifacts_8_2: 'Chaos Jazz',
    artifacts_8_3: '',
    artifacts_8_app: 2.21,
    artifacts_8_round_sd: 121,
    artifacts_8_round_da: 22919,
    artifacts_9: 'Swing Jazz, Hormone Punk',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 1.77,
    artifacts_9_round_sd: 128,
    artifacts_9_round_da: 22783,
    artifacts_10: 'Swing Jazz, Astral Voice',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Astral Voice',
    artifacts_10_3: '',
    artifacts_10_app: 0.95,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 27855
  },
  {
    char: 'piper',
    app_rate_sd: 6.46,
    app_rate_sd_e1: 1.67,
    app_rate_sd_s0: 1.74,
    avg_round_sd: 141,
    avg_round_sd_e1: 96,
    avg_round_sd_s0: 176,
    sample_sd: 374,
    sample_size_players_sd: 0,
    app_rate_da: 22.4,
    app_rate_da_e1: 3.19,
    app_rate_da_s0: 9.95,
    avg_round_da: 19622,
    avg_round_da_e1: 24160,
    avg_round_da_s0: 18164,
    app_rate_da_boss_1: 2.88,
    avg_round_da_boss_1: 19205,
    app_rate_da_boss_2: 1.28,
    avg_round_da_boss_2: 18013,
    app_rate_da_boss_3: 21.57,
    avg_round_da_boss_3: 19768,
    sample_da: 1229,
    sample_size_players_da: 0,
    app_0: 0.18,
    round_0_sd: 140,
    round_0_da: 10422,
    app_1: 0.86,
    round_1_sd: 153,
    round_1_da: 14736,
    app_2: 1.32,
    round_2_sd: 101,
    round_2_da: 17488,
    app_3: 1.88,
    round_3_sd: 157,
    round_3_da: 16487,
    app_4: 2.89,
    round_4_sd: 126,
    round_4_da: 20034,
    app_5: 2.62,
    round_5_sd: 179,
    round_5_da: 19196,
    app_6: 90.25,
    round_6_sd: 140,
    round_6_da: 19784,
    cons_avg: 5.75,
    weapons_1: 'Roaring Ride',
    weapons_1_app: 88.05,
    weapons_1_round_sd: 140,
    weapons_1_round_da: 19734,
    weapons_2: 'Rainforest Gourmet',
    weapons_2_app: 3.44,
    weapons_2_round_sd: 120,
    weapons_2_round_da: 16684,
    weapons_3: 'Weeping Gemini',
    weapons_3_app: 2.94,
    weapons_3_round_sd: 150,
    weapons_3_round_da: 19323,
    weapons_4: 'Fusion Compiler',
    weapons_4_app: 2.87,
    weapons_4_round_sd: 180,
    weapons_4_round_da: 20077,
    weapons_5: 'Electro-Lip Gloss',
    weapons_5_app: 0.82,
    weapons_5_round_sd: 158,
    weapons_5_round_da: 22490,
    weapons_6: 'Sharpened Stinger',
    weapons_6_app: 0.62,
    weapons_6_round_sd: 107,
    weapons_6_round_da: 17539,
    weapons_7: '[Magnetic Storm] Bravo',
    weapons_7_app: 0.18,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 15482,
    weapons_8: 'Flamemaker Shaker',
    weapons_8_app: 0.12,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 22723,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Freedom Blues, Fanged Metal',
    artifacts_1_1: 'Freedom Blues',
    artifacts_1_2: 'Fanged Metal',
    artifacts_1_3: '',
    artifacts_1_app: 30.34,
    artifacts_1_round_sd: 154,
    artifacts_1_round_da: 18055,
    artifacts_2: 'Fanged Metal, Freedom Blues',
    artifacts_2_1: 'Fanged Metal',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 28.22,
    artifacts_2_round_sd: 148,
    artifacts_2_round_da: 20476,
    artifacts_3: 'Freedom Blues, Swing Jazz',
    artifacts_3_1: 'Freedom Blues',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 13.19,
    artifacts_3_round_sd: 131,
    artifacts_3_round_da: 19379,
    artifacts_4: 'Fanged Metal, Chaos Jazz',
    artifacts_4_1: 'Fanged Metal',
    artifacts_4_2: 'Chaos Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 6.16,
    artifacts_4_round_sd: 120,
    artifacts_4_round_da: 23439,
    artifacts_5: 'Freedom Blues, Chaos Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Chaos Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 4.51,
    artifacts_5_round_sd: 124,
    artifacts_5_round_da: 19905,
    artifacts_6: 'Freedom Blues, Flex',
    artifacts_6_1: 'Freedom Blues',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 1.75,
    artifacts_6_round_sd: 128,
    artifacts_6_round_da: 19478,
    artifacts_7: 'Freedom Blues, Hormone Punk',
    artifacts_7_1: 'Freedom Blues',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 1.57,
    artifacts_7_round_sd: 92,
    artifacts_7_round_da: 19052,
    artifacts_8: 'AP +30, AP +30, Fanged Metal',
    artifacts_8_1: 'AP +30',
    artifacts_8_2: 'AP +30',
    artifacts_8_3: 'Fanged Metal',
    artifacts_8_app: 1.24,
    artifacts_8_round_sd: 105,
    artifacts_8_round_da: 18820,
    artifacts_9: 'Fanged Metal, Swing Jazz',
    artifacts_9_1: 'Fanged Metal',
    artifacts_9_2: 'Swing Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 1.2,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 18761,
    artifacts_10: 'Fanged Metal, Flex',
    artifacts_10_1: 'Fanged Metal',
    artifacts_10_2: 'Flex',
    artifacts_10_3: '',
    artifacts_10_app: 0.82,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 19489
  },
  {
    char: 'nekomata',
    app_rate_sd: 0.74,
    app_rate_sd_e1: 0.19,
    app_rate_sd_s0: 0.31,
    avg_round_sd: 209,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 43,
    sample_size_players_sd: 0,
    app_rate_da: 1.8,
    app_rate_da_e1: 0.24,
    app_rate_da_s0: 0.98,
    avg_round_da: 13627,
    avg_round_da_e1: 0,
    avg_round_da_s0: 13222,
    app_rate_da_boss_1: 0.41,
    avg_round_da_boss_1: 10586,
    app_rate_da_boss_2: 0.61,
    avg_round_da_boss_2: 15658,
    app_rate_da_boss_3: 1.0,
    avg_round_da_boss_3: 13601,
    sample_da: 99,
    sample_size_players_da: 0,
    app_0: 38.0,
    round_0_sd: 253,
    round_0_da: 12315,
    app_1: 38.12,
    round_1_sd: 201,
    round_1_da: 14285,
    app_2: 15.48,
    round_2_sd: 170,
    round_2_da: 15276,
    app_3: 6.29,
    round_3_sd: 136,
    round_3_da: 11490,
    app_4: 1.01,
    round_4_sd: 600,
    round_4_da: 0,
    app_5: 2.33,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.01,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.99,
    weapons_1: 'Steel Cushion',
    weapons_1_app: 57.63,
    weapons_1_round_sd: 193,
    weapons_1_round_da: 14431,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 12.05,
    weapons_2_round_sd: 346,
    weapons_2_round_da: 13370,
    weapons_3: 'The Brimstone',
    weapons_3_app: 9.83,
    weapons_3_round_sd: 175,
    weapons_3_round_da: 13344,
    weapons_4: 'Gilded Blossom',
    weapons_4_app: 7.12,
    weapons_4_round_sd: 220,
    weapons_4_round_da: 11589,
    weapons_5: 'Marcato Desire',
    weapons_5_app: 5.64,
    weapons_5_round_sd: 263,
    weapons_5_round_da: 15391,
    weapons_6: 'Cannon Rotor',
    weapons_6_app: 5.61,
    weapons_6_round_sd: 246,
    weapons_6_round_da: 10982,
    weapons_7: 'Street Superstar',
    weapons_7_app: 0.68,
    weapons_7_round_sd: 328,
    weapons_7_round_da: 0.0,
    weapons_8: '',
    weapons_8_app: 0.0,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0.0,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Fanged Metal, Woodpecker Electro',
    artifacts_1_1: 'Fanged Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 42.14,
    artifacts_1_round_sd: 247,
    artifacts_1_round_da: 13374,
    artifacts_2: 'Woodpecker Electro, Fanged Metal',
    artifacts_2_1: 'Woodpecker Electro',
    artifacts_2_2: 'Fanged Metal',
    artifacts_2_3: '',
    artifacts_2_app: 20.52,
    artifacts_2_round_sd: 113,
    artifacts_2_round_da: 12371,
    artifacts_3: 'Fanged Metal, Hormone Punk',
    artifacts_3_1: 'Fanged Metal',
    artifacts_3_2: 'Hormone Punk',
    artifacts_3_3: '',
    artifacts_3_app: 9.15,
    artifacts_3_round_sd: 196,
    artifacts_3_round_da: 15955,
    artifacts_4: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 4.21,
    artifacts_4_round_sd: 245,
    artifacts_4_round_da: 16002,
    artifacts_5: 'Hormone Punk, Fanged Metal',
    artifacts_5_1: 'Hormone Punk',
    artifacts_5_2: 'Fanged Metal',
    artifacts_5_3: '',
    artifacts_5_app: 3.54,
    artifacts_5_round_sd: 600,
    artifacts_5_round_da: 20934,
    artifacts_6: 'Fanged Metal, Branch & Blade Song',
    artifacts_6_1: 'Fanged Metal',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 2.83,
    artifacts_6_round_sd: 247,
    artifacts_6_round_da: 15559,
    artifacts_7: 'Fanged Metal, Woodpecker Electro, Flex',
    artifacts_7_1: 'Fanged Metal',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: 'Flex',
    artifacts_7_app: 1.43,
    artifacts_7_round_sd: 600,
    artifacts_7_round_da: 12254,
    artifacts_8: 'Woodpecker Electro, Puffer Electro',
    artifacts_8_1: 'Woodpecker Electro',
    artifacts_8_2: 'Puffer Electro',
    artifacts_8_3: '',
    artifacts_8_app: 1.4,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 0,
    artifacts_9: 'Branch & Blade Song, Fanged Metal, Woodpecker Electro',
    artifacts_9_1: 'Branch & Blade Song',
    artifacts_9_2: 'Fanged Metal',
    artifacts_9_3: 'Woodpecker Electro',
    artifacts_9_app: 0.72,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 21391,
    artifacts_10: 'Chaotic Metal, Swing Jazz, Woodpecker Electro',
    artifacts_10_1: 'Chaotic Metal',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: 'Woodpecker Electro',
    artifacts_10_app: 0.72,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 6279
  },
  {
    char: 'zhu-yuan',
    app_rate_sd: 23.43,
    app_rate_sd_e1: 5.49,
    app_rate_sd_s0: 9.79,
    avg_round_sd: 124,
    avg_round_sd_e1: 107,
    avg_round_sd_s0: 129,
    sample_sd: 1357,
    sample_size_players_sd: 0,
    app_rate_da: 37.78,
    app_rate_da_e1: 9.08,
    app_rate_da_s0: 16.09,
    avg_round_da: 21292,
    avg_round_da_e1: 27186,
    avg_round_da_s0: 20552,
    app_rate_da_boss_1: 4.57,
    avg_round_da_boss_1: 16091,
    app_rate_da_boss_2: 36.32,
    avg_round_da_boss_2: 22162,
    app_rate_da_boss_3: 0.77,
    avg_round_da_boss_3: 13138,
    sample_da: 2073,
    sample_size_players_da: 0,
    app_0: 81.54,
    round_0_sd: 124,
    round_0_da: 21292,
    app_1: 14.33,
    round_1_sd: 112,
    round_1_da: 25295,
    app_2: 2.13,
    round_2_sd: 97,
    round_2_da: 28501,
    app_3: 0.3,
    round_3_sd: 100,
    round_3_da: 26393,
    app_4: 0.29,
    round_4_sd: 75,
    round_4_da: 41424,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.41,
    round_6_sd: 77,
    round_6_da: 41598,
    cons_avg: 0.29,
    weapons_1: 'Riot Suppressor Mark VI',
    weapons_1_app: 42.89,
    weapons_1_round_sd: 118,
    weapons_1_round_da: 22327,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 27.54,
    weapons_2_round_sd: 125,
    weapons_2_round_da: 20800,
    weapons_3: 'The Brimstone',
    weapons_3_app: 14.84,
    weapons_3_round_sd: 127,
    weapons_3_round_da: 22033,
    weapons_4: 'Marcato Desire',
    weapons_4_app: 4.88,
    weapons_4_round_sd: 130,
    weapons_4_round_da: 19519,
    weapons_5: 'Cannon Rotor',
    weapons_5_app: 4.16,
    weapons_5_round_sd: 138,
    weapons_5_round_da: 18778,
    weapons_6: 'Gilded Blossom',
    weapons_6_app: 3.17,
    weapons_6_round_sd: 142,
    weapons_6_round_da: 19182,
    weapons_7: 'Steel Cushion',
    weapons_7_app: 1.29,
    weapons_7_round_sd: 117,
    weapons_7_round_da: 19838,
    weapons_8: 'Street Superstar',
    weapons_8_app: 0.56,
    weapons_8_round_sd: 118,
    weapons_8_round_da: 19704,
    weapons_9: '[Lunar] Noviluna',
    weapons_9_app: 0.09,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 22357,
    weapons_10: 'Starlight Engine Replica',
    weapons_10_app: 0.06,
    weapons_10_round_sd: 128,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Chaotic Metal, Woodpecker Electro',
    artifacts_1_1: 'Chaotic Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 77.16,
    artifacts_1_round_sd: 126,
    artifacts_1_round_da: 21136,
    artifacts_2: 'Chaotic Metal, Branch & Blade Song',
    artifacts_2_1: 'Chaotic Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 4.92,
    artifacts_2_round_sd: 98,
    artifacts_2_round_da: 24130,
    artifacts_3: 'Chaotic Metal, Hormone Punk',
    artifacts_3_1: 'Chaotic Metal',
    artifacts_3_2: 'Hormone Punk',
    artifacts_3_3: '',
    artifacts_3_app: 4.55,
    artifacts_3_round_sd: 127,
    artifacts_3_round_da: 21456,
    artifacts_4: 'Chaotic Metal, Swing Jazz',
    artifacts_4_1: 'Chaotic Metal',
    artifacts_4_2: 'Swing Jazz',
    artifacts_4_3: '',
    artifacts_4_app: 2.74,
    artifacts_4_round_sd: 118,
    artifacts_4_round_da: 19362,
    artifacts_5: 'Woodpecker Electro, Chaotic Metal',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Chaotic Metal',
    artifacts_5_3: '',
    artifacts_5_app: 2.45,
    artifacts_5_round_sd: 120,
    artifacts_5_round_da: 22393,
    artifacts_6: 'Chaotic Metal, Flex',
    artifacts_6_1: 'Chaotic Metal',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 1.87,
    artifacts_6_round_sd: 136,
    artifacts_6_round_da: 16746,
    artifacts_7: 'Chaotic Metal, Puffer Electro',
    artifacts_7_1: 'Chaotic Metal',
    artifacts_7_2: 'Puffer Electro',
    artifacts_7_3: '',
    artifacts_7_app: 1.09,
    artifacts_7_round_sd: 144,
    artifacts_7_round_da: 19583,
    artifacts_8: 'Chaotic Metal, Astral Voice',
    artifacts_8_1: 'Chaotic Metal',
    artifacts_8_2: 'Astral Voice',
    artifacts_8_3: '',
    artifacts_8_app: 0.94,
    artifacts_8_round_sd: 101,
    artifacts_8_round_da: 27536,
    artifacts_9: 'Hormone Punk, Woodpecker Electro',
    artifacts_9_1: 'Hormone Punk',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: '',
    artifacts_9_app: 0.61,
    artifacts_9_round_sd: 98,
    artifacts_9_round_da: 28179,
    artifacts_10: 'Hormone Punk, Branch & Blade Song',
    artifacts_10_1: 'Hormone Punk',
    artifacts_10_2: 'Branch & Blade Song',
    artifacts_10_3: '',
    artifacts_10_app: 0.27,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 24565
  },
  {
    char: 'lycaon',
    app_rate_sd: 29.02,
    app_rate_sd_e1: 11.1,
    app_rate_sd_s0: 3.0,
    avg_round_sd: 110,
    avg_round_sd_e1: 84,
    avg_round_sd_s0: 154,
    sample_sd: 1681,
    sample_size_players_sd: 0,
    app_rate_da: 32.51,
    app_rate_da_e1: 12.61,
    app_rate_da_s0: 3.77,
    avg_round_da: 17780,
    avg_round_da_e1: 25570,
    avg_round_da_s0: 12922,
    app_rate_da_boss_1: 26.17,
    avg_round_da_boss_1: 18145,
    app_rate_da_boss_2: 5.32,
    avg_round_da_boss_2: 17093,
    app_rate_da_boss_3: 2.79,
    avg_round_da_boss_3: 15414,
    sample_da: 1784,
    sample_size_players_da: 0,
    app_0: 50.44,
    round_0_sd: 107,
    round_0_da: 17849,
    app_1: 34.19,
    round_1_sd: 115,
    round_1_da: 17764,
    app_2: 10.78,
    round_2_sd: 110,
    round_2_da: 17517,
    app_3: 3.15,
    round_3_sd: 106,
    round_3_da: 17031,
    app_4: 0.89,
    round_4_sd: 96,
    round_4_da: 20261,
    app_5: 0.41,
    round_5_sd: 184,
    round_5_da: 0,
    app_6: 0.15,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.72,
    weapons_1: 'The Restrained',
    weapons_1_app: 36.92,
    weapons_1_round_sd: 116,
    weapons_1_round_da: 16655,
    weapons_2: 'Steam Oven',
    weapons_2_app: 25.24,
    weapons_2_round_sd: 100,
    weapons_2_round_da: 18698,
    weapons_3: 'Precious Fossilized Core',
    weapons_3_app: 15.56,
    weapons_3_round_sd: 116,
    weapons_3_round_da: 17752,
    weapons_4: 'Hellfire Gears',
    weapons_4_app: 14.62,
    weapons_4_round_sd: 107,
    weapons_4_round_da: 18897,
    weapons_5: 'Six Shooter',
    weapons_5_app: 3.92,
    weapons_5_round_sd: 123,
    weapons_5_round_da: 16176,
    weapons_6: 'Demara Battery Mark II',
    weapons_6_app: 1.03,
    weapons_6_round_sd: 108,
    weapons_6_round_da: 14957,
    weapons_7: '[Vortex] Arrow',
    weapons_7_app: 0.56,
    weapons_7_round_sd: 87,
    weapons_7_round_da: 16446,
    weapons_8: 'Blazing Laurel',
    weapons_8_app: 0.52,
    weapons_8_round_sd: 53,
    weapons_8_round_da: 25902,
    weapons_9: 'Ice-Jade Teapot',
    weapons_9_app: 0.35,
    weapons_9_round_sd: 97,
    weapons_9_round_da: 17548,
    weapons_10: 'Original Transmorpher',
    weapons_10_app: 0.12,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 25290,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 38.71,
    artifacts_1_round_sd: 106,
    artifacts_1_round_da: 18155,
    artifacts_2: 'Shockstar Disco, Polar Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Polar Metal',
    artifacts_2_3: '',
    artifacts_2_app: 34.8,
    artifacts_2_round_sd: 115,
    artifacts_2_round_da: 16036,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 6.01,
    artifacts_3_round_sd: 125,
    artifacts_3_round_da: 18204,
    artifacts_4: 'Freedom Blues, Shockstar Disco',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 2.76,
    artifacts_4_round_sd: 81,
    artifacts_4_round_da: 24078,
    artifacts_5: 'Shockstar Disco, Flex',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Flex',
    artifacts_5_3: '',
    artifacts_5_app: 2.66,
    artifacts_5_round_sd: 110,
    artifacts_5_round_da: 17124,
    artifacts_6: 'Swing Jazz, Shockstar Disco',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 1.87,
    artifacts_6_round_sd: 92,
    artifacts_6_round_da: 20062,
    artifacts_7: 'Shockstar Disco, Freedom Blues',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Freedom Blues',
    artifacts_7_3: '',
    artifacts_7_app: 1.56,
    artifacts_7_round_sd: 108,
    artifacts_7_round_da: 15599,
    artifacts_8: 'Proto Punk, Shockstar Disco',
    artifacts_8_1: 'Proto Punk',
    artifacts_8_2: 'Shockstar Disco',
    artifacts_8_3: '',
    artifacts_8_app: 1.22,
    artifacts_8_round_sd: 112,
    artifacts_8_round_da: 22984,
    artifacts_9: 'Polar Metal, Shockstar Disco',
    artifacts_9_1: 'Polar Metal',
    artifacts_9_2: 'Shockstar Disco',
    artifacts_9_3: '',
    artifacts_9_app: 0.87,
    artifacts_9_round_sd: 113,
    artifacts_9_round_da: 10746,
    artifacts_10: 'Shockstar Disco, Hormone Punk',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Hormone Punk',
    artifacts_10_3: '',
    artifacts_10_app: 0.48,
    artifacts_10_round_sd: 118,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'anton',
    app_rate_sd: 0.64,
    app_rate_sd_e1: 0.05,
    app_rate_sd_s0: 0.47,
    avg_round_sd: 166,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 174,
    sample_sd: 37,
    sample_size_players_sd: 0,
    app_rate_da: 0.86,
    app_rate_da_e1: 0.05,
    app_rate_da_s0: 0.58,
    avg_round_da: 17455,
    avg_round_da_e1: 0,
    avg_round_da_s0: 17370,
    app_rate_da_boss_1: 0.04,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.89,
    avg_round_da_boss_2: 17637,
    app_rate_da_boss_3: 0.02,
    avg_round_da_boss_3: 0,
    sample_da: 47,
    sample_size_players_da: 0,
    app_0: 1,
    round_0_sd: 600,
    round_0_da: 0,
    app_1: 1,
    round_1_sd: 600,
    round_1_da: 0,
    app_2: 2.37,
    round_2_sd: 247,
    round_2_da: 11604,
    app_3: 6.03,
    round_3_sd: 125,
    round_3_da: 22195,
    app_4: 2.13,
    round_4_sd: 600,
    round_4_da: 20679,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 90.37,
    round_6_sd: 164,
    round_6_da: 17158,
    cons_avg: 5.7,
    weapons_1: 'Drill Rig - Red Axis',
    weapons_1_app: 64.41,
    weapons_1_round_sd: 186,
    weapons_1_round_da: 15031,
    weapons_2: 'The Brimstone',
    weapons_2_app: 14.3,
    weapons_2_round_sd: 100,
    weapons_2_round_da: 23784,
    weapons_3: 'Zanshin Herb Case',
    weapons_3_app: 7.12,
    weapons_3_round_sd: 147,
    weapons_3_round_da: 18860,
    weapons_4: 'Starlight Engine',
    weapons_4_app: 4.75,
    weapons_4_round_sd: 200,
    weapons_4_round_da: 26448,
    weapons_5: 'Marcato Desire',
    weapons_5_app: 4.68,
    weapons_5_round_sd: 129,
    weapons_5_round_da: 20462,
    weapons_6: 'Street Superstar',
    weapons_6_app: 3.53,
    weapons_6_round_sd: 138,
    weapons_6_round_da: 20679,
    weapons_7: 'Steel Cushion',
    weapons_7_app: 1.22,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 0,
    weapons_8: '',
    weapons_8_app: 0.0,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 0.0,
    weapons_9: '',
    weapons_9_app: 0.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0.0,
    weapons_10: '',
    weapons_10_app: 0.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Thunder Metal, Woodpecker Electro',
    artifacts_1_1: 'Thunder Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 57.02,
    artifacts_1_round_sd: 168,
    artifacts_1_round_da: 17543,
    artifacts_2: 'Thunder Metal, Hormone Punk',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Hormone Punk',
    artifacts_2_3: '',
    artifacts_2_app: 7.19,
    artifacts_2_round_sd: 186,
    artifacts_2_round_da: 14894,
    artifacts_3: 'Thunder Metal, Flex',
    artifacts_3_1: 'Thunder Metal',
    artifacts_3_2: 'Flex',
    artifacts_3_3: '',
    artifacts_3_app: 7.12,
    artifacts_3_round_sd: 185,
    artifacts_3_round_da: 19054,
    artifacts_4: 'Thunder Metal, Branch & Blade Song',
    artifacts_4_1: 'Thunder Metal',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 6.03,
    artifacts_4_round_sd: 188,
    artifacts_4_round_da: 16176,
    artifacts_5: 'Thunder Metal, Woodpecker Electro, Flex',
    artifacts_5_1: 'Thunder Metal',
    artifacts_5_2: 'Woodpecker Electro',
    artifacts_5_3: 'Flex',
    artifacts_5_app: 4.81,
    artifacts_5_round_sd: 600,
    artifacts_5_round_da: 14944,
    artifacts_6: 'Branch & Blade Song, Woodpecker Electro, Flex',
    artifacts_6_1: 'Branch & Blade Song',
    artifacts_6_2: 'Woodpecker Electro',
    artifacts_6_3: 'Flex',
    artifacts_6_app: 2.44,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 21887,
    artifacts_7: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_7_1: 'Woodpecker Electro',
    artifacts_7_2: 'Branch & Blade Song',
    artifacts_7_3: '',
    artifacts_7_app: 2.37,
    artifacts_7_round_sd: 117,
    artifacts_7_round_da: 20462,
    artifacts_8: 'Thunder Metal, Puffer Electro',
    artifacts_8_1: 'Thunder Metal',
    artifacts_8_2: 'Puffer Electro',
    artifacts_8_3: '',
    artifacts_8_app: 2.37,
    artifacts_8_round_sd: 145,
    artifacts_8_round_da: 17371,
    artifacts_9: 'Puffer Electro, Hormone Punk',
    artifacts_9_1: 'Puffer Electro',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 2.31,
    artifacts_9_round_sd: 117,
    artifacts_9_round_da: 0.0,
    artifacts_10: 'Puffer Electro, Woodpecker Electro',
    artifacts_10_1: 'Puffer Electro',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 1.22,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 21578
  },
  {
    char: 'soldier-11',
    app_rate_sd: 1.1,
    app_rate_sd_e1: 0.29,
    app_rate_sd_s0: 0.47,
    avg_round_sd: 175,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 187,
    sample_sd: 64,
    sample_size_players_sd: 0,
    app_rate_da: 12.65,
    app_rate_da_e1: 1.59,
    app_rate_da_s0: 7.24,
    avg_round_da: 15168,
    avg_round_da_e1: 22656,
    avg_round_da_s0: 13642,
    app_rate_da_boss_1: 0.23,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.26,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 14.25,
    avg_round_da_boss_3: 15191,
    sample_da: 694,
    sample_size_players_da: 0,
    app_0: 46.59,
    round_0_sd: 181,
    round_0_da: 14435,
    app_1: 33.02,
    round_1_sd: 187,
    round_1_da: 14938,
    app_2: 12.98,
    round_2_sd: 165,
    round_2_da: 16929,
    app_3: 6.07,
    round_3_sd: 135,
    round_3_da: 19268,
    app_4: 1.16,
    round_4_sd: 600,
    round_4_da: 17111,
    app_5: 0.29,
    round_5_sd: 600,
    round_5_da: 18856,
    app_6: 1,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.82,
    weapons_1: 'The Brimstone',
    weapons_1_app: 48.12,
    weapons_1_round_sd: 173,
    weapons_1_round_da: 16208,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 20.65,
    weapons_2_round_sd: 176,
    weapons_2_round_da: 14197,
    weapons_3: 'Marcato Desire',
    weapons_3_app: 10.09,
    weapons_3_round_sd: 230,
    weapons_3_round_da: 15036,
    weapons_4: 'Gilded Blossom',
    weapons_4_app: 7.05,
    weapons_4_round_sd: 202,
    weapons_4_round_da: 12763,
    weapons_5: 'Cannon Rotor',
    weapons_5_app: 6.78,
    weapons_5_round_sd: 184,
    weapons_5_round_da: 14119,
    weapons_6: 'Steel Cushion',
    weapons_6_app: 2.13,
    weapons_6_round_sd: 600,
    weapons_6_round_da: 14245,
    weapons_7: 'Street Superstar',
    weapons_7_app: 1.6,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 13811,
    weapons_8: 'Starlight Engine Replica',
    weapons_8_app: 0.27,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 15521,
    weapons_9: '[Lunar] Pleniluna',
    weapons_9_app: 0.27,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 12081,
    weapons_10: 'Bashful Demon',
    weapons_10_app: 0.13,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0,
    artifacts_1: 'Inferno Metal, Woodpecker Electro',
    artifacts_1_1: 'Inferno Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 69.94,
    artifacts_1_round_sd: 179,
    artifacts_1_round_da: 14883,
    artifacts_2: 'Inferno Metal, Branch & Blade Song',
    artifacts_2_1: 'Inferno Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 7.3,
    artifacts_2_round_sd: 140,
    artifacts_2_round_da: 16360,
    artifacts_3: 'Inferno Metal, Puffer Electro',
    artifacts_3_1: 'Inferno Metal',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 6.09,
    artifacts_3_round_sd: 94,
    artifacts_3_round_da: 17591,
    artifacts_4: 'Inferno Metal, Flex',
    artifacts_4_1: 'Inferno Metal',
    artifacts_4_2: 'Flex',
    artifacts_4_3: '',
    artifacts_4_app: 3.32,
    artifacts_4_round_sd: 46,
    artifacts_4_round_da: 15136,
    artifacts_5: 'Woodpecker Electro, Inferno Metal',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Inferno Metal',
    artifacts_5_3: '',
    artifacts_5_app: 3.29,
    artifacts_5_round_sd: 216,
    artifacts_5_round_da: 14766,
    artifacts_6: 'Inferno Metal, Hormone Punk',
    artifacts_6_1: 'Inferno Metal',
    artifacts_6_2: 'Hormone Punk',
    artifacts_6_3: '',
    artifacts_6_app: 2.1,
    artifacts_6_round_sd: 213,
    artifacts_6_round_da: 14739,
    artifacts_7: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_7_1: 'Woodpecker Electro',
    artifacts_7_2: 'Branch & Blade Song',
    artifacts_7_3: '',
    artifacts_7_app: 1.45,
    artifacts_7_round_sd: 188,
    artifacts_7_round_da: 21425,
    artifacts_8: 'Inferno Metal, Swing Jazz',
    artifacts_8_1: 'Inferno Metal',
    artifacts_8_2: 'Swing Jazz',
    artifacts_8_3: '',
    artifacts_8_app: 1.18,
    artifacts_8_round_sd: 190,
    artifacts_8_round_da: 11486,
    artifacts_9: 'Inferno Metal, Astral Voice',
    artifacts_9_1: 'Inferno Metal',
    artifacts_9_2: 'Astral Voice',
    artifacts_9_3: '',
    artifacts_9_app: 0.53,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 10619,
    artifacts_10: 'Puffer Electro, Inferno Metal',
    artifacts_10_1: 'Puffer Electro',
    artifacts_10_2: 'Inferno Metal',
    artifacts_10_3: '',
    artifacts_10_app: 0.4,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 17196
  },
  {
    char: 'ben',
    app_rate_sd: 0.28,
    app_rate_sd_e1: 0.02,
    app_rate_sd_s0: 0.1,
    avg_round_sd: 600,
    avg_round_sd_e1: 600,
    avg_round_sd_s0: 600,
    sample_sd: 16,
    sample_size_players_sd: 0,
    app_rate_da: 1.57,
    app_rate_da_e1: 0.11,
    app_rate_da_s0: 0.98,
    avg_round_da: 13131,
    avg_round_da_e1: 0,
    avg_round_da_s0: 11020,
    app_rate_da_boss_1: 0.11,
    avg_round_da_boss_1: 0,
    app_rate_da_boss_2: 0.1,
    avg_round_da_boss_2: 0,
    app_rate_da_boss_3: 1.6,
    avg_round_da_boss_3: 13371,
    sample_da: 86,
    sample_size_players_da: 0,
    app_0: 5.89,
    round_0_sd: 313,
    round_0_da: 10908,
    app_1: 3.94,
    round_1_sd: 196,
    round_1_da: 15069,
    app_2: 2.35,
    round_2_sd: 600,
    round_2_da: 8786,
    app_3: 2.35,
    round_3_sd: 600,
    round_3_da: 9818,
    app_4: 8.24,
    round_4_sd: 600,
    round_4_da: 8996,
    app_5: 5.88,
    round_5_sd: 600,
    round_5_da: 13123,
    app_6: 74.2,
    round_6_sd: 146,
    round_6_da: 14005,
    cons_avg: 5.12,
    weapons_1: 'Big Cylinder',
    weapons_1_app: 63.48,
    weapons_1_round_sd: 152,
    weapons_1_round_da: 13354,
    weapons_2: 'Bunny Band',
    weapons_2_app: 11.83,
    weapons_2_round_sd: 160,
    weapons_2_round_da: 13143,
    weapons_3: 'Original Transmorpher',
    weapons_3_app: 4.95,
    weapons_3_round_sd: 380,
    weapons_3_round_da: 9244,
    weapons_4: 'Tusks of Fury',
    weapons_4_app: 3.95,
    weapons_4_round_sd: 136,
    weapons_4_round_da: 15919,
    weapons_5: 'Steel Cushion',
    weapons_5_app: 3.95,
    weapons_5_round_sd: 157,
    weapons_5_round_da: 10805,
    weapons_6: 'Spring Embrace',
    weapons_6_app: 3.0,
    weapons_6_round_sd: 600,
    weapons_6_round_da: 12481,
    weapons_7: 'Demara Battery Mark II',
    weapons_7_app: 1.99,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 9438,
    weapons_8: 'Zanshin Herb Case',
    weapons_8_app: 1.0,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 26192,
    weapons_9: 'Hailstorm Shrine',
    weapons_9_app: 1.0,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 20524,
    weapons_10: 'Peacekeeper - Specialized',
    weapons_10_app: 1.0,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 26939,
    artifacts_1: 'Swing Jazz, Soul Rock',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Soul Rock',
    artifacts_1_3: '',
    artifacts_1_app: 11.98,
    artifacts_1_round_sd: 600,
    artifacts_1_round_da: 11388,
    artifacts_2: 'Proto Punk, Swing Jazz',
    artifacts_2_1: 'Proto Punk',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 8.88,
    artifacts_2_round_sd: 162,
    artifacts_2_round_da: 15824,
    artifacts_3: 'Shockstar Disco, Swing Jazz',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 5.89,
    artifacts_3_round_sd: 301,
    artifacts_3_round_da: 12630,
    artifacts_4: 'Proto Punk, Soul Rock',
    artifacts_4_1: 'Proto Punk',
    artifacts_4_2: 'Soul Rock',
    artifacts_4_3: '',
    artifacts_4_app: 4.99,
    artifacts_4_round_sd: 600,
    artifacts_4_round_da: 13541,
    artifacts_5: 'Inferno Metal, Soul Rock',
    artifacts_5_1: 'Inferno Metal',
    artifacts_5_2: 'Soul Rock',
    artifacts_5_3: '',
    artifacts_5_app: 4.95,
    artifacts_5_round_sd: 157,
    artifacts_5_round_da: 16827,
    artifacts_6: 'Soul Rock, Swing Jazz',
    artifacts_6_1: 'Soul Rock',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: '',
    artifacts_6_app: 4.0,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 8314,
    artifacts_7: 'Proto Punk, Shockstar Disco',
    artifacts_7_1: 'Proto Punk',
    artifacts_7_2: 'Shockstar Disco',
    artifacts_7_3: '',
    artifacts_7_app: 3.0,
    artifacts_7_round_sd: 600,
    artifacts_7_round_da: 13598,
    artifacts_8: 'Inferno Metal, Branch & Blade Song',
    artifacts_8_1: 'Inferno Metal',
    artifacts_8_2: 'Branch & Blade Song',
    artifacts_8_3: '',
    artifacts_8_app: 3.0,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 19655,
    artifacts_9: 'Woodpecker Electro, Inferno Metal',
    artifacts_9_1: 'Woodpecker Electro',
    artifacts_9_2: 'Inferno Metal',
    artifacts_9_3: '',
    artifacts_9_app: 3.0,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 15474,
    artifacts_10: 'Swing Jazz, Shockstar Disco',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Shockstar Disco',
    artifacts_10_3: '',
    artifacts_10_app: 1.99,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 9768
  },
  {
    char: 'rina',
    app_rate_sd: 38.57,
    app_rate_sd_e1: 9.89,
    app_rate_sd_s0: 11.4,
    avg_round_sd: 126,
    avg_round_sd_e1: 87,
    avg_round_sd_s0: 149,
    sample_sd: 2234,
    sample_size_players_sd: 0,
    app_rate_da: 35.3,
    app_rate_da_e1: 9.55,
    app_rate_da_s0: 10.72,
    avg_round_da: 19709,
    avg_round_da_e1: 27289,
    avg_round_da_s0: 17558,
    app_rate_da_boss_1: 11.24,
    avg_round_da_boss_1: 20923,
    app_rate_da_boss_2: 24.45,
    avg_round_da_boss_2: 19640,
    app_rate_da_boss_3: 2.81,
    avg_round_da_boss_3: 14568,
    sample_da: 1937,
    sample_size_players_da: 0,
    app_0: 41.65,
    round_0_sd: 131,
    round_0_da: 18175,
    app_1: 38.54,
    round_1_sd: 119,
    round_1_da: 20833,
    app_2: 13.58,
    round_2_sd: 125,
    round_2_da: 20634,
    app_3: 4.45,
    round_3_sd: 132,
    round_3_da: 21049,
    app_4: 1.15,
    round_4_sd: 83,
    round_4_da: 22487,
    app_5: 0.34,
    round_5_sd: 120,
    round_5_da: 29960,
    app_6: 0.29,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.87,
    weapons_1: 'Slice of Time',
    weapons_1_app: 46.59,
    weapons_1_round_sd: 131,
    weapons_1_round_da: 18925,
    weapons_2: 'Weeping Cradle',
    weapons_2_app: 46.06,
    weapons_2_round_sd: 120,
    weapons_2_round_da: 20810,
    weapons_3: 'Kaboom the Cannon',
    weapons_3_app: 3.11,
    weapons_3_round_sd: 127,
    weapons_3_round_da: 19084,
    weapons_4: 'Unfettered Game Ball',
    weapons_4_app: 0.96,
    weapons_4_round_sd: 134,
    weapons_4_round_da: 16001,
    weapons_5: '[Reverb] Mark II',
    weapons_5_app: 0.79,
    weapons_5_round_sd: 127,
    weapons_5_round_da: 17833,
    weapons_6: 'Bashful Demon',
    weapons_6_app: 0.33,
    weapons_6_round_sd: 141,
    weapons_6_round_da: 20146,
    weapons_7: 'The Vault',
    weapons_7_app: 0.31,
    weapons_7_round_sd: 149,
    weapons_7_round_da: 19366,
    weapons_8: 'Fusion Compiler',
    weapons_8_app: 0.17,
    weapons_8_round_sd: 114,
    weapons_8_round_da: 20216,
    weapons_9: '[Magnetic Storm] Charlie',
    weapons_9_app: 0.04,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0,
    weapons_10: 'Roaring Ride',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Swing Jazz, Puffer Electro',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Puffer Electro',
    artifacts_1_3: '',
    artifacts_1_app: 50.47,
    artifacts_1_round_sd: 127,
    artifacts_1_round_da: 19672,
    artifacts_2: 'Freedom Blues, Puffer Electro',
    artifacts_2_1: 'Freedom Blues',
    artifacts_2_2: 'Puffer Electro',
    artifacts_2_3: '',
    artifacts_2_app: 12.56,
    artifacts_2_round_sd: 115,
    artifacts_2_round_da: 21428,
    artifacts_3: 'Astral Voice, Swing Jazz',
    artifacts_3_1: 'Astral Voice',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 8.82,
    artifacts_3_round_sd: 122,
    artifacts_3_round_da: 19420,
    artifacts_4: 'Astral Voice, Puffer Electro',
    artifacts_4_1: 'Astral Voice',
    artifacts_4_2: 'Puffer Electro',
    artifacts_4_3: '',
    artifacts_4_app: 7.01,
    artifacts_4_round_sd: 107,
    artifacts_4_round_da: 21806,
    artifacts_5: 'Freedom Blues, Swing Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 3.51,
    artifacts_5_round_sd: 122,
    artifacts_5_round_da: 19498,
    artifacts_6: 'Swing Jazz, Freedom Blues',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Freedom Blues',
    artifacts_6_3: '',
    artifacts_6_app: 2.85,
    artifacts_6_round_sd: 136,
    artifacts_6_round_da: 19065,
    artifacts_7: 'Puffer Electro, Swing Jazz, Flex',
    artifacts_7_1: 'Puffer Electro',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: 'Flex',
    artifacts_7_app: 1.73,
    artifacts_7_round_sd: 142,
    artifacts_7_round_da: 18441,
    artifacts_8: 'Swing Jazz, Flex',
    artifacts_8_1: 'Swing Jazz',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 0.94,
    artifacts_8_round_sd: 156,
    artifacts_8_round_da: 14087,
    artifacts_9: 'Swing Jazz, Thunder Metal',
    artifacts_9_1: 'Swing Jazz',
    artifacts_9_2: 'Thunder Metal',
    artifacts_9_3: '',
    artifacts_9_app: 0.7,
    artifacts_9_round_sd: 142,
    artifacts_9_round_da: 15726,
    artifacts_10: 'Freedom Blues, Thunder Metal',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Thunder Metal',
    artifacts_10_3: '',
    artifacts_10_app: 0.47,
    artifacts_10_round_sd: 154,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'lucy',
    app_rate_sd: 33.79,
    app_rate_sd_e1: 11.12,
    app_rate_sd_s0: 5.42,
    avg_round_sd: 101,
    avg_round_sd_e1: 72,
    avg_round_sd_s0: 140,
    sample_sd: 1957,
    sample_size_players_sd: 0,
    app_rate_da: 55.48,
    app_rate_da_e1: 11.59,
    app_rate_da_s0: 20.74,
    avg_round_da: 19809,
    avg_round_da_e1: 28094,
    avg_round_da_s0: 17465,
    app_rate_da_boss_1: 10.24,
    avg_round_da_boss_1: 21440,
    app_rate_da_boss_2: 4.21,
    avg_round_da_boss_2: 19746,
    app_rate_da_boss_3: 48.81,
    avg_round_da_boss_3: 19522,
    sample_da: 3044,
    sample_size_players_da: 0,
    app_0: 0.34,
    round_0_sd: 250,
    round_0_da: 11729,
    app_1: 0.84,
    round_1_sd: 138,
    round_1_da: 14250,
    app_2: 2.36,
    round_2_sd: 140,
    round_2_da: 14857,
    app_3: 4.5,
    round_3_sd: 116,
    round_3_da: 16638,
    app_4: 6.26,
    round_4_sd: 121,
    round_4_da: 16033,
    app_5: 6.83,
    round_5_sd: 102,
    round_5_da: 18105,
    app_6: 78.88,
    round_6_sd: 96,
    round_6_da: 20704,
    cons_avg: 5.51,
    weapons_1: 'Kaboom the Cannon',
    weapons_1_app: 83.95,
    weapons_1_round_sd: 99,
    weapons_1_round_da: 20203,
    weapons_2: 'Slice of Time',
    weapons_2_app: 3.57,
    weapons_2_round_sd: 117,
    weapons_2_round_da: 16696,
    weapons_3: '[Reverb] Mark II',
    weapons_3_app: 2.02,
    weapons_3_round_sd: 123,
    weapons_3_round_da: 17204,
    weapons_4: 'Weeping Cradle',
    weapons_4_app: 1.87,
    weapons_4_round_sd: 124,
    weapons_4_round_da: 16661,
    weapons_5: 'Bashful Demon',
    weapons_5_app: 1.65,
    weapons_5_round_sd: 106,
    weapons_5_round_da: 19489,
    weapons_6: 'The Vault',
    weapons_6_app: 1.06,
    weapons_6_round_sd: 115,
    weapons_6_round_da: 22166,
    weapons_7: 'Unfettered Game Ball',
    weapons_7_app: 0.96,
    weapons_7_round_sd: 113,
    weapons_7_round_da: 17270,
    weapons_8: '[Reverb] Mark III',
    weapons_8_app: 0.41,
    weapons_8_round_sd: 111,
    weapons_8_round_da: 13713,
    weapons_9: '[Reverb] Mark I',
    weapons_9_app: 0.33,
    weapons_9_round_sd: 152,
    weapons_9_round_da: 14777,
    weapons_10: 'Starlight Engine',
    weapons_10_app: 0.1,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 17718,
    artifacts_1: 'Swing Jazz, Hormone Punk',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Hormone Punk',
    artifacts_1_3: '',
    artifacts_1_app: 45.95,
    artifacts_1_round_sd: 105,
    artifacts_1_round_da: 19050,
    artifacts_2: 'Astral Voice, Swing Jazz',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 14.03,
    artifacts_2_round_sd: 88,
    artifacts_2_round_da: 21547,
    artifacts_3: 'Swing Jazz, Woodpecker Electro',
    artifacts_3_1: 'Swing Jazz',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 4.83,
    artifacts_3_round_sd: 103,
    artifacts_3_round_da: 20911,
    artifacts_4: 'Swing Jazz, Astral Voice',
    artifacts_4_1: 'Swing Jazz',
    artifacts_4_2: 'Astral Voice',
    artifacts_4_3: '',
    artifacts_4_app: 3.39,
    artifacts_4_round_sd: 97,
    artifacts_4_round_da: 18949,
    artifacts_5: 'Swing Jazz, Freedom Blues',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Freedom Blues',
    artifacts_5_3: '',
    artifacts_5_app: 3.04,
    artifacts_5_round_sd: 87,
    artifacts_5_round_da: 19575,
    artifacts_6: 'Hormone Punk, Swing Jazz',
    artifacts_6_1: 'Hormone Punk',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: '',
    artifacts_6_app: 2.28,
    artifacts_6_round_sd: 113,
    artifacts_6_round_da: 18217,
    artifacts_7: 'Swing Jazz, Flex',
    artifacts_7_1: 'Swing Jazz',
    artifacts_7_2: 'Flex',
    artifacts_7_3: '',
    artifacts_7_app: 2.02,
    artifacts_7_round_sd: 116,
    artifacts_7_round_da: 18266,
    artifacts_8: 'ATK +10%, Swing Jazz, Flex',
    artifacts_8_1: 'ATK +10%',
    artifacts_8_2: 'Swing Jazz',
    artifacts_8_3: 'Flex',
    artifacts_8_app: 1.94,
    artifacts_8_round_sd: 109,
    artifacts_8_round_da: 20334,
    artifacts_9: 'Freedom Blues, Swing Jazz',
    artifacts_9_1: 'Freedom Blues',
    artifacts_9_2: 'Swing Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 1.77,
    artifacts_9_round_sd: 98,
    artifacts_9_round_da: 23855,
    artifacts_10: 'Swing Jazz, Inferno Metal',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Inferno Metal',
    artifacts_10_3: '',
    artifacts_10_app: 1.03,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 18588
  },
  {
    char: 'koleda',
    app_rate_sd: 1.78,
    app_rate_sd_e1: 0.48,
    app_rate_sd_s0: 0.64,
    avg_round_sd: 161,
    avg_round_sd_e1: 121,
    avg_round_sd_s0: 184,
    sample_sd: 103,
    sample_size_players_sd: 0,
    app_rate_da: 6.07,
    app_rate_da_e1: 0.97,
    app_rate_da_s0: 3.9,
    avg_round_da: 12590,
    avg_round_da_e1: 20547,
    avg_round_da_s0: 11933,
    app_rate_da_boss_1: 1.09,
    avg_round_da_boss_1: 12964,
    app_rate_da_boss_2: 1.34,
    avg_round_da_boss_2: 15524,
    app_rate_da_boss_3: 4.45,
    avg_round_da_boss_3: 11672,
    sample_da: 333,
    sample_size_players_da: 0,
    app_0: 46.33,
    round_0_sd: 161,
    round_0_da: 11991,
    app_1: 32.5,
    round_1_sd: 164,
    round_1_da: 12707,
    app_2: 15.89,
    round_2_sd: 155,
    round_2_da: 14236,
    app_3: 3.92,
    round_3_sd: 148,
    round_3_da: 11636,
    app_4: 1.38,
    round_4_sd: 600,
    round_4_da: 15680,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1,
    round_6_sd: 600,
    round_6_da: 0,
    cons_avg: 0.82,
    weapons_1: 'Hellfire Gears',
    weapons_1_app: 43.66,
    weapons_1_round_sd: 152,
    weapons_1_round_da: 12891,
    weapons_2: 'Steam Oven',
    weapons_2_app: 29.33,
    weapons_2_round_sd: 165,
    weapons_2_round_da: 13080,
    weapons_3: 'Precious Fossilized Core',
    weapons_3_app: 12.04,
    weapons_3_round_sd: 236,
    weapons_3_round_da: 11897,
    weapons_4: 'Demara Battery Mark II',
    weapons_4_app: 4.79,
    weapons_4_round_sd: 161,
    weapons_4_round_da: 9838,
    weapons_5: 'The Restrained',
    weapons_5_app: 4.15,
    weapons_5_round_sd: 131,
    weapons_5_round_da: 11078,
    weapons_6: 'Six Shooter',
    weapons_6_app: 3.01,
    weapons_6_round_sd: 158,
    weapons_6_round_da: 13587,
    weapons_7: '[Vortex] Revolver',
    weapons_7_app: 0.45,
    weapons_7_round_sd: 115,
    weapons_7_round_da: 6738,
    weapons_8: '[Vortex] Arrow',
    weapons_8_app: 0.23,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 11940,
    weapons_9: 'Big Cylinder',
    weapons_9_app: 0.23,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 14777,
    weapons_10: 'The Brimstone',
    weapons_10_app: 0.23,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 9193,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 69.53,
    artifacts_1_round_sd: 154,
    artifacts_1_round_da: 12898,
    artifacts_2: 'Shockstar Disco, Inferno Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 6.27,
    artifacts_2_round_sd: 139,
    artifacts_2_round_da: 10764,
    artifacts_3: 'Shockstar Disco, Flex',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Flex',
    artifacts_3_3: '',
    artifacts_3_app: 3.91,
    artifacts_3_round_sd: 240,
    artifacts_3_round_da: 11488,
    artifacts_4: 'Shockstar Disco, Freedom Blues',
    artifacts_4_1: 'Shockstar Disco',
    artifacts_4_2: 'Freedom Blues',
    artifacts_4_3: '',
    artifacts_4_app: 3.45,
    artifacts_4_round_sd: 600,
    artifacts_4_round_da: 12050,
    artifacts_5: 'Shockstar Disco, Woodpecker Electro',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Woodpecker Electro',
    artifacts_5_3: '',
    artifacts_5_app: 2.75,
    artifacts_5_round_sd: 182,
    artifacts_5_round_da: 13478,
    artifacts_6: 'Shockstar Disco, Swing Jazz, Flex',
    artifacts_6_1: 'Shockstar Disco',
    artifacts_6_2: 'Swing Jazz',
    artifacts_6_3: 'Flex',
    artifacts_6_app: 2.1,
    artifacts_6_round_sd: 600,
    artifacts_6_round_da: 10355,
    artifacts_7: 'Swing Jazz, Shockstar Disco',
    artifacts_7_1: 'Swing Jazz',
    artifacts_7_2: 'Shockstar Disco',
    artifacts_7_3: '',
    artifacts_7_app: 1.61,
    artifacts_7_round_sd: 107,
    artifacts_7_round_da: 9201,
    artifacts_8: 'Shockstar Disco, Hormone Punk',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Hormone Punk',
    artifacts_8_3: '',
    artifacts_8_app: 1.14,
    artifacts_8_round_sd: 219,
    artifacts_8_round_da: 6786,
    artifacts_9: 'Shockstar Disco, Flex',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Flex',
    artifacts_9_3: '',
    artifacts_9_app: 0.9,
    artifacts_9_round_sd: 190,
    artifacts_9_round_da: 14777,
    artifacts_10: 'Swing Jazz, Flex',
    artifacts_10_1: 'Swing Jazz',
    artifacts_10_2: 'Flex',
    artifacts_10_3: '',
    artifacts_10_app: 0.7,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 9450
  },
  {
    char: 'ellen',
    app_rate_sd: 16.38,
    app_rate_sd_e1: 6.08,
    app_rate_sd_s0: 2.35,
    avg_round_sd: 152,
    avg_round_sd_e1: 128,
    avg_round_sd_s0: 180,
    sample_sd: 949,
    sample_size_players_sd: 0,
    app_rate_da: 22.07,
    app_rate_da_e1: 8.58,
    app_rate_da_s0: 3.57,
    avg_round_da: 13561,
    avg_round_da_e1: 18249,
    avg_round_da_s0: 10886,
    app_rate_da_boss_1: 12.88,
    avg_round_da_boss_1: 12160,
    app_rate_da_boss_2: 8.3,
    avg_round_da_boss_2: 16526,
    app_rate_da_boss_3: 2.49,
    avg_round_da_boss_3: 11198,
    sample_da: 1211,
    sample_size_players_da: 0,
    app_0: 66.69,
    round_0_sd: 152,
    round_0_da: 13561,
    app_1: 17.09,
    round_1_sd: 142,
    round_1_da: 15471,
    app_2: 9.05,
    round_2_sd: 128,
    round_2_da: 18453,
    app_3: 1.76,
    round_3_sd: 133,
    round_3_da: 18419,
    app_4: 0.51,
    round_4_sd: 111,
    round_4_da: 17958,
    app_5: 0.33,
    round_5_sd: 128,
    round_5_da: 14546,
    app_6: 4.59,
    round_6_sd: 90,
    round_6_da: 27722,
    cons_avg: 0.72,
    weapons_1: 'Deep Sea Visitor',
    weapons_1_app: 75.37,
    weapons_1_round_sd: 145,
    weapons_1_round_da: 14481,
    weapons_2: 'Starlight Engine',
    weapons_2_app: 6.17,
    weapons_2_round_sd: 180,
    weapons_2_round_da: 10581,
    weapons_3: 'The Brimstone',
    weapons_3_app: 5.09,
    weapons_3_round_sd: 163,
    weapons_3_round_da: 12598,
    weapons_4: 'Marcato Desire',
    weapons_4_app: 3.44,
    weapons_4_round_sd: 158,
    weapons_4_round_da: 12827,
    weapons_5: 'Cannon Rotor',
    weapons_5_app: 3.15,
    weapons_5_round_sd: 180,
    weapons_5_round_da: 11626,
    weapons_6: 'Steel Cushion',
    weapons_6_app: 2.42,
    weapons_6_round_sd: 159,
    weapons_6_round_da: 13652,
    weapons_7: 'Gilded Blossom',
    weapons_7_app: 2.41,
    weapons_7_round_sd: 164,
    weapons_7_round_da: 10925,
    weapons_8: 'Street Superstar',
    weapons_8_app: 1.21,
    weapons_8_round_sd: 187,
    weapons_8_round_da: 8394,
    weapons_9: 'Riot Suppressor Mark VI',
    weapons_9_app: 0.19,
    weapons_9_round_sd: 142,
    weapons_9_round_da: 16522,
    weapons_10: 'The Restrained',
    weapons_10_app: 0.05,
    weapons_10_round_sd: 117,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Polar Metal, Woodpecker Electro',
    artifacts_1_1: 'Polar Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 52.74,
    artifacts_1_round_sd: 156,
    artifacts_1_round_da: 12632,
    artifacts_2: 'Polar Metal, Branch & Blade Song',
    artifacts_2_1: 'Polar Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 9.3,
    artifacts_2_round_sd: 151,
    artifacts_2_round_da: 14372,
    artifacts_3: 'Polar Metal, Puffer Electro',
    artifacts_3_1: 'Polar Metal',
    artifacts_3_2: 'Puffer Electro',
    artifacts_3_3: '',
    artifacts_3_app: 7.7,
    artifacts_3_round_sd: 142,
    artifacts_3_round_da: 16442,
    artifacts_4: 'Woodpecker Electro, Polar Metal',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Polar Metal',
    artifacts_4_3: '',
    artifacts_4_app: 7.68,
    artifacts_4_round_sd: 138,
    artifacts_4_round_da: 14798,
    artifacts_5: 'Woodpecker Electro, Puffer Electro',
    artifacts_5_1: 'Woodpecker Electro',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 6.74,
    artifacts_5_round_sd: 146,
    artifacts_5_round_da: 13387,
    artifacts_6: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_6_1: 'Woodpecker Electro',
    artifacts_6_2: 'Branch & Blade Song',
    artifacts_6_3: '',
    artifacts_6_app: 3.06,
    artifacts_6_round_sd: 130,
    artifacts_6_round_da: 16899,
    artifacts_7: 'Polar Metal, Hormone Punk',
    artifacts_7_1: 'Polar Metal',
    artifacts_7_2: 'Hormone Punk',
    artifacts_7_3: '',
    artifacts_7_app: 2.64,
    artifacts_7_round_sd: 163,
    artifacts_7_round_da: 13393,
    artifacts_8: 'Polar Metal, Flex',
    artifacts_8_1: 'Polar Metal',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 1.83,
    artifacts_8_round_sd: 155,
    artifacts_8_round_da: 12828,
    artifacts_9: 'Woodpecker Electro, Hormone Punk',
    artifacts_9_1: 'Woodpecker Electro',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 0.93,
    artifacts_9_round_sd: 150,
    artifacts_9_round_da: 15574,
    artifacts_10: 'Polar Metal, Woodpecker Electro, Flex',
    artifacts_10_1: 'Polar Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: 'Flex',
    artifacts_10_app: 0.57,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 10781
  },
  {
    char: 'qingyi',
    app_rate_sd: 40.3,
    app_rate_sd_e1: 9.38,
    app_rate_sd_s0: 16.49,
    avg_round_sd: 139,
    avg_round_sd_e1: 114,
    avg_round_sd_s0: 145,
    sample_sd: 2334,
    sample_size_players_sd: 0,
    app_rate_da: 45.05,
    app_rate_da_e1: 11.39,
    app_rate_da_s0: 18.06,
    avg_round_da: 20962,
    avg_round_da_e1: 26884,
    avg_round_da_s0: 20189,
    app_rate_da_boss_1: 2.62,
    avg_round_da_boss_1: 15106,
    app_rate_da_boss_2: 46.5,
    avg_round_da_boss_2: 21425,
    app_rate_da_boss_3: 0.77,
    avg_round_da_boss_3: 10402,
    sample_da: 2472,
    sample_size_players_da: 0,
    app_0: 88.16,
    round_0_sd: 139,
    round_0_da: 20962,
    app_1: 8.24,
    round_1_sd: 114,
    round_1_da: 26877,
    app_2: 2.24,
    round_2_sd: 96,
    round_2_da: 29209,
    app_3: 0.25,
    round_3_sd: 118,
    round_3_da: 26729,
    app_4: 1,
    round_4_sd: 600,
    round_4_da: 0,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.11,
    round_6_sd: 71,
    round_6_da: 46776,
    cons_avg: 0.2,
    weapons_1: 'Ice-Jade Teapot',
    weapons_1_app: 32.02,
    weapons_1_round_sd: 131,
    weapons_1_round_da: 21755,
    weapons_2: 'Steam Oven',
    weapons_2_app: 21.4,
    weapons_2_round_sd: 140,
    weapons_2_round_da: 20848,
    weapons_3: 'The Restrained',
    weapons_3_app: 15.81,
    weapons_3_round_sd: 134,
    weapons_3_round_da: 21132,
    weapons_4: 'Demara Battery Mark II',
    weapons_4_app: 13.89,
    weapons_4_round_sd: 149,
    weapons_4_round_da: 19836,
    weapons_5: 'Hellfire Gears',
    weapons_5_app: 9.03,
    weapons_5_round_sd: 144,
    weapons_5_round_da: 20991,
    weapons_6: 'Precious Fossilized Core',
    weapons_6_app: 5.86,
    weapons_6_round_sd: 142,
    weapons_6_round_da: 20751,
    weapons_7: 'Six Shooter',
    weapons_7_app: 1.06,
    weapons_7_round_sd: 138,
    weapons_7_round_da: 21011,
    weapons_8: '[Vortex] Arrow',
    weapons_8_app: 0.2,
    weapons_8_round_sd: 159,
    weapons_8_round_da: 19267,
    weapons_9: '[Vortex] Revolver',
    weapons_9_app: 0.06,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 8706,
    weapons_10: 'Slice of Time',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 147,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Shockstar Disco, Thunder Metal',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Thunder Metal',
    artifacts_1_3: '',
    artifacts_1_app: 40.82,
    artifacts_1_round_sd: 147,
    artifacts_1_round_da: 19860,
    artifacts_2: 'Shockstar Disco, Swing Jazz',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 37.27,
    artifacts_2_round_sd: 132,
    artifacts_2_round_da: 21564,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 11.18,
    artifacts_3_round_sd: 127,
    artifacts_3_round_da: 22571,
    artifacts_4: 'Shockstar Disco, Flex',
    artifacts_4_1: 'Shockstar Disco',
    artifacts_4_2: 'Flex',
    artifacts_4_3: '',
    artifacts_4_app: 1.88,
    artifacts_4_round_sd: 144,
    artifacts_4_round_da: 19685,
    artifacts_5: 'Shockstar Disco, Hormone Punk',
    artifacts_5_1: 'Shockstar Disco',
    artifacts_5_2: 'Hormone Punk',
    artifacts_5_3: '',
    artifacts_5_app: 1.36,
    artifacts_5_round_sd: 145,
    artifacts_5_round_da: 20899,
    artifacts_6: 'Shockstar Disco, Freedom Blues',
    artifacts_6_1: 'Shockstar Disco',
    artifacts_6_2: 'Freedom Blues',
    artifacts_6_3: '',
    artifacts_6_app: 1.03,
    artifacts_6_round_sd: 142,
    artifacts_6_round_da: 21175,
    artifacts_7: 'Shockstar Disco, Puffer Electro',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Puffer Electro',
    artifacts_7_3: '',
    artifacts_7_app: 1.0,
    artifacts_7_round_sd: 138,
    artifacts_7_round_da: 21540,
    artifacts_8: 'Astral Voice, Shockstar Disco',
    artifacts_8_1: 'Astral Voice',
    artifacts_8_2: 'Shockstar Disco',
    artifacts_8_3: '',
    artifacts_8_app: 0.69,
    artifacts_8_round_sd: 106,
    artifacts_8_round_da: 30309,
    artifacts_9: 'Shockstar Disco, Thunder Metal, Flex',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Thunder Metal',
    artifacts_9_3: 'Flex',
    artifacts_9_app: 0.48,
    artifacts_9_round_sd: 144,
    artifacts_9_round_da: 16442,
    artifacts_10: 'Shockstar Disco, Branch & Blade Song',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Branch & Blade Song',
    artifacts_10_3: '',
    artifacts_10_app: 0.36,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 21147
  },
  {
    char: 'jane-doe',
    app_rate_sd: 19.96,
    app_rate_sd_e1: 5.7,
    app_rate_sd_s0: 5.39,
    avg_round_sd: 176,
    avg_round_sd_e1: 139,
    avg_round_sd_s0: 182,
    sample_sd: 1156,
    sample_size_players_sd: 0,
    app_rate_da: 44.67,
    app_rate_da_e1: 10.57,
    app_rate_da_s0: 14.34,
    avg_round_da: 20034,
    avg_round_da_e1: 25871,
    avg_round_da_s0: 18571,
    app_rate_da_boss_1: 2.75,
    avg_round_da_boss_1: 17079,
    app_rate_da_boss_2: 3.0,
    avg_round_da_boss_2: 14276,
    app_rate_da_boss_3: 45.93,
    avg_round_da_boss_3: 20583,
    sample_da: 2451,
    sample_size_players_da: 0,
    app_0: 81.51,
    round_0_sd: 176,
    round_0_da: 20034,
    app_1: 8.81,
    round_1_sd: 159,
    round_1_da: 21498,
    app_2: 7.01,
    round_2_sd: 130,
    round_2_da: 27901,
    app_3: 0.58,
    round_3_sd: 138,
    round_3_da: 23780,
    app_4: 0.14,
    round_4_sd: 94,
    round_4_da: 29765,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.96,
    round_6_sd: 94,
    round_6_da: 39027,
    cons_avg: 0.37,
    weapons_1: 'Sharpened Stinger',
    weapons_1_app: 55.43,
    weapons_1_round_sd: 174,
    weapons_1_round_da: 21031,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 16.48,
    weapons_2_round_sd: 171,
    weapons_2_round_da: 19655,
    weapons_3: 'Rainforest Gourmet',
    weapons_3_app: 12.64,
    weapons_3_round_sd: 181,
    weapons_3_round_da: 18341,
    weapons_4: 'Electro-Lip Gloss',
    weapons_4_app: 10.91,
    weapons_4_round_sd: 176,
    weapons_4_round_da: 20066,
    weapons_5: 'Fusion Compiler',
    weapons_5_app: 3.24,
    weapons_5_round_sd: 202,
    weapons_5_round_da: 17115,
    weapons_6: 'Roaring Ride',
    weapons_6_app: 0.69,
    weapons_6_round_sd: 193,
    weapons_6_round_da: 18938,
    weapons_7: 'Timeweaver',
    weapons_7_app: 0.09,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 27851,
    weapons_8: '[Magnetic Storm] Bravo',
    weapons_8_app: 0.08,
    weapons_8_round_sd: 600,
    weapons_8_round_da: 13273,
    weapons_9: 'Flamemaker Shaker',
    weapons_9_app: 0.06,
    weapons_9_round_sd: 178,
    weapons_9_round_da: 7457,
    weapons_10: '[Magnetic Storm] Charlie',
    weapons_10_app: 0.06,
    weapons_10_round_sd: 155,
    weapons_10_round_da: 20186,
    artifacts_1: 'Fanged Metal, Freedom Blues',
    artifacts_1_1: 'Fanged Metal',
    artifacts_1_2: 'Freedom Blues',
    artifacts_1_3: '',
    artifacts_1_app: 86.1,
    artifacts_1_round_sd: 177,
    artifacts_1_round_da: 19953,
    artifacts_2: 'Fanged Metal, Chaos Jazz',
    artifacts_2_1: 'Fanged Metal',
    artifacts_2_2: 'Chaos Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 6.27,
    artifacts_2_round_sd: 153,
    artifacts_2_round_da: 21883,
    artifacts_3: 'Fanged Metal, Hormone Punk',
    artifacts_3_1: 'Fanged Metal',
    artifacts_3_2: 'Hormone Punk',
    artifacts_3_3: '',
    artifacts_3_app: 2.79,
    artifacts_3_round_sd: 172,
    artifacts_3_round_da: 19641,
    artifacts_4: 'Freedom Blues, Fanged Metal',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Fanged Metal',
    artifacts_4_3: '',
    artifacts_4_app: 1.34,
    artifacts_4_round_sd: 205,
    artifacts_4_round_da: 18869,
    artifacts_5: 'Fanged Metal, Puffer Electro',
    artifacts_5_1: 'Fanged Metal',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 0.65,
    artifacts_5_round_sd: 161,
    artifacts_5_round_da: 19998,
    artifacts_6: 'Fanged Metal, Flex',
    artifacts_6_1: 'Fanged Metal',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 0.57,
    artifacts_6_round_sd: 189,
    artifacts_6_round_da: 17514,
    artifacts_7: 'Freedom Blues, Chaos Jazz',
    artifacts_7_1: 'Freedom Blues',
    artifacts_7_2: 'Chaos Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 0.45,
    artifacts_7_round_sd: 189,
    artifacts_7_round_da: 20414,
    artifacts_8: 'Fanged Metal, Astral Voice',
    artifacts_8_1: 'Fanged Metal',
    artifacts_8_2: 'Astral Voice',
    artifacts_8_3: '',
    artifacts_8_app: 0.28,
    artifacts_8_round_sd: 121,
    artifacts_8_round_da: 27959,
    artifacts_9: 'AP +30, AP +30, Fanged Metal',
    artifacts_9_1: 'AP +30',
    artifacts_9_2: 'AP +30',
    artifacts_9_3: 'Fanged Metal',
    artifacts_9_app: 0.22,
    artifacts_9_round_sd: 239,
    artifacts_9_round_da: 25842,
    artifacts_10: 'Freedom Blues, Swing Jazz',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 0.11,
    artifacts_10_round_sd: 95,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'seth',
    app_rate_sd: 18.51,
    app_rate_sd_e1: 4.06,
    app_rate_sd_s0: 6.42,
    avg_round_sd: 166,
    avg_round_sd_e1: 136,
    avg_round_sd_s0: 173,
    sample_sd: 1072,
    sample_size_players_sd: 0,
    app_rate_da: 19.7,
    app_rate_da_e1: 4.85,
    app_rate_da_s0: 6.54,
    avg_round_da: 15866,
    avg_round_da_e1: 21167,
    avg_round_da_s0: 14618,
    app_rate_da_boss_1: 2.3,
    avg_round_da_boss_1: 14222,
    app_rate_da_boss_2: 7.04,
    avg_round_da_boss_2: 16923,
    app_rate_da_boss_3: 13.01,
    avg_round_da_boss_3: 15496,
    sample_da: 1081,
    sample_size_players_da: 0,
    app_0: 2.5,
    round_0_sd: 174,
    round_0_da: 14408,
    app_1: 5.05,
    round_1_sd: 168,
    round_1_da: 15268,
    app_2: 9.62,
    round_2_sd: 174,
    round_2_da: 15092,
    app_3: 9.94,
    round_3_sd: 168,
    round_3_da: 15472,
    app_4: 12.2,
    round_4_sd: 169,
    round_4_da: 15410,
    app_5: 11.66,
    round_5_sd: 158,
    round_5_da: 15796,
    app_6: 49.03,
    round_6_sd: 163,
    round_6_da: 16520,
    cons_avg: 4.55,
    weapons_1: 'Peacekeeper - Specialized',
    weapons_1_app: 85.45,
    weapons_1_round_sd: 165,
    weapons_1_round_da: 15905,
    weapons_2: 'Bunny Band',
    weapons_2_app: 4.54,
    weapons_2_round_sd: 180,
    weapons_2_round_da: 13412,
    weapons_3: 'Spring Embrace',
    weapons_3_app: 3.76,
    weapons_3_round_sd: 149,
    weapons_3_round_da: 16968,
    weapons_4: 'Original Transmorpher',
    weapons_4_app: 2.05,
    weapons_4_round_sd: 187,
    weapons_4_round_da: 14694,
    weapons_5: 'Big Cylinder',
    weapons_5_app: 1.16,
    weapons_5_round_sd: 194,
    weapons_5_round_da: 15008,
    weapons_6: 'Starlight Engine',
    weapons_6_app: 0.79,
    weapons_6_round_sd: 159,
    weapons_6_round_da: 17442,
    weapons_7: 'Weeping Gemini',
    weapons_7_app: 0.19,
    weapons_7_round_sd: 114,
    weapons_7_round_da: 19372,
    weapons_8: 'Tusks of Fury',
    weapons_8_app: 0.18,
    weapons_8_round_sd: 222,
    weapons_8_round_da: 0.0,
    weapons_9: 'Gilded Blossom',
    weapons_9_app: 0.14,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 16543,
    weapons_10: 'Steam Oven',
    weapons_10_app: 0.1,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 15490,
    artifacts_1: 'Swing Jazz, Hormone Punk',
    artifacts_1_1: 'Swing Jazz',
    artifacts_1_2: 'Hormone Punk',
    artifacts_1_3: '',
    artifacts_1_app: 29.58,
    artifacts_1_round_sd: 175,
    artifacts_1_round_da: 14854,
    artifacts_2: 'Proto Punk, Hormone Punk',
    artifacts_2_1: 'Proto Punk',
    artifacts_2_2: 'Hormone Punk',
    artifacts_2_3: '',
    artifacts_2_app: 12.52,
    artifacts_2_round_sd: 148,
    artifacts_2_round_da: 16407,
    artifacts_3: 'Proto Punk, Swing Jazz',
    artifacts_3_1: 'Proto Punk',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 6.61,
    artifacts_3_round_sd: 154,
    artifacts_3_round_da: 15557,
    artifacts_4: 'Swing Jazz, Freedom Blues',
    artifacts_4_1: 'Swing Jazz',
    artifacts_4_2: 'Freedom Blues',
    artifacts_4_3: '',
    artifacts_4_app: 6.32,
    artifacts_4_round_sd: 188,
    artifacts_4_round_da: 15892,
    artifacts_5: 'Astral Voice, Swing Jazz',
    artifacts_5_1: 'Astral Voice',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 3.68,
    artifacts_5_round_sd: 151,
    artifacts_5_round_da: 17372,
    artifacts_6: 'Proto Punk, Shockstar Disco',
    artifacts_6_1: 'Proto Punk',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 3.36,
    artifacts_6_round_sd: 128,
    artifacts_6_round_da: 16442,
    artifacts_7: 'Freedom Blues, Swing Jazz',
    artifacts_7_1: 'Freedom Blues',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 2.99,
    artifacts_7_round_sd: 148,
    artifacts_7_round_da: 17641,
    artifacts_8: 'Freedom Blues, Hormone Punk',
    artifacts_8_1: 'Freedom Blues',
    artifacts_8_2: 'Hormone Punk',
    artifacts_8_3: '',
    artifacts_8_app: 2.74,
    artifacts_8_round_sd: 174,
    artifacts_8_round_da: 16369,
    artifacts_9: 'ATK +10%, Swing Jazz, Flex',
    artifacts_9_1: 'ATK +10%',
    artifacts_9_2: 'Swing Jazz',
    artifacts_9_3: 'Flex',
    artifacts_9_app: 2.45,
    artifacts_9_round_sd: 179,
    artifacts_9_round_da: 18557,
    artifacts_10: 'Hormone Punk, Swing Jazz',
    artifacts_10_1: 'Hormone Punk',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 1.22,
    artifacts_10_round_sd: 180,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'caesar',
    app_rate_sd: 35.5,
    app_rate_sd_e1: 12.71,
    app_rate_sd_s0: 6.37,
    avg_round_sd: 135,
    avg_round_sd_e1: 91,
    avg_round_sd_s0: 154,
    sample_sd: 2056,
    sample_size_players_sd: 0,
    app_rate_da: 44.09,
    app_rate_da_e1: 11.61,
    app_rate_da_s0: 11.68,
    avg_round_da: 19200,
    avg_round_da_e1: 26635,
    avg_round_da_s0: 17545,
    app_rate_da_boss_1: 9.36,
    avg_round_da_boss_1: 17603,
    app_rate_da_boss_2: 10.67,
    avg_round_da_boss_2: 20902,
    app_rate_da_boss_3: 29.71,
    avg_round_da_boss_3: 19019,
    sample_da: 2419,
    sample_size_players_da: 0,
    app_0: 89.26,
    round_0_sd: 135,
    round_0_da: 19200,
    app_1: 6.63,
    round_1_sd: 105,
    round_1_da: 24293,
    app_2: 3.1,
    round_2_sd: 83,
    round_2_da: 34192,
    app_3: 0.36,
    round_3_sd: 96,
    round_3_da: 36480,
    app_4: 0.04,
    round_4_sd: 600,
    round_4_da: 25020,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 0.62,
    round_6_sd: 71,
    round_6_da: 42996,
    cons_avg: 0.18,
    weapons_1: 'Tusks of Fury',
    weapons_1_app: 48.19,
    weapons_1_round_sd: 132,
    weapons_1_round_da: 20180,
    weapons_2: 'Original Transmorpher',
    weapons_2_app: 21.13,
    weapons_2_round_sd: 139,
    weapons_2_round_da: 18010,
    weapons_3: 'Demara Battery Mark II',
    weapons_3_app: 8.19,
    weapons_3_round_sd: 134,
    weapons_3_round_da: 19259,
    weapons_4: 'Precious Fossilized Core',
    weapons_4_app: 7.01,
    weapons_4_round_sd: 135,
    weapons_4_round_da: 18860,
    weapons_5: 'Hellfire Gears',
    weapons_5_app: 4.39,
    weapons_5_round_sd: 131,
    weapons_5_round_da: 18739,
    weapons_6: 'The Restrained',
    weapons_6_app: 3.78,
    weapons_6_round_sd: 128,
    weapons_6_round_da: 20580,
    weapons_7: 'Spring Embrace',
    weapons_7_app: 2.59,
    weapons_7_round_sd: 136,
    weapons_7_round_da: 20196,
    weapons_8: 'Peacekeeper - Specialized',
    weapons_8_app: 1.99,
    weapons_8_round_sd: 125,
    weapons_8_round_da: 17952,
    weapons_9: 'Bunny Band',
    weapons_9_app: 0.63,
    weapons_9_round_sd: 161,
    weapons_9_round_da: 13490,
    weapons_10: 'Six Shooter',
    weapons_10_app: 0.58,
    weapons_10_round_sd: 112,
    weapons_10_round_da: 17031,
    artifacts_1: 'Proto Punk, Shockstar Disco',
    artifacts_1_1: 'Proto Punk',
    artifacts_1_2: 'Shockstar Disco',
    artifacts_1_3: '',
    artifacts_1_app: 84.86,
    artifacts_1_round_sd: 133,
    artifacts_1_round_da: 19120,
    artifacts_2: 'Proto Punk, Swing Jazz',
    artifacts_2_1: 'Proto Punk',
    artifacts_2_2: 'Swing Jazz',
    artifacts_2_3: '',
    artifacts_2_app: 7.32,
    artifacts_2_round_sd: 129,
    artifacts_2_round_da: 19811,
    artifacts_3: 'Proto Punk, Flex',
    artifacts_3_1: 'Proto Punk',
    artifacts_3_2: 'Flex',
    artifacts_3_3: '',
    artifacts_3_app: 1.03,
    artifacts_3_round_sd: 171,
    artifacts_3_round_da: 17856,
    artifacts_4: 'Freedom Blues, Shockstar Disco',
    artifacts_4_1: 'Freedom Blues',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 0.96,
    artifacts_4_round_sd: 140,
    artifacts_4_round_da: 19642,
    artifacts_5: 'Swing Jazz, Shockstar Disco',
    artifacts_5_1: 'Swing Jazz',
    artifacts_5_2: 'Shockstar Disco',
    artifacts_5_3: '',
    artifacts_5_app: 0.56,
    artifacts_5_round_sd: 97,
    artifacts_5_round_da: 23660,
    artifacts_6: 'Proto Punk, Fanged Metal',
    artifacts_6_1: 'Proto Punk',
    artifacts_6_2: 'Fanged Metal',
    artifacts_6_3: '',
    artifacts_6_app: 0.52,
    artifacts_6_round_sd: 151,
    artifacts_6_round_da: 16517,
    artifacts_7: 'Proto Punk, Woodpecker Electro',
    artifacts_7_1: 'Proto Punk',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 0.47,
    artifacts_7_round_sd: 169,
    artifacts_7_round_da: 17765,
    artifacts_8: 'Proto Punk, Freedom Blues',
    artifacts_8_1: 'Proto Punk',
    artifacts_8_2: 'Freedom Blues',
    artifacts_8_3: '',
    artifacts_8_app: 0.43,
    artifacts_8_round_sd: 220,
    artifacts_8_round_da: 19653,
    artifacts_9: 'Shockstar Disco, Proto Punk',
    artifacts_9_1: 'Shockstar Disco',
    artifacts_9_2: 'Proto Punk',
    artifacts_9_3: '',
    artifacts_9_app: 0.42,
    artifacts_9_round_sd: 148,
    artifacts_9_round_da: 16062,
    artifacts_10: 'Freedom Blues, Swing Jazz',
    artifacts_10_1: 'Freedom Blues',
    artifacts_10_2: 'Swing Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 0.18,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 21698
  },
  {
    char: 'burnice',
    app_rate_sd: 30.73,
    app_rate_sd_e1: 9.24,
    app_rate_sd_s0: 5.63,
    avg_round_sd: 106,
    avg_round_sd_e1: 84,
    avg_round_sd_s0: 126,
    sample_sd: 1780,
    sample_size_players_sd: 0,
    app_rate_da: 55.51,
    app_rate_da_e1: 11.24,
    app_rate_da_s0: 19.34,
    avg_round_da: 22409,
    avg_round_da_e1: 29116,
    avg_round_da_s0: 20705,
    app_rate_da_boss_1: 3.52,
    avg_round_da_boss_1: 24072,
    app_rate_da_boss_2: 2.57,
    avg_round_da_boss_2: 23763,
    app_rate_da_boss_3: 58.18,
    avg_round_da_boss_3: 22256,
    sample_da: 3046,
    sample_size_players_da: 0,
    app_0: 90.48,
    round_0_sd: 106,
    round_0_da: 22409,
    app_1: 6.41,
    round_1_sd: 99,
    round_1_da: 25984,
    app_2: 2.03,
    round_2_sd: 84,
    round_2_da: 35355,
    app_3: 0.29,
    round_3_sd: 70,
    round_3_da: 31870,
    app_4: 0.03,
    round_4_sd: 600,
    round_4_da: 29664,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 0.76,
    round_6_sd: 72,
    round_6_da: 44749,
    cons_avg: 0.16,
    weapons_1: 'Flamemaker Shaker',
    weapons_1_app: 38.05,
    weapons_1_round_sd: 109,
    weapons_1_round_da: 24015,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 23.44,
    weapons_2_round_sd: 106,
    weapons_2_round_da: 21698,
    weapons_3: 'Electro-Lip Gloss',
    weapons_3_app: 15.61,
    weapons_3_round_sd: 102,
    weapons_3_round_da: 22743,
    weapons_4: 'Roaring Ride',
    weapons_4_app: 8.54,
    weapons_4_round_sd: 111,
    weapons_4_round_da: 20644,
    weapons_5: 'Fusion Compiler',
    weapons_5_app: 7.09,
    weapons_5_round_sd: 97,
    weapons_5_round_da: 22059,
    weapons_6: 'Rainforest Gourmet',
    weapons_6_app: 6.86,
    weapons_6_round_sd: 113,
    weapons_6_round_da: 19832,
    weapons_7: '[Magnetic Storm] Bravo',
    weapons_7_app: 0.07,
    weapons_7_round_sd: 156,
    weapons_7_round_da: 15335,
    weapons_8: 'Hailstorm Shrine',
    weapons_8_app: 0.04,
    weapons_8_round_sd: 68,
    weapons_8_round_da: 38718,
    weapons_9: '[Magnetic Storm] Alpha',
    weapons_9_app: 0.04,
    weapons_9_round_sd: 42,
    weapons_9_round_da: 19780,
    weapons_10: 'The Brimstone',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 37,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Chaos Jazz, Freedom Blues',
    artifacts_1_1: 'Chaos Jazz',
    artifacts_1_2: 'Freedom Blues',
    artifacts_1_3: '',
    artifacts_1_app: 54.43,
    artifacts_1_round_sd: 110,
    artifacts_1_round_da: 22091,
    artifacts_2: 'Chaos Jazz, Inferno Metal',
    artifacts_2_1: 'Chaos Jazz',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 19.51,
    artifacts_2_round_sd: 103,
    artifacts_2_round_da: 22717,
    artifacts_3: 'Chaos Jazz, Swing Jazz',
    artifacts_3_1: 'Chaos Jazz',
    artifacts_3_2: 'Swing Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 18.92,
    artifacts_3_round_sd: 101,
    artifacts_3_round_da: 22812,
    artifacts_4: 'Chaos Jazz, Hormone Punk',
    artifacts_4_1: 'Chaos Jazz',
    artifacts_4_2: 'Hormone Punk',
    artifacts_4_3: '',
    artifacts_4_app: 2.12,
    artifacts_4_round_sd: 93,
    artifacts_4_round_da: 24669,
    artifacts_5: 'Chaos Jazz, Puffer Electro',
    artifacts_5_1: 'Chaos Jazz',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 1.14,
    artifacts_5_round_sd: 76,
    artifacts_5_round_da: 26155,
    artifacts_6: 'Chaos Jazz, Flex',
    artifacts_6_1: 'Chaos Jazz',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 0.74,
    artifacts_6_round_sd: 162,
    artifacts_6_round_da: 20502,
    artifacts_7: 'Chaos Jazz, Astral Voice',
    artifacts_7_1: 'Chaos Jazz',
    artifacts_7_2: 'Astral Voice',
    artifacts_7_3: '',
    artifacts_7_app: 0.59,
    artifacts_7_round_sd: 72,
    artifacts_7_round_da: 24284,
    artifacts_8: 'Chaos Jazz, Woodpecker Electro',
    artifacts_8_1: 'Chaos Jazz',
    artifacts_8_2: 'Woodpecker Electro',
    artifacts_8_3: '',
    artifacts_8_app: 0.29,
    artifacts_8_round_sd: 116,
    artifacts_8_round_da: 19824,
    artifacts_9: 'Freedom Blues, Chaos Jazz',
    artifacts_9_1: 'Freedom Blues',
    artifacts_9_2: 'Chaos Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 0.27,
    artifacts_9_round_sd: 110,
    artifacts_9_round_da: 21901,
    artifacts_10: 'Inferno Metal, Woodpecker Electro',
    artifacts_10_1: 'Inferno Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.25,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 23765
  },
  {
    char: 'yanagi',
    app_rate_sd: 55.51,
    app_rate_sd_e1: 15.12,
    app_rate_sd_s0: 12.07,
    avg_round_sd: 105,
    avg_round_sd_e1: 73,
    avg_round_sd_s0: 132,
    sample_sd: 3215,
    sample_size_players_sd: 0,
    app_rate_da: 53.23,
    app_rate_da_e1: 14.09,
    app_rate_da_s0: 11.23,
    avg_round_da: 23095,
    avg_round_da_e1: 32771,
    avg_round_da_s0: 19661,
    app_rate_da_boss_1: 28.39,
    avg_round_da_boss_1: 24914,
    app_rate_da_boss_2: 23.83,
    avg_round_da_boss_2: 21320,
    app_rate_da_boss_3: 5.03,
    avg_round_da_boss_3: 21777,
    sample_da: 2921,
    sample_size_players_da: 0,
    app_0: 89.9,
    round_0_sd: 105,
    round_0_da: 23095,
    app_1: 5.12,
    round_1_sd: 85,
    round_1_da: 29711,
    app_2: 3.59,
    round_2_sd: 78,
    round_2_da: 32299,
    app_3: 0.33,
    round_3_sd: 83,
    round_3_da: 33943,
    app_4: 0.03,
    round_4_sd: 87,
    round_4_da: 32155,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.03,
    round_6_sd: 56,
    round_6_da: 50523,
    cons_avg: 0.2,
    weapons_1: 'Timeweaver',
    weapons_1_app: 42.8,
    weapons_1_round_sd: 99,
    weapons_1_round_da: 24085,
    weapons_2: 'Weeping Gemini',
    weapons_2_app: 25.05,
    weapons_2_round_sd: 108,
    weapons_2_round_da: 22081,
    weapons_3: 'Fusion Compiler',
    weapons_3_app: 16.96,
    weapons_3_round_sd: 103,
    weapons_3_round_da: 23811,
    weapons_4: 'Electro-Lip Gloss',
    weapons_4_app: 9.81,
    weapons_4_round_sd: 114,
    weapons_4_round_da: 22841,
    weapons_5: 'Rainforest Gourmet',
    weapons_5_app: 3.78,
    weapons_5_round_sd: 119,
    weapons_5_round_da: 20510,
    weapons_6: 'Roaring Ride',
    weapons_6_app: 1.05,
    weapons_6_round_sd: 122,
    weapons_6_round_da: 18628,
    weapons_7: 'Sharpened Stinger',
    weapons_7_app: 0.08,
    weapons_7_round_sd: 600,
    weapons_7_round_da: 21302,
    weapons_8: 'The Vault',
    weapons_8_app: 0.04,
    weapons_8_round_sd: 86,
    weapons_8_round_da: 20202,
    weapons_9: 'Hailstorm Shrine',
    weapons_9_app: 0.03,
    weapons_9_round_sd: 60,
    weapons_9_round_da: 0.0,
    weapons_10: 'Flamemaker Shaker',
    weapons_10_app: 0.03,
    weapons_10_round_sd: 135,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Chaos Jazz, Freedom Blues',
    artifacts_1_1: 'Chaos Jazz',
    artifacts_1_2: 'Freedom Blues',
    artifacts_1_3: '',
    artifacts_1_app: 70.73,
    artifacts_1_round_sd: 104,
    artifacts_1_round_da: 23228,
    artifacts_2: 'Thunder Metal, Freedom Blues',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Freedom Blues',
    artifacts_2_3: '',
    artifacts_2_app: 7.45,
    artifacts_2_round_sd: 111,
    artifacts_2_round_da: 21120,
    artifacts_3: 'Thunder Metal, Chaos Jazz',
    artifacts_3_1: 'Thunder Metal',
    artifacts_3_2: 'Chaos Jazz',
    artifacts_3_3: '',
    artifacts_3_app: 5.93,
    artifacts_3_round_sd: 120,
    artifacts_3_round_da: 21542,
    artifacts_4: 'Chaos Jazz, Thunder Metal',
    artifacts_4_1: 'Chaos Jazz',
    artifacts_4_2: 'Thunder Metal',
    artifacts_4_3: '',
    artifacts_4_app: 4.56,
    artifacts_4_round_sd: 101,
    artifacts_4_round_da: 23573,
    artifacts_5: 'Freedom Blues, Chaos Jazz',
    artifacts_5_1: 'Freedom Blues',
    artifacts_5_2: 'Chaos Jazz',
    artifacts_5_3: '',
    artifacts_5_app: 2.44,
    artifacts_5_round_sd: 110,
    artifacts_5_round_da: 24531,
    artifacts_6: 'Chaos Jazz, Puffer Electro',
    artifacts_6_1: 'Chaos Jazz',
    artifacts_6_2: 'Puffer Electro',
    artifacts_6_3: '',
    artifacts_6_app: 2.03,
    artifacts_6_round_sd: 85,
    artifacts_6_round_da: 27465,
    artifacts_7: 'Chaos Jazz, Swing Jazz',
    artifacts_7_1: 'Chaos Jazz',
    artifacts_7_2: 'Swing Jazz',
    artifacts_7_3: '',
    artifacts_7_app: 1.13,
    artifacts_7_round_sd: 109,
    artifacts_7_round_da: 23390,
    artifacts_8: 'AP +30, AP +30, Thunder Metal',
    artifacts_8_1: 'AP +30',
    artifacts_8_2: 'AP +30',
    artifacts_8_3: 'Thunder Metal',
    artifacts_8_app: 0.72,
    artifacts_8_round_sd: 116,
    artifacts_8_round_da: 25726,
    artifacts_9: 'Chaos Jazz, Hormone Punk',
    artifacts_9_1: 'Chaos Jazz',
    artifacts_9_2: 'Hormone Punk',
    artifacts_9_3: '',
    artifacts_9_app: 0.65,
    artifacts_9_round_sd: 110,
    artifacts_9_round_da: 24263,
    artifacts_10: 'Thunder Metal, Puffer Electro',
    artifacts_10_1: 'Thunder Metal',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.53,
    artifacts_10_round_sd: 99,
    artifacts_10_round_da: 26176
  },
  {
    char: 'lighter',
    app_rate_sd: 10.31,
    app_rate_sd_e1: 3.63,
    app_rate_sd_s0: 1.05,
    avg_round_sd: 103,
    avg_round_sd_e1: 83,
    avg_round_sd_s0: 135,
    sample_sd: 597,
    sample_size_players_sd: 0,
    app_rate_da: 14.07,
    app_rate_da_e1: 3.88,
    app_rate_da_s0: 3.23,
    avg_round_da: 18912,
    avg_round_da_e1: 28232,
    avg_round_da_s0: 16182,
    app_rate_da_boss_1: 4.8,
    avg_round_da_boss_1: 19267,
    app_rate_da_boss_2: 1.44,
    avg_round_da_boss_2: 18418,
    app_rate_da_boss_3: 9.5,
    avg_round_da_boss_3: 18814,
    sample_da: 772,
    sample_size_players_da: 0,
    app_0: 90.18,
    round_0_sd: 103,
    round_0_da: 18912,
    app_1: 4.61,
    round_1_sd: 95,
    round_1_da: 23565,
    app_2: 2.43,
    round_2_sd: 81,
    round_2_da: 33514,
    app_3: 1.39,
    round_3_sd: 71,
    round_3_da: 30653,
    app_4: 0.22,
    round_4_sd: 52,
    round_4_da: 30133,
    app_5: 1,
    round_5_sd: 600,
    round_5_da: 0,
    app_6: 1.17,
    round_6_sd: 64,
    round_6_da: 43445,
    cons_avg: 0.21,
    weapons_1: 'Blazing Laurel',
    weapons_1_app: 49.26,
    weapons_1_round_sd: 104,
    weapons_1_round_da: 20228,
    weapons_2: 'Hellfire Gears',
    weapons_2_app: 17.15,
    weapons_2_round_sd: 94,
    weapons_2_round_da: 18863,
    weapons_3: 'Precious Fossilized Core',
    weapons_3_app: 10.93,
    weapons_3_round_sd: 102,
    weapons_3_round_da: 16584,
    weapons_4: 'Steam Oven',
    weapons_4_app: 10.78,
    weapons_4_round_sd: 99,
    weapons_4_round_da: 17652,
    weapons_5: 'The Restrained',
    weapons_5_app: 7.4,
    weapons_5_round_sd: 120,
    weapons_5_round_da: 18572,
    weapons_6: 'Ice-Jade Teapot',
    weapons_6_app: 1.9,
    weapons_6_round_sd: 101,
    weapons_6_round_da: 18622,
    weapons_7: 'Six Shooter',
    weapons_7_app: 1.54,
    weapons_7_round_sd: 126,
    weapons_7_round_da: 16206,
    weapons_8: 'Demara Battery Mark II',
    weapons_8_app: 0.29,
    weapons_8_round_sd: 99,
    weapons_8_round_da: 21627,
    weapons_9: 'Peacekeeper - Specialized',
    weapons_9_app: 0.15,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 0,
    weapons_10: 'Steel Cushion',
    weapons_10_app: 0.15,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 21392,
    artifacts_1: 'Shockstar Disco, Swing Jazz',
    artifacts_1_1: 'Shockstar Disco',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 57.06,
    artifacts_1_round_sd: 101,
    artifacts_1_round_da: 18721,
    artifacts_2: 'Shockstar Disco, Inferno Metal',
    artifacts_2_1: 'Shockstar Disco',
    artifacts_2_2: 'Inferno Metal',
    artifacts_2_3: '',
    artifacts_2_app: 14.23,
    artifacts_2_round_sd: 117,
    artifacts_2_round_da: 17483,
    artifacts_3: 'Shockstar Disco, Woodpecker Electro',
    artifacts_3_1: 'Shockstar Disco',
    artifacts_3_2: 'Woodpecker Electro',
    artifacts_3_3: '',
    artifacts_3_app: 7.35,
    artifacts_3_round_sd: 91,
    artifacts_3_round_da: 21021,
    artifacts_4: 'Inferno Metal, Shockstar Disco',
    artifacts_4_1: 'Inferno Metal',
    artifacts_4_2: 'Shockstar Disco',
    artifacts_4_3: '',
    artifacts_4_app: 3.95,
    artifacts_4_round_sd: 109,
    artifacts_4_round_da: 20769,
    artifacts_5: 'Proto Punk, Shockstar Disco',
    artifacts_5_1: 'Proto Punk',
    artifacts_5_2: 'Shockstar Disco',
    artifacts_5_3: '',
    artifacts_5_app: 2.8,
    artifacts_5_round_sd: 79,
    artifacts_5_round_da: 19532,
    artifacts_6: 'Swing Jazz, Shockstar Disco',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Shockstar Disco',
    artifacts_6_3: '',
    artifacts_6_app: 1.98,
    artifacts_6_round_sd: 88,
    artifacts_6_round_da: 18614,
    artifacts_7: 'Shockstar Disco, Flex',
    artifacts_7_1: 'Shockstar Disco',
    artifacts_7_2: 'Flex',
    artifacts_7_3: '',
    artifacts_7_app: 1.24,
    artifacts_7_round_sd: 188,
    artifacts_7_round_da: 15797,
    artifacts_8: 'Shockstar Disco, Freedom Blues',
    artifacts_8_1: 'Shockstar Disco',
    artifacts_8_2: 'Freedom Blues',
    artifacts_8_3: '',
    artifacts_8_app: 1.17,
    artifacts_8_round_sd: 80,
    artifacts_8_round_da: 20991,
    artifacts_9: 'Inferno Metal, Woodpecker Electro',
    artifacts_9_1: 'Inferno Metal',
    artifacts_9_2: 'Woodpecker Electro',
    artifacts_9_3: '',
    artifacts_9_app: 0.83,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 22527,
    artifacts_10: 'Shockstar Disco, Chaos Jazz',
    artifacts_10_1: 'Shockstar Disco',
    artifacts_10_2: 'Chaos Jazz',
    artifacts_10_3: '',
    artifacts_10_app: 0.53,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 19756
  },
  {
    char: 'harumasa',
    app_rate_sd: 26.47,
    app_rate_sd_e1: 5.4,
    app_rate_sd_s0: 11.71,
    avg_round_sd: 160,
    avg_round_sd_e1: 120,
    avg_round_sd_s0: 170,
    sample_sd: 1533,
    sample_size_players_sd: 0,
    app_rate_da: 27.05,
    app_rate_da_e1: 5.65,
    app_rate_da_s0: 11.5,
    avg_round_da: 18099,
    avg_round_da_e1: 25056,
    avg_round_da_s0: 16946,
    app_rate_da_boss_1: 1.51,
    avg_round_da_boss_1: 13386,
    app_rate_da_boss_2: 28.22,
    avg_round_da_boss_2: 18308,
    app_rate_da_boss_3: 0.21,
    avg_round_da_boss_3: 0,
    sample_da: 1484,
    sample_size_players_da: 0,
    app_0: 89.75,
    round_0_sd: 160,
    round_0_da: 18099,
    app_1: 5.72,
    round_1_sd: 130,
    round_1_da: 23187,
    app_2: 2.06,
    round_2_sd: 130,
    round_2_da: 25626,
    app_3: 0.89,
    round_3_sd: 112,
    round_3_da: 29582,
    app_4: 0.23,
    round_4_sd: 128,
    round_4_da: 22153,
    app_5: 0.2,
    round_5_sd: 121,
    round_5_da: 21822,
    app_6: 1.13,
    round_6_sd: 73,
    round_6_da: 37870,
    cons_avg: 0.21,
    weapons_1: 'Marcato Desire',
    weapons_1_app: 39.24,
    weapons_1_round_sd: 166,
    weapons_1_round_da: 17847,
    weapons_2: 'Zanshin Herb Case',
    weapons_2_app: 33.29,
    weapons_2_round_sd: 146,
    weapons_2_round_da: 19919,
    weapons_3: 'The Brimstone',
    weapons_3_app: 10.64,
    weapons_3_round_sd: 163,
    weapons_3_round_da: 18437,
    weapons_4: 'Starlight Engine',
    weapons_4_app: 4.4,
    weapons_4_round_sd: 173,
    weapons_4_round_da: 16803,
    weapons_5: 'Drill Rig - Red Axis',
    weapons_5_app: 3.41,
    weapons_5_round_sd: 176,
    weapons_5_round_da: 15151,
    weapons_6: 'Cannon Rotor',
    weapons_6_app: 3.14,
    weapons_6_round_sd: 162,
    weapons_6_round_da: 16445,
    weapons_7: 'Gilded Blossom',
    weapons_7_app: 2.07,
    weapons_7_round_sd: 181,
    weapons_7_round_da: 13798,
    weapons_8: 'Steel Cushion',
    weapons_8_app: 1.23,
    weapons_8_round_sd: 155,
    weapons_8_round_da: 15046,
    weapons_9: 'Deep Sea Visitor',
    weapons_9_app: 0.3,
    weapons_9_round_sd: 148,
    weapons_9_round_da: 19204,
    weapons_10: 'Street Superstar',
    weapons_10_app: 0.14,
    weapons_10_round_sd: 600,
    weapons_10_round_da: 15819,
    artifacts_1: 'Thunder Metal, Woodpecker Electro',
    artifacts_1_1: 'Thunder Metal',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 50.84,
    artifacts_1_round_sd: 164,
    artifacts_1_round_da: 17611,
    artifacts_2: 'Thunder Metal, Branch & Blade Song',
    artifacts_2_1: 'Thunder Metal',
    artifacts_2_2: 'Branch & Blade Song',
    artifacts_2_3: '',
    artifacts_2_app: 11.96,
    artifacts_2_round_sd: 148,
    artifacts_2_round_da: 19112,
    artifacts_3: 'Woodpecker Electro, Thunder Metal',
    artifacts_3_1: 'Woodpecker Electro',
    artifacts_3_2: 'Thunder Metal',
    artifacts_3_3: '',
    artifacts_3_app: 5.66,
    artifacts_3_round_sd: 165,
    artifacts_3_round_da: 18727,
    artifacts_4: 'Woodpecker Electro, Branch & Blade Song',
    artifacts_4_1: 'Woodpecker Electro',
    artifacts_4_2: 'Branch & Blade Song',
    artifacts_4_3: '',
    artifacts_4_app: 4.5,
    artifacts_4_round_sd: 175,
    artifacts_4_round_da: 17056,
    artifacts_5: 'Hormone Punk, Thunder Metal',
    artifacts_5_1: 'Hormone Punk',
    artifacts_5_2: 'Thunder Metal',
    artifacts_5_3: '',
    artifacts_5_app: 3.58,
    artifacts_5_round_sd: 170,
    artifacts_5_round_da: 17162,
    artifacts_6: 'Thunder Metal, Hormone Punk',
    artifacts_6_1: 'Thunder Metal',
    artifacts_6_2: 'Hormone Punk',
    artifacts_6_3: '',
    artifacts_6_app: 3.52,
    artifacts_6_round_sd: 152,
    artifacts_6_round_da: 19904,
    artifacts_7: 'Branch & Blade Song, Thunder Metal, Woodpecker Electro',
    artifacts_7_1: 'Branch & Blade Song',
    artifacts_7_2: 'Thunder Metal',
    artifacts_7_3: 'Woodpecker Electro',
    artifacts_7_app: 1.93,
    artifacts_7_round_sd: 170,
    artifacts_7_round_da: 17461,
    artifacts_8: 'Thunder Metal, Flex',
    artifacts_8_1: 'Thunder Metal',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 1.6,
    artifacts_8_round_sd: 148,
    artifacts_8_round_da: 18737,
    artifacts_9: 'Thunder Metal, Astral Voice',
    artifacts_9_1: 'Thunder Metal',
    artifacts_9_2: 'Astral Voice',
    artifacts_9_3: '',
    artifacts_9_app: 1.53,
    artifacts_9_round_sd: 141,
    artifacts_9_round_da: 22702,
    artifacts_10: 'Thunder Metal, Puffer Electro',
    artifacts_10_1: 'Thunder Metal',
    artifacts_10_2: 'Puffer Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.93,
    artifacts_10_round_sd: 147,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'miyabi',
    app_rate_sd: 90.88,
    app_rate_sd_e1: 36.76,
    app_rate_sd_s0: 5.11,
    avg_round_sd: 80,
    avg_round_sd_e1: 64,
    avg_round_sd_s0: 101,
    sample_sd: 5264,
    sample_size_players_sd: 0,
    app_rate_da: 91.84,
    app_rate_da_e1: 37.14,
    app_rate_da_s0: 5.67,
    avg_round_da: 23254,
    avg_round_da_e1: 31912,
    avg_round_da_s0: 19270,
    app_rate_da_boss_1: 80.65,
    avg_round_da_boss_1: 23026,
    app_rate_da_boss_2: 4.8,
    avg_round_da_boss_2: 26934,
    app_rate_da_boss_3: 11.03,
    avg_round_da_boss_3: 23716,
    sample_da: 5039,
    sample_size_players_da: 0,
    app_0: 61.99,
    round_0_sd: 80,
    round_0_da: 23254,
    app_1: 8.97,
    round_1_sd: 75,
    round_1_da: 25551,
    app_2: 18.76,
    round_2_sd: 63,
    round_2_da: 31114,
    app_3: 3.52,
    round_3_sd: 62,
    round_3_da: 31521,
    app_4: 1.01,
    round_4_sd: 62,
    round_4_da: 31136,
    app_5: 0.24,
    round_5_sd: 62,
    round_5_da: 31677,
    app_6: 5.51,
    round_6_sd: 45,
    round_6_da: 46003,
    cons_avg: 0.95,
    weapons_1: 'Hailstorm Shrine',
    weapons_1_app: 92.86,
    weapons_1_round_sd: 78,
    weapons_1_round_da: 23826,
    weapons_2: 'Rainforest Gourmet',
    weapons_2_app: 2.17,
    weapons_2_round_sd: 108,
    weapons_2_round_da: 18634,
    weapons_3: 'Fusion Compiler',
    weapons_3_app: 1.72,
    weapons_3_round_sd: 92,
    weapons_3_round_da: 20190,
    weapons_4: 'Weeping Gemini',
    weapons_4_app: 1.32,
    weapons_4_round_sd: 101,
    weapons_4_round_da: 18508,
    weapons_5: 'Roaring Ride',
    weapons_5_app: 0.62,
    weapons_5_round_sd: 95,
    weapons_5_round_da: 18394,
    weapons_6: 'Electro-Lip Gloss',
    weapons_6_app: 0.5,
    weapons_6_round_sd: 98,
    weapons_6_round_da: 19091,
    weapons_7: 'Steel Cushion',
    weapons_7_app: 0.32,
    weapons_7_round_sd: 94,
    weapons_7_round_da: 19787,
    weapons_8: 'Marcato Desire',
    weapons_8_app: 0.21,
    weapons_8_round_sd: 107,
    weapons_8_round_da: 17499,
    weapons_9: 'Deep Sea Visitor',
    weapons_9_app: 0.14,
    weapons_9_round_sd: 86,
    weapons_9_round_da: 15785,
    weapons_10: 'Cannon Rotor',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 98,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Branch & Blade Song, Woodpecker Electro',
    artifacts_1_1: 'Branch & Blade Song',
    artifacts_1_2: 'Woodpecker Electro',
    artifacts_1_3: '',
    artifacts_1_app: 75.05,
    artifacts_1_round_sd: 81,
    artifacts_1_round_da: 23071,
    artifacts_2: 'Branch & Blade Song, Polar Metal',
    artifacts_2_1: 'Branch & Blade Song',
    artifacts_2_2: 'Polar Metal',
    artifacts_2_3: '',
    artifacts_2_app: 14.33,
    artifacts_2_round_sd: 80,
    artifacts_2_round_da: 23105,
    artifacts_3: 'Branch & Blade Song, Astral Voice',
    artifacts_3_1: 'Branch & Blade Song',
    artifacts_3_2: 'Astral Voice',
    artifacts_3_3: '',
    artifacts_3_app: 5.43,
    artifacts_3_round_sd: 72,
    artifacts_3_round_da: 25217,
    artifacts_4: 'Branch & Blade Song, Hormone Punk',
    artifacts_4_1: 'Branch & Blade Song',
    artifacts_4_2: 'Hormone Punk',
    artifacts_4_3: '',
    artifacts_4_app: 1.67,
    artifacts_4_round_sd: 64,
    artifacts_4_round_da: 27615,
    artifacts_5: 'Branch & Blade Song, Puffer Electro',
    artifacts_5_1: 'Branch & Blade Song',
    artifacts_5_2: 'Puffer Electro',
    artifacts_5_3: '',
    artifacts_5_app: 1.41,
    artifacts_5_round_sd: 64,
    artifacts_5_round_da: 28515,
    artifacts_6: 'Branch & Blade Song, Flex',
    artifacts_6_1: 'Branch & Blade Song',
    artifacts_6_2: 'Flex',
    artifacts_6_3: '',
    artifacts_6_app: 0.7,
    artifacts_6_round_sd: 90,
    artifacts_6_round_da: 22166,
    artifacts_7: 'Branch & Blade Song, Freedom Blues',
    artifacts_7_1: 'Branch & Blade Song',
    artifacts_7_2: 'Freedom Blues',
    artifacts_7_3: '',
    artifacts_7_app: 0.31,
    artifacts_7_round_sd: 72,
    artifacts_7_round_da: 23721,
    artifacts_8: 'Branch & Blade Song, Woodpecker Electro, Flex',
    artifacts_8_1: 'Branch & Blade Song',
    artifacts_8_2: 'Woodpecker Electro',
    artifacts_8_3: 'Flex',
    artifacts_8_app: 0.18,
    artifacts_8_round_sd: 66,
    artifacts_8_round_da: 23634,
    artifacts_9: 'Branch & Blade Song, Chaos Jazz',
    artifacts_9_1: 'Branch & Blade Song',
    artifacts_9_2: 'Chaos Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 0.14,
    artifacts_9_round_sd: 84,
    artifacts_9_round_da: 27278,
    artifacts_10: 'Polar Metal, Woodpecker Electro',
    artifacts_10_1: 'Polar Metal',
    artifacts_10_2: 'Woodpecker Electro',
    artifacts_10_3: '',
    artifacts_10_app: 0.06,
    artifacts_10_round_sd: 79,
    artifacts_10_round_da: 0.0
  },
  {
    char: 'astra-yao',
    app_rate_sd: 15.26,
    app_rate_sd_e1: 4.87,
    app_rate_sd_s0: 3.04,
    avg_round_sd: 95,
    avg_round_sd_e1: 67,
    avg_round_sd_s0: 132,
    sample_sd: 884,
    sample_size_players_sd: 0,
    app_rate_da: 65.34,
    app_rate_da_e1: 20.52,
    app_rate_da_s0: 16.02,
    avg_round_da: 24234,
    avg_round_da_e1: 33979,
    avg_round_da_s0: 21128,
    app_rate_da_boss_1: 32.04,
    avg_round_da_boss_1: 25987,
    app_rate_da_boss_2: 24.7,
    avg_round_da_boss_2: 23247,
    app_rate_da_boss_3: 14.12,
    avg_round_da_boss_3: 22151,
    sample_da: 3585,
    sample_size_players_da: 0,
    app_0: 87.2,
    round_0_sd: 95,
    round_0_da: 24234,
    app_1: 9.66,
    round_1_sd: 68,
    round_1_da: 32877,
    app_2: 2.29,
    round_2_sd: 66,
    round_2_da: 41944,
    app_3: 0.11,
    round_3_sd: 26,
    round_3_da: 36692,
    app_4: 0.11,
    round_4_sd: 25,
    round_4_da: 40974,
    app_5: 0.03,
    round_5_sd: 600,
    round_5_da: 61350,
    app_6: 0.61,
    round_6_sd: 19,
    round_6_da: 54755,
    cons_avg: 0.19,
    weapons_1: 'Bashful Demon',
    weapons_1_app: 36.01,
    weapons_1_round_sd: 98,
    weapons_1_round_da: 23894,
    weapons_2: 'Elegant Vanity',
    weapons_2_app: 26.79,
    weapons_2_round_sd: 84,
    weapons_2_round_da: 25343,
    weapons_3: 'Kaboom the Cannon',
    weapons_3_app: 22.24,
    weapons_3_round_sd: 87,
    weapons_3_round_da: 24640,
    weapons_4: 'The Vault',
    weapons_4_app: 10.94,
    weapons_4_round_sd: 114,
    weapons_4_round_da: 23219,
    weapons_5: 'Weeping Cradle',
    weapons_5_app: 1.25,
    weapons_5_round_sd: 113,
    weapons_5_round_da: 22040,
    weapons_6: 'Slice of Time',
    weapons_6_app: 1.18,
    weapons_6_round_sd: 110,
    weapons_6_round_da: 22191,
    weapons_7: 'Unfettered Game Ball',
    weapons_7_app: 0.68,
    weapons_7_round_sd: 57,
    weapons_7_round_da: 24367,
    weapons_8: '[Reverb] Mark II',
    weapons_8_app: 0.1,
    weapons_8_round_sd: 80,
    weapons_8_round_da: 19208,
    weapons_9: '[Reverb] Mark I',
    weapons_9_app: 0.05,
    weapons_9_round_sd: 600,
    weapons_9_round_da: 19118,
    weapons_10: 'The Brimstone',
    weapons_10_app: 0.02,
    weapons_10_round_sd: 73,
    weapons_10_round_da: 0.0,
    artifacts_1: 'Astral Voice, Swing Jazz',
    artifacts_1_1: 'Astral Voice',
    artifacts_1_2: 'Swing Jazz',
    artifacts_1_3: '',
    artifacts_1_app: 83.23,
    artifacts_1_round_sd: 93,
    artifacts_1_round_da: 24395,
    artifacts_2: 'Astral Voice, Hormone Punk',
    artifacts_2_1: 'Astral Voice',
    artifacts_2_2: 'Hormone Punk',
    artifacts_2_3: '',
    artifacts_2_app: 13.78,
    artifacts_2_round_sd: 103,
    artifacts_2_round_da: 23941,
    artifacts_3: 'Astral Voice, Flex',
    artifacts_3_1: 'Astral Voice',
    artifacts_3_2: 'Flex',
    artifacts_3_3: '',
    artifacts_3_app: 0.77,
    artifacts_3_round_sd: 124,
    artifacts_3_round_da: 23566,
    artifacts_4: 'Swing Jazz, Astral Voice',
    artifacts_4_1: 'Swing Jazz',
    artifacts_4_2: 'Astral Voice',
    artifacts_4_3: '',
    artifacts_4_app: 0.32,
    artifacts_4_round_sd: 58,
    artifacts_4_round_da: 22834,
    artifacts_5: 'ATK +10%, Swing Jazz, Flex',
    artifacts_5_1: 'ATK +10%',
    artifacts_5_2: 'Swing Jazz',
    artifacts_5_3: 'Flex',
    artifacts_5_app: 0.29,
    artifacts_5_round_sd: 88,
    artifacts_5_round_da: 19114,
    artifacts_6: 'Swing Jazz, Hormone Punk',
    artifacts_6_1: 'Swing Jazz',
    artifacts_6_2: 'Hormone Punk',
    artifacts_6_3: '',
    artifacts_6_app: 0.22,
    artifacts_6_round_sd: 130,
    artifacts_6_round_da: 19349,
    artifacts_7: 'Astral Voice, Woodpecker Electro',
    artifacts_7_1: 'Astral Voice',
    artifacts_7_2: 'Woodpecker Electro',
    artifacts_7_3: '',
    artifacts_7_app: 0.18,
    artifacts_7_round_sd: 53,
    artifacts_7_round_da: 18096,
    artifacts_8: 'ATK +10%, Flex',
    artifacts_8_1: 'ATK +10%',
    artifacts_8_2: 'Flex',
    artifacts_8_3: '',
    artifacts_8_app: 0.16,
    artifacts_8_round_sd: 600,
    artifacts_8_round_da: 21924,
    artifacts_9: 'Freedom Blues, Swing Jazz',
    artifacts_9_1: 'Freedom Blues',
    artifacts_9_2: 'Swing Jazz',
    artifacts_9_3: '',
    artifacts_9_app: 0.11,
    artifacts_9_round_sd: 600,
    artifacts_9_round_da: 45036,
    artifacts_10: 'Astral Voice, Freedom Blues',
    artifacts_10_1: 'Astral Voice',
    artifacts_10_2: 'Freedom Blues',
    artifacts_10_3: '',
    artifacts_10_app: 0.11,
    artifacts_10_round_sd: 600,
    artifacts_10_round_da: 27250
  }
];
